const INITIAL_API_LOADING_STATE = {
  isDjangoProfileLoading: true,
  isStripeInfoLoading: true,
  isLandingPageInfoLoading: true,
  isBookingRequestInfoLoading: true,
  isGmbInfoLoading: true,
  isTestimonialInfoLoading: true,
  isContactInfoLoading: true,
};
const CONSTANTS = {
  INITIAL_API_LOADING_STATE,
};

export default CONSTANTS;
