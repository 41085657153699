import { api } from "../../helpers/topline-api";

export async function getAllProjectShowcases(slug: string) {
  try {
    const response = await api.get(`/api/project-showcases-get-all/${slug}`);
    return response?.data?.message;
  } catch (e) {
    return null;
  }
}

export async function publishShowcase(showcaseInfo: any, slug: string) {
  try {
    const response = await api.post(`/api/project-showcase-publish/${slug}`, {
      showcaseInfo,
    });
    return response?.data?.message;
  } catch (e) {
    return null;
  }
}

export async function editShowcase(showcaseInfo: any, slug: string, id: any) {
  try {
    const response = await api.post(`/api/project-showcase-edit/${slug}`, {
      showcaseInfo,
      id,
    });
    return response?.data?.message;
  } catch (e) {
    return null;
  }
}

export async function deleteShowcase(showcaseId: any) {
  try {
    const response = await api.post(`/api/project-showcase-delete`, {
      showcaseId,
    });
    return response?.data?.message;
  } catch (e) {
    return null;
  }
}

export async function generateTitleDescription(
  slug: string,
  showcaseInfo: any
) {
  try {
    const response = await api.post(
      `/api/project-showcases-title-description/${slug}`,
      { showcaseInfo }
    );
    return response?.data?.message;
  } catch (e) {
    return { title: "", detailedDescription: "" };
  }
}

export async function generateSubtitle(
  slug: string,
  showcaseInfo: any,
  title: string
) {
  try {
    const response = await api.post(`/api/project-showcases-subtitle/${slug}`, {
      showcaseInfo,
      title,
    });
    return response?.data?.message;
  } catch (e) {
    return { subtitle: "" };
  }
}

export async function selectFeaturedPhoto(slug: string, showcaseInfo: any) {
  try {
    const response = await api.post(
      `/api/project-showcases-featured-photo/${slug}`,
      { showcaseInfo }
    );
    return response?.data?.message;
  } catch (e) {
    return { featuredPhoto: "" };
  }
}

export async function selectLikelyService(
  slug: string,
  showcaseInfo: any,
  title: string,
  detailedDescription: string
) {
  try {
    const response = await api.post(
      `/api/project-showcases-likely-service/${slug}`,
      {
        showcaseInfo,
        title,
        detailedDescription,
      }
    );
    return response?.data?.message;
  } catch (e) {
    return { service: "" };
  }
}

export function getReviewsWithoutShowcases(displayedShowcases, reviewInfo) {
  const reviewsWithShowcases =
    displayedShowcases
      ?.filter((showcase) => showcase.review)
      ?.map((showcase) => showcase.review) || [];

  const reviewsWithoutShowcases = reviewInfo?.filter(
    (review) => !reviewsWithShowcases.includes(parseInt(review.id))
  );
  return reviewsWithoutShowcases;
}

export enum ShowcaseStage {
  SELECT = "SELECT",
  CREATE = "CREATE",
  GENERATING = "GENERATING",
  PUBLISH = "PUBLISH",
}
