const GOOD = "good";
const NEUTRAL = "neutral";
const POOR = "poor";

const ON_SITE = "On-site SEO";
const GBP = "Google Business Profile";
const REVIEWS = "Reviews";
const POST_FREQ = "Post Frequency";

const TITLE_TAG = "title tag";
const META_DESCRIPTION = "meta description";

const SEODATA = {
  has_meta_name_viewport: {
    content: (
      <div>
        Has a{" "}
        <span className="text-blue-900">&lt;meta name="viewport"&gt;</span> tag
        with width or initial-scale
      </div>
    ),
    tooltip:
      'A <meta name="viewport"> not only optimizes your app for mobile screen sizes, but also prevents a delay to user input.',
  },
  has_title_element: {
    content: (
      <div>
        Document has a <span className="text-blue-900">&lt;title&gt;</span>{" "}
        element
      </div>
    ),
    tooltip:
      "The title gives users an overview of the page, and search engine users rely on it heavily to determine if a page is relevant to their search.",
  },
  has_meta_description: {
    content: "Document has a meta description",
    tooltip:
      "Meta descriptions may be included in search results to concisely summarize page content.",
  },
  has_successful_http_status_code: {
    content: "Page has successful HTTP status code",
    tooltip:
      "Pages with unsuccessful HTTP status codes may not be indexed properly.",
  },
  has_descriptive_text_for_links: {
    content: "Links have descriptive text",
    tooltip:
      "Descriptive link text helps search engines understand your content.",
  },
  is_blocked_from_indexing: {
    content: "Page isn’t blocked from indexing",
    tooltip:
      "Search engines are unable to include your pages in search results if they don't have permission to crawl them.",
  },
  has_valid_hreflang: {
    content: (
      <div>
        Document has a valid <span className="text-blue-900">hreflang</span>
      </div>
    ),
    tooltip:
      "hreflang links tell search engines what version of a page they should list in search results for a given language or region",
  },
  has_legible_font_sizes: {
    content: (
      <div>
        Document uses legible font sizes{" "}
        <span className="text-green-600">— 100% legible text</span>
      </div>
    ),
    tooltip:
      "Font sizes less than 12px are too small to be legible and require mobile visitors to “pinch to zoom” in order to read. Strive to have >60% of page text ≥12px.",
  },
  avoid_plugins: {
    content: "Document avoids plugins",
    tooltip:
      "Search engines can't index plugin content, and many devices restrict plugins or don't support them.",
  },
  has_appropriate_tap_target: {
    content: (
      <div>
        Tap targets are sized appropriately{" "}
        <span className="text-green-600">
          — 100% appropriately sized tap targets
        </span>
      </div>
    ),
    tooltip:
      "Interactive elements like buttons and links should be large enough (48x48px), or have enough space around them, to be easy enough to tap without overlapping onto other elements.",
  },

  are_links_crawlable: {
    content: "Links are crawlable",
    tooltip:
      "Search engines may use href attributes on links to crawl websites. Ensure that the href attribute of anchor elements links to an appropriate destination, so more pages of the site can be discovered.",
  },
  is_rel_valid: {
    content: (
      <div>
        Document has a valid{" "}
        <span className="text-blue-900">rel=canonical</span>
      </div>
    ),
    tooltip:
      "If your robots.txt file is malformed, crawlers may not be able to understand how you want your website to be crawled or indexed.",
  },
  has_alt_for_images: {
    content: (
      <div>
        Image elements have <span className="text-blue-900">[alt]</span>{" "}
        attributes
      </div>
    ),
    tooltip:
      "Informative elements should aim for short, descriptive alternate text. Decorative elements can be ignored with an empty alt attribute.",
  },
};

const GBP_DATA = {
  is_profile_verified: { content: "Profile is verfied", tooltip: "" },
  is_company_name_accurate: {
    content: "Company name is accurate",
    tooltip:
      "The name of your company on your profile matches the rest of your online presence (e.g., website). ",
  },
  service_area_accuracy: {
    content: "Service area is accurate",
    tooltip:
      "The area you service is reasonably sized and matches the rest of your online presence (e.g., website).",
  },
  is_website_provided: {
    content: "Website is provided",
    tooltip: "Your profile has a website listed with relevant content.",
  },
  is_phone_number_provided: {
    content: "Phone number is provided",
    tooltip: "Your profile has a phone number listed.",
  },
  is_category_provided: {
    content: "Category is provided",
    tooltip:
      "Your profile has a category that matches the rest of your online presence (e.g., website).",
  },
  are_business_hours_provided: {
    content: "Business hours are provided",
    tooltip: "Your profile has business hours listed.",
  },
  is_city_name_in_title_tag: {
    content: 'City name is used in landing page "title tag"',
    tooltip:
      "The primary city that you service is included in the title tag of your website.",
  },
  is_state_code_in_title_tag: {
    content: 'State code is used in landing page "title tag"',
    tooltip:
      "The primary state that you service is included in the title tag of your website.",
  },
  photos_num: {
    tooltip:
      "The number of photos you have on your Google Business Profile. We recommend having at least 100 photos and adding new ones monthly.",
  },
  reviews_count: {
    tooltip: "The number of reviews you have on your Google Business Profile.",
  },
};

export {
  GOOD,
  NEUTRAL,
  POOR,
  ON_SITE,
  GBP,
  REVIEWS,
  POST_FREQ,
  SEODATA,
  GBP_DATA,
  TITLE_TAG,
  META_DESCRIPTION,
};
