import axios from "axios";
import PrimaryLink from "../../../uiwrappers/PrimaryLink/PrimaryLink";
import SecondaryButton from "../../../uiwrappers/SecondaryButton/SecondaryButton";
import {
  formatDate,
  formatDateLong,
} from "../../reviews/ReviewContainer/utils";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { api } from "../../../../helpers/topline-api";

export function getTasksByLandingPage(slug: string) {
  return api.get(`/api/getTasksByLandingPage/${slug}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getUserHashIntercom() {
  return api.get(`/api/get-user-intercom`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export async function publishRoute() {
  return api.get(`/api/publish_route`);
}

export async function firstLoginAfterPublish(slug) {
  try {
    await api.get(`/api/first-login-after-publish/${slug}`);
  } catch (e) {
    console.log("error", e);
  }
}

export async function toggleDigitalMediaPopup(slug) {
  try {
    await api.get(`/api/toggle-digital-media-popup/${slug}`);
  } catch (e) {
    console.log("error", e);
  }
}

export async function togglePhysicalMarketingPopup(slug) {
  try {
    await api.get(`/api/toggle-physical-marketing-popup/${slug}`);
  } catch (e) {
    console.log("error", e);
  }
}

export async function triggerStripeIncentive() {
  try {
    await api.get(`/api/trigger-stripe-incentive`);
  } catch (e) {
    console.log("error", e);
  }
}

export async function triggerDownloadAppPopup() {
  try {
    await api.get(`/api/trigger-download-app-popup`);
  } catch (e) {
    console.log("error", e);
  }
}

export async function sendSafariNoti() {
  try {
    await api.get(`/api/send-safari-download-notification`);
  } catch (e) {
    console.log("error", e);
  }
}

export async function saveConnectionsScreenShownDb() {
  try {
    await api.get(`/api/save-connections-screen-shown`);
  } catch (e) {
    console.log("error", e);
  }
}

export function addHttp(url: any) {
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  return url;
}

export function renderDomainLink(
  url: any,
  domain: any,
  style = "",
  size = "sm"
) {
  return (
    <>
      <a className="w-auto" target="_blank" href={url} rel="noreferrer">
        <SecondaryButton
          size={size}
          text={domain}
          icon="external-link"
          iconPlacement="lagging"
          customStyle={`whitespace-nowrap ${style}`}
        />
      </a>
    </>
  );
}

export function renderCopyButton(domain: any, ref?: any) {
  function onCopyHandler(domain: any) {
    copy(domain);
    toast.success("Domain link copied");
  }

  return (
    <>
      <div ref={ref}>
        <PrimaryLink
          id={`copy-domain`}
          text="Copy"
          size="lg"
          onClickFunc={() => onCopyHandler(domain)}
        />
      </div>
    </>
  );
}

export async function getFacebookPageTotalLikes(pageId, pageAccessToken) {
  try {
    let response = await fetch(
      `https://graph.facebook.com/v14.0/${pageId}/feed?fields=likes.limit(1).summary(true)&access_token=${pageAccessToken}`,
      {
        method: "GET",
      }
    );
    let responseData = await response.json();
    let data = responseData.data;

    if (responseData?.paging?.next) {
      for (let i = 0; i < 100; i++) {
        response = await fetch(responseData?.paging?.next, {
          method: "GET",
        });
        responseData = await response.json();
        data = [...data, ...responseData.data];
        if (responseData?.paging?.next) {
          continue;
        } else {
          break;
        }
      }
    }

    let totalLikes = 0;
    data?.forEach((post: any) => {
      totalLikes = totalLikes + post.likes.summary.total_count;
    });

    return {
      totalLikes,
    };
  } catch (error) {
    console.log("error:", error);
  }
}

export async function getFacebookPageFollowerCount(pageId, pageAccessToken) {
  try {
    const response = await fetch(
      `https://graph.facebook.com/v14.0/${pageId}?fields=access_token%2Cfollowers_count%2Cposts%7Bmessage%2Cstory%2Cfull_picture%2Ccreated_time%7D&access_token=${pageAccessToken}`,
      {
        method: "GET",
      }
    );
    const responseData = await response.json();
    const { followers_count } = responseData;

    return {
      followerCount: followers_count,
    };
  } catch (error) {
    console.log("error:", error);
  }
}

export async function saveNewMonthlyGoal(goal: any) {
  try {
    await api.post(`/api/save-monthly-review-goal`, { goal });
  } catch (e) {
    console.log("error", e);
  }
  return;
}

export async function updateShowLiveWebsiteFacebookPopUp(slug: any) {
  try {
    const response = await api.post(
      `/api/update_show_live_website_facebook_popup`,
      { slug }
    );
    console.log(
      "response after update show live website facbook popup",
      response
    );
  } catch (e) {
    console.log("error updateShowLiveWebsiteFacebookPopUp", e);
  }
  return;
}

export async function getGoogleAnalytics(accessToken, locationId) {
  try {
    const today = new Date();
    const todayDay = today.getDate();
    const todayMonth = today.getMonth() + 1;
    const todayYear = today.getFullYear();

    const oneYearAgo = new Date(new Date().setDate(today.getDate() - 365));
    const oneYearAgoDay = oneYearAgo.getDate();
    const oneYearAgoMonth = oneYearAgo.getMonth() + 1;
    const oneYearAgoYear = oneYearAgo.getFullYear();

    const beginningOfTime = new Date(1970, 1, 1);
    const beginningOfTimeMonth = beginningOfTime.getMonth() + 1;
    const beginningOfTimeYear = beginningOfTime.getFullYear();

    const desktopImpressionsMaps = `https://businessprofileperformance.googleapis.com/v1/${locationId}:getDailyMetricsTimeSeries?dailyMetric=BUSINESS_IMPRESSIONS_DESKTOP_MAPS&dailyRange.start_date.year=${oneYearAgoYear}&dailyRange.start_date.month=${oneYearAgoMonth}&dailyRange.start_date.day=${oneYearAgoDay}&dailyRange.end_date.year=${todayYear}&dailyRange.end_date.month=${todayMonth}&dailyRange.end_date.day=${todayDay}`;
    const desktopImpressionsSearch = `https://businessprofileperformance.googleapis.com/v1/${locationId}:getDailyMetricsTimeSeries?dailyMetric=BUSINESS_IMPRESSIONS_DESKTOP_SEARCH&dailyRange.start_date.year=${oneYearAgoYear}&dailyRange.start_date.month=${oneYearAgoMonth}&dailyRange.start_date.day=${oneYearAgoDay}&dailyRange.end_date.year=${todayYear}&dailyRange.end_date.month=${todayMonth}&dailyRange.end_date.day=${todayDay}`;
    const mobileImpressionsMaps = `https://businessprofileperformance.googleapis.com/v1/${locationId}:getDailyMetricsTimeSeries?dailyMetric=BUSINESS_IMPRESSIONS_MOBILE_MAPS&dailyRange.start_date.year=${oneYearAgoYear}&dailyRange.start_date.month=${oneYearAgoMonth}&dailyRange.start_date.day=${oneYearAgoDay}&dailyRange.end_date.year=${todayYear}&dailyRange.end_date.month=${todayMonth}&dailyRange.end_date.day=${todayDay}`;
    const mobileImpressionsSearch = `https://businessprofileperformance.googleapis.com/v1/${locationId}:getDailyMetricsTimeSeries?dailyMetric=BUSINESS_IMPRESSIONS_MOBILE_SEARCH&dailyRange.start_date.year=${oneYearAgoYear}&dailyRange.start_date.month=${oneYearAgoMonth}&dailyRange.start_date.day=${oneYearAgoDay}&dailyRange.end_date.year=${todayYear}&dailyRange.end_date.month=${todayMonth}&dailyRange.end_date.day=${todayDay}`;
    const callClicks = `https://businessprofileperformance.googleapis.com/v1/${locationId}:getDailyMetricsTimeSeries?dailyMetric=CALL_CLICKS&dailyRange.start_date.year=${oneYearAgoYear}&dailyRange.start_date.month=${oneYearAgoMonth}&dailyRange.start_date.day=${oneYearAgoDay}&dailyRange.end_date.year=${todayYear}&dailyRange.end_date.month=${todayMonth}&dailyRange.end_date.day=${todayDay}`;
    const searchKeyWords = `https://businessprofileperformance.googleapis.com/v1/${locationId}/searchkeywords/impressions/monthly?monthlyRange.start_month.year=${beginningOfTimeYear}&monthlyRange.start_month.month=${beginningOfTimeMonth}&monthlyRange.end_month.year=${todayYear}&monthlyRange.end_month.month=${todayMonth}`;
    // const searchKeyWordsOneMonthAgo = `https://businessprofileperformance.googleapis.com/v1/${locationId}/searchkeywords/impressions/monthly?monthlyRange.start_month.year=${oneMonthAgoYear}&monthlyRange.start_month.month=${oneMonthAgoMonth}&monthlyRange.end_month.year=${todayYear}&monthlyRange.end_month.month=${todayMonth}`;

    const requestUrls = [
      desktopImpressionsMaps,
      desktopImpressionsSearch,
      mobileImpressionsMaps,
      mobileImpressionsSearch,
      callClicks,
      searchKeyWords,
    ];

    const responses = [];
    for (let i = 0; i < requestUrls.length; i++) {
      const url = requestUrls[i];
      const response = await axios.get(url, {
        headers: {
          authorization: "Bearer " + accessToken,
        },
      });
      responses.push(response);
    }

    return responses;
  } catch (error) {
    console.log("error:", error);
    return [];
  }
}

function filterAnalyticsByToday(allItems: any[]) {
  const todayItems: any[] = [];
  allItems.forEach((array: any[]) => {
    const lastItem = array[array.length - 1];
    todayItems.push(lastItem);
  });
  return todayItems;
}

function filterAnalyticsByRange(days, allItems) {
  const items: any[] = [];
  allItems.forEach((array: any[]) => {
    const negativeDays = -1 * days;
    const itemsByRange = [...array].splice(negativeDays);
    items.push(itemsByRange);
  });
  const flattenedArray = items.flat();

  return flattenedArray;
}

function filterBookingsByToday(allItems) {
  const filteredItems = allItems.filter(function (booking) {
    const today = new Date();
    const bookingDate = new Date(booking.created);
    return (
      bookingDate.getDate() === today?.getDate() &&
      bookingDate.getMonth() === today?.getMonth() &&
      bookingDate.getFullYear() === today?.getFullYear()
    );
  });
  return filteredItems;
}

function filterBookingsByRange(days, allItems) {
  const filteredItems = allItems?.filter(function (booking) {
    const reviewDate = new Date(booking.created);
    const now = new Date();
    const msSince = Math.abs(reviewDate.getTime() - now.getTime());
    const daysSince = msSince / (24 * 60 * 60 * 1000);
    return daysSince < days;
  });
  return filteredItems;
}

const today = new Date();
const date1 = new Date();
const sevenDaysAgo = new Date(
  date1.getFullYear(),
  date1.getMonth(),
  date1.getDate() - 6
);
export const thirtyDaysAgo = new Date(
  date1.getFullYear(),
  date1.getMonth(),
  date1.getDate() - 29
);
const ninetyDaysAgo = new Date(
  date1.getFullYear(),
  date1.getMonth(),
  date1.getDate() - 89
);
const oneYearAgo = new Date(
  date1.getFullYear(),
  date1.getMonth(),
  date1.getDate() - 364
);

export function getFilteredData(
  timePeriod,
  googleImpressionsData,
  googleCallData,
  bookingsData
) {
  let filteredImpressionsArray: any[] = [];
  let filteredCallsArray: any[] = [];
  let filteredBookingsArray: any[] = [];
  let formattedDate: string = "";
  let formattedDateLong: string = "";

  switch (timePeriod) {
    case "Today":
      filteredImpressionsArray = filterAnalyticsByToday(googleImpressionsData);
      filteredCallsArray = filterAnalyticsByToday(googleCallData);
      filteredBookingsArray = filterBookingsByToday(bookingsData);
      formattedDate = formatDate(today);
      formattedDateLong = formatDateLong(today);

      break;
    case "Last 7 days":
      filteredImpressionsArray = filterAnalyticsByRange(
        7,
        googleImpressionsData
      );
      filteredCallsArray = filterAnalyticsByRange(7, googleCallData);
      filteredBookingsArray = filterBookingsByRange(7, bookingsData);

      formattedDate = `${formatDate(sevenDaysAgo)} - ${formatDate(today)}`;
      formattedDateLong = `${formatDateLong(sevenDaysAgo)} - ${formatDateLong(
        today
      )}`;
      break;
    case "Last 30 days":
      filteredImpressionsArray = filterAnalyticsByRange(
        30,
        googleImpressionsData
      );
      filteredCallsArray = filterAnalyticsByRange(30, googleCallData);
      filteredBookingsArray = filterBookingsByRange(30, bookingsData);

      formattedDate = `${formatDate(thirtyDaysAgo)} - ${formatDate(today)}`;
      formattedDateLong = `${formatDateLong(thirtyDaysAgo)} - ${formatDateLong(
        today
      )}`;
      break;
    case "Last 90 days":
      filteredImpressionsArray = filterAnalyticsByRange(
        90,
        googleImpressionsData
      );
      filteredCallsArray = filterAnalyticsByRange(90, googleCallData);
      filteredBookingsArray = filterBookingsByRange(90, bookingsData);

      formattedDate = `${formatDate(ninetyDaysAgo)} - ${formatDate(today)}`;
      formattedDateLong = `${formatDateLong(ninetyDaysAgo)} - ${formatDateLong(
        today
      )}`;
      break;
    case "Last year":
      filteredImpressionsArray = filterAnalyticsByRange(
        365,
        googleImpressionsData
      );
      filteredCallsArray = filterAnalyticsByRange(365, googleCallData);
      filteredBookingsArray = filterBookingsByRange(365, bookingsData);

      formattedDate = `${formatDate(oneYearAgo)} - ${formatDate(today)}`;
      formattedDateLong = `${formatDateLong(oneYearAgo)} - ${formatDateLong(
        today
      )}`;
      break;
  }

  return {
    filteredImpressionsArray,
    filteredCallsArray,
    filteredBookingsArray,
    formattedDate,
    formattedDateLong,
  };
}

export async function sendSlackError(
  slug: string,
  error: any,
  locationInApp: string,
  additionalContext: string,
  userAction: string
) {
  return await api.post(`/api/slack-error`, {
    slug,
    error,
    locationInApp,
    additionalContext,
    userAction,
  });
}
