import { SelectorIcon } from "@heroicons/react/solid";
import { domToPng } from "modern-screenshot";
import { v4 as uuidv4 } from "uuid";
import NewBadge from "./NewBadge";

export async function captureImage(elementId: string) {
  const photoContainer = document.getElementById(elementId);
  if (photoContainer) {
    try {
      const imageData = await domToPng(photoContainer, {
        scale: 5,
        quality: 1,
      });

      return imageData;
    } catch (error) {
      console.error("Failed to capture image:", error);
      throw error;
    }
  }

  return null;
}

export async function createFile(imageUrl: string, mimeType?: string) {
  const response = await fetch(imageUrl);
  const data = await response.blob();

  let extension, metadata;

  if (mimeType) {
    metadata = {
      type: mimeType,
    };
    extension = mimeType.split("/")[1];
  } else {
    const type = data?.type;
    metadata = {
      type,
    };
    extension = type.split("/")[1];
  }

  const file = new File([data], `${uuidv4()}.${extension}`, metadata);
  return file;
}

export async function createAndCaptureImage(image1, image2) {
  await createBeforeAfterElement(image1, image2);
  const photoContainer2 = document.getElementById("photo-container-2");
  if (photoContainer2) {
    try {
      const imageData = await domToPng(photoContainer2, {
        scale: 5,
        quality: 1,
      });

      return imageData;
    } catch (error) {
      console.error("Failed to capture image:", error);
      throw error;
    }
  }

  return null;
}
async function createBeforeAfterElement(image1, image2) {
  try {
    const container = document.createElement("div");
    document.body.appendChild(container);
    const imageSrc1 = image1;
    const imageSrc2 = image2;

    const html = `
      <div id="photo-container-2" class="relative flex justify-center items-center w-400 h-400 flex-row">
        <div class="flex relative w-1/2 h-full mr-0.5 false">
          <div class="flex w-full h-full relative cursor-pointer">
            <div id="image-1-container" style="touch-action: none; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
              <img crossorigin="anonymous" class="flex w-full h-full object-cover" src="${imageSrc1}">
            </div>
          </div>
        </div>
        <div class="flex relative w-1/2 h-full">
          <div class="flex w-full h-full relative cursor-pointer">
            <div id="image-2-container" style="touch-action: none; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
              <img crossorigin="anonymous" class="flex w-full h-full object-cover" src="${imageSrc2}">
            </div>
          </div>
        </div>
      </div>
    `;

    container.innerHTML = html;
  } catch (e) {
    console.log("ERROR:", e);
  }
}

export function getHorizontalIcon(layout: string) {
  return (
    <div
      className={`flex flex-row justify-center items-center space-x-1 w-10 h-10 rounded-md box-content cursor-pointer ${
        layout === "horizontal"
          ? "border-2 border-blue-600 bg-blue-200"
          : "border border-gray-300 bg-white"
      }`}
    >
      <div
        className={`flex w-3.5 h-8 ${
          layout === "horizontal" ? "bg-white" : "bg-gray-300"
        }`}
      ></div>
      <div
        className={`flex w-3.5 h-8 ${
          layout === "horizontal" ? "bg-white" : "bg-gray-300"
        }`}
      ></div>
    </div>
  );
}

export function getVerticalIcon(layout: string) {
  return (
    <div
      className={`flex flex-col justify-center items-center space-y-1 w-10 h-10 rounded-md box-content cursor-pointer ${
        layout === "vertical"
          ? "border-2 border-blue-600 bg-blue-200"
          : "border border-gray-300 bg-white"
      }`}
    >
      <div
        className={`flex w-8 h-3.5 ${
          layout === "vertical" ? "bg-white" : "bg-gray-300"
        }`}
      ></div>
      <div
        className={`flex w-8 h-3.5 ${
          layout === "vertical" ? "bg-white" : "bg-gray-300"
        }`}
      ></div>
    </div>
  );
}

export function getSliderIcon(layout: string) {
  return (
    <div
      className={`relative flex flex-row justify-center items-center w-10 h-10 rounded-md box-content cursor-pointer ${
        layout === "slider"
          ? "border-2 border-blue-600 bg-blue-200"
          : "border border-gray-300 bg-white"
      }`}
    >
      <NewBadge />
      <div
        className={`flex w-3.5 h-8 mr-1 ${
          layout === "slider" ? "bg-white" : "bg-gray-300"
        }`}
      ></div>
      <div
        className={`flex w-3.5 h-8 ${
          layout === "slider" ? "bg-white" : "bg-gray-300"
        }`}
      ></div>
      <div
        className={`flex absolute w-4 h-4 rounded-full bg-white ${
          layout === "slider"
            ? "border border-blue-600"
            : "border border-gray-300"
        }`}
      >
        <SelectorIcon
          className={`transform rotate-90 ${
            layout === "slider" ? "text-blue-600" : "text-gray-600"
          }`}
        />
      </div>
    </div>
  );
}

export function optimizedUrl(photoUrl: string) {
  if (photoUrl?.includes("data:image")) {
    return photoUrl;
  }

  if (photoUrl) {
    const uuid = getUuid(photoUrl);
    const url = `https://d3p2r6ofnvoe67.cloudfront.net/fit-in/1500x1500/filters:format(webp)/filters:no_upscale()/filters:strip_exif()/media/${uuid}`;
    return url;
  } else {
    return "";
  }
}

export function getOptimizedUrlFromUuid(uuid: string) {
  const url = `https://d3p2r6ofnvoe67.cloudfront.net/fit-in/1500x1500/filters:format(webp)/filters:no_upscale()/filters:strip_exif()/media/${uuid}`;
  return url;
}

export function getUuid(photoUrl: string) {
  const segments = photoUrl.split("/");
  const uuid = segments[segments.length - 1];
  return uuid;
}
