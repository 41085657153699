import getCronTaskResult from "../../../common/utils";
import { api } from "../../../../helpers/topline-api";

export async function getCompleteCompaignInfo(id) {
  const response = await api.post(`/ads/get_complete_campaign_info_request`, {
    campaign_id: id,
  });
  const { data } = response;
  const { message: taskMessage } = data;
  const { message } = await getCronTaskResult(taskMessage);

  const {
    campaign_form,
    campaign_id,
    is_canceled,
    campaign_info,
    processing_status,
    campaign_status,
  } = message || {};
  const campaignData = {
    ...campaign_form,
    campaign_id,
    is_canceled,
    campaign_info,
    processing_status,
    campaign_status,
  };
  return campaignData;
}

export async function getCsmCalendly() {
  const response = await api.get(`/api/get-csm-calendly`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { data } = response;
  if (data?.calendly) {
    return data.calendly;
  }
}
