import { defaultPlans } from "../../pages/api/stripe/products";
import getUserInfo, { fetchStripeUserInfo } from "../utils";
import { stripeToast } from "./utils";

export default async function getUserStripeInfo(site_id: number) {
  const userProfile = await getUserInfo(site_id);
  if (!userProfile) return;
  const subscriptionId = "";
  const payment_methods = [
    {
      id: "",
      type: "",
      number: "",
      selected: false,
    },
  ];

  const current_plan = {
    id: "",
    type: "",
    billing_cycle: "",
    price: "",
    header: "",
    amount: 0,
  };

  return {
    customer_pk: userProfile.customer_pk,
    stripe_created_at: userProfile.stripe_created_at,
    user_type: userProfile.user_type,
    first_name: userProfile.first_name,
    last_name: userProfile.last_name,
    slug: userProfile.slug,
    domain: userProfile.domain,
    company_name: userProfile.company_name,
    review_link: userProfile.review_link,
    email: userProfile.email,
    personal_phone: userProfile.phone,
    stripe_customer_id: userProfile.customer_id,
    current_plan: current_plan,
    payment_methods: payment_methods,
    default_payment_method: null,
    main_subscription_id: subscriptionId,
    stripe_plans_in_price_tier: [],
    first_login_after_publish: userProfile.first_login_after_publish,
    stripe_connect_account_id: userProfile.stripe_connect_account_id,
    stripe_connect_payment_link: userProfile.stripe_connect_payment_link,
    who_pays_stripe_fees: userProfile.who_pays_stripe_fees,
    stripe_due_date: userProfile.stripe_due_date,
    stripe_payment_link_on_site: userProfile.stripe_payment_link_on_site,
    stripe_auto_follow_up_7_days: userProfile.stripe_auto_follow_up_7_days,
    total_login_count: userProfile.total_login_count,
    stripe_incentive_triggered: userProfile.stripe_incentive_triggered,
    default_tax_on: userProfile.default_tax_on,
    default_tax_type: userProfile.default_tax_type,
    default_tax_custom_type: userProfile.default_tax_custom_type,
    default_tax_rate: userProfile.default_tax_rate,
    download_app_popup_shown: userProfile.download_app_popup_shown,
    digital_media_popup_shown: userProfile.digital_media_popup_shown,
    physical_marketing_popup_shown: userProfile.physical_marketing_popup_shown,
    dashboard_connections_screen_shown:
      userProfile.dashboard_connections_screen_shown,
    payments_flag: userProfile.payments_flag,
    google_ads_account_id: userProfile.google_ads_account_id,
    blogs_flag: userProfile.blogs_flag,
    review_monthly_goal: userProfile.review_monthly_goal,
    gmb_location_id: userProfile.gmb_location_id,
    first_login_time: userProfile.first_login_time,
    show_facebook_popup_for_live_site_announcement:
      userProfile.show_facebook_popup_for_live_site_announcement,
    referral_id: userProfile.referral_id,
    landing_pages: userProfile.landing_pages,
    nylas_grant_id: userProfile.nylas_grant_id,
    nylas_configuration_ids: userProfile.nylas_configuration_ids,
    ai_assistant_notifications_on: userProfile.ai_assistant_notifications_on,
  };
}

function getPlansInTier(stripeCustomerInfo: any) {
  const currentPlan = stripeCustomerInfo?.current_plan;
  const latestCanceledPlan = stripeCustomerInfo?.latestCanceledPlan;

  const plansInTier = [...defaultPlans];

  const isDefaultPlan = plansInTier.some(
    (plan) => plan?.price === currentPlan?.price
  );

  if (isDefaultPlan && !currentPlan && !latestCanceledPlan) {
    return plansInTier;
  }

  const planType = currentPlan?.type || latestCanceledPlan?.type;
  const billingCycle =
    currentPlan?.billing_cycle || latestCanceledPlan?.billing_cycle;

  const planToReplace = plansInTier.find(
    (plan) => plan?.type === planType && plan?.billing_cycle === billingCycle
  );
  const index = plansInTier.findIndex(
    (plan) => plan?.price === planToReplace?.price
  );

  if (index && (currentPlan || latestCanceledPlan)) {
    const planToSubstitute = currentPlan ? currentPlan : latestCanceledPlan;
    plansInTier.splice(index, 1, planToSubstitute);
  }

  return plansInTier;
}

export const getStripeInformation = async (stripeCustomerId = "") => {
  if (!stripeCustomerId) {
    const plansInTier = [...defaultPlans];
    return { stripe_plans_in_price_tier: plansInTier };
  }

  const stripeCustomerInfo = await fetchStripeUserInfo(stripeCustomerId);

  if (stripeCustomerInfo === "error") {
    stripeToast("error");
    return {};
  }

  const plansInTier = getPlansInTier(stripeCustomerInfo);
  return { ...stripeCustomerInfo, stripe_plans_in_price_tier: plansInTier };
};
