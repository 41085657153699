export function formatPhoneNumberForDisplay(phoneNumber: string) {
  let formattedFrom;
  if (phoneNumber?.split("")[0] === "+" && phoneNumber?.split("")[1] === "1")
    phoneNumber = phoneNumber?.split("+1")[1];
  if (phoneNumber?.split("")[0] === "1")
    phoneNumber = phoneNumber?.split("1")[1];
  // const strippedFrom = phoneNumber.slice(2);
  const cleaned = `${phoneNumber}`.replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    formattedFrom = `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return formattedFrom || "";
}
