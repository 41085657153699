import React from "react";
import Image from "next/image";
import { formatPhoneNumberForDisplay } from "../../../helpers/formatPhoneNumber";
import {
  unformatPhoneNumber,
  validatePhoneNumber,
} from "../contacts/helpers/formatPhoneNumber";
import { getOptimizedCloudFrontImageUrl } from "../editor/utils";
import { UserIcon } from "@heroicons/react/solid";
import { api } from "../../../helpers/topline-api";

export async function initiateStripeConnect(
  slug: any,
  email: any,
  selected: string,
  origin?: string
) {
  try {
    const response = await api.post(`/api/onboard-user/${slug}`, {
      slug,
      email,
      selected,
      origin: origin ? origin : "payments",
    });
    const url = response?.data?.message?.url;
    return url;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function searchStripeCustomer(
  email: string,
  phone: string,
  slug: any,
  recipient: any
) {
  try {
    const response = await api.post(`/api/search-stripe-customer`, {
      email,
      phone,
      slug,
      recipient,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function createStripeConnectCustomer(
  slug: any,
  recipient: any,
  name?: string,
  email?: string,
  phone?: string
) {
  try {
    const response = await api.post(`/api/create-stripe-connect-customer`, {
      slug,
      recipient,
      name,
      email,
      phone,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function getStripeAccountInfo() {
  try {
    const response = await api.get(`/api/get-stripe-connect-account-info`);
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function getInvoicesFromDb() {
  try {
    const response = await api.get(`/api/get-invoices-from-db`);
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function getQuotesFromDb() {
  try {
    const response = await api.get(`/api/get-quotes-from-db`);
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function getAllInvoices(stripeAccountId: string) {
  try {
    const response = await api.get(`/api/get-all-invoices/${stripeAccountId}`);
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error getting all invoices:", e);
    return;
  }
}
export async function getAllInvoicesForPro(stripeCustomerId: string) {
  try {
    const response = await api.get(
      `/api/get-all-invoices-for-pro/${stripeCustomerId}`
    );
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error getting all invoices for pro:", e);
    return;
  }
}

export async function getAllQuotes(stripeAccountId: string) {
  try {
    const response = await api.get(`/api/get-all-quotes/${stripeAccountId}`);
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function getAllPayouts(stripeAccountId: string) {
  try {
    const response = await api.get(`/api/get-all-payouts/${stripeAccountId}`);
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function createStripeLink(
  stripeAccountId: any,
  slug: any,
  email: any
) {
  try {
    const response = await api.post(`/api/create-stripe-payment-link`, {
      stripeAccountId,
      slug,
      email,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function setDefaultStripeSettings(
  slug: any,
  fees: string,
  dueDate: string,
  paymentSettings: any,
  taxSettings: any
) {
  try {
    const response = await api.post(`/api/set-default-stripe-settings`, {
      slug,
      fees,
      dueDate,
      paymentSettings,
      taxSettings,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function setStripeFeeSettings(slug: any, fees: string) {
  try {
    const response = await api.post(`/api/set-stripe-fee-settings`, {
      slug,
      fees,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function setStripeDueDateSettings(slug: any, dueDate: string) {
  try {
    const response = await api.post(`/api/set-stripe-due-date-settings`, {
      slug,
      dueDate,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function createInvoice(
  customerId: any,
  slug: any,
  description: string,
  stripeAccountId: any,
  items: any,
  discount: any,
  discountType: any,
  taxAmount: any,
  taxName: any,
  fee: any
) {
  try {
    const response = await api.post(`/api/create-stripe-connect-invoice`, {
      customerId,
      slug,
      description,
      stripeAccountId,
      items,
      discount,
      discountType,
      taxAmount,
      taxName: taxName || "",
      fee,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function createQuote(
  customerId: any,
  slug: any,
  description: string,
  stripeAccountId: any,
  items: any,
  discount: any,
  discountType: any,
  taxAmount: any,
  taxName: any,
  requestType: string
) {
  try {
    const response = await api.post(`/api/create-stripe-connect-quote`, {
      customerId,
      slug,
      description,
      stripeAccountId,
      items,
      discount,
      discountType,
      taxAmount,
      taxName: taxName || "",
      requestType,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function finalizeInvoice(
  invoiceId: any,
  customerId: any,
  slug: any,
  description: string,
  stripeAccountId: any,
  items: any,
  discount: any,
  discountType: any,
  taxAmount: any,
  taxName: any,
  requestType: string,
  fee: any
) {
  try {
    const response = await api.post(`/api/finalize-invoice`, {
      invoiceId,
      customerId,
      slug,
      description,
      stripeAccountId,
      items,
      discount,
      discountType,
      taxAmount,
      taxName: taxName || "",
      requestType,
      fee,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function cancelInvoice(invoiceId: string, stripeAccountId: any) {
  try {
    const response = await api.post(`/api/cancel-stripe-connect-invoice`, {
      invoiceId,
      stripeAccountId,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function cancelQuote(quoteId: string, stripeAccountId: any) {
  try {
    const response = await api.post(`/api/cancel-stripe-connect-quote`, {
      quoteId,
      stripeAccountId,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function approveQuote(quoteId: string, stripeAccountId: any) {
  try {
    const response = await api.post(`/api/accept-stripe-connect-quote`, {
      quoteId,
      stripeAccountId,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function sendEmailOrTextInvoice(
  recipientId: string,
  slug: any,
  requestType: string,
  invoiceLink: string,
  totalPrice: any,
  recipientName: string,
  taxAmount: any,
  quoteId: any,
  invoiceId: any
) {
  try {
    const response = await api.post(`/api/post-payment-request`, {
      recipientId,
      slug,
      requestType,
      invoiceLink,
      amount: totalPrice,
      recipientName,
      taxAmount,
      quoteId,
      invoiceId,
    });
    return response;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function sendEmailOrTextQuote(
  recipientId: string,
  slug: any,
  requestType: string,
  quoteLink: string,
  totalPrice: any,
  recipientName: string,
  taxAmount: any,
  quoteId: any
) {
  try {
    const response = await api.post(`/api/post-quote-request`, {
      recipientId,
      slug,
      requestType,
      quoteLink,
      amount: totalPrice,
      recipientName,
      taxAmount,
      quoteId,
    });
    return response;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function bitly(invoiceLink: string) {
  try {
    const response = await api.post(`/api/link-to-bitly`, {
      invoiceLink: invoiceLink,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

function isNum(string: string) {
  return /\d/.test(string);
}

export function getNameFirstLetter(firstName: string) {
  if (firstName && !isNum(firstName) && !firstName.includes("@")) {
    return firstName.split("")[0].toUpperCase();
  } else return null;
}

export function getAvatar(contact: any, width?: string, height?: string) {
  const { first_name, last_name, profile_photo } = contact;
  const initials =
    first_name && last_name && first_name.split("")[0] + last_name.split("")[0];

  const firstNameFirstLetter = getNameFirstLetter(first_name);
  const lastNameFirstLetter = getNameFirstLetter(last_name);

  if (profile_photo && profile_photo !== '""') {
    const optimized_profile_photo =
      getOptimizedCloudFrontImageUrl(profile_photo);
    return (
      <div
        className={`relative min-w-40 ${width ? width : "w-10"} ${
          height ? height : "h-10"
        } rounded-full object-cover`}
      >
        <Image
          layout={"fill"}
          src={optimized_profile_photo}
          alt=""
          className={`${width ? width : "w-10"} ${
            height ? height : "h-10"
          } rounded-full object-cover`}
        />
      </div>
    );
  } else if (!profile_photo && !initials && firstNameFirstLetter)
    return (
      <span
        className={`inline-flex items-center justify-center min-w-40 ${
          width ? width : "w-10"
        } ${height ? height : "h-10"} rounded-full bg-gray-500`}
      >
        <span className="text-body-medium text-white">
          {firstNameFirstLetter}
        </span>
      </span>
    );
  else if (
    !profile_photo &&
    !initials &&
    !firstNameFirstLetter &&
    lastNameFirstLetter
  )
    return (
      <span
        className={`inline-flex items-center justify-center min-w-40 ${
          width ? width : "w-10"
        } ${height ? height : "h-10"} rounded-full bg-gray-500`}
      >
        <span className="text-body-medium text-white">
          {lastNameFirstLetter}
        </span>
      </span>
    );
  else if (firstNameFirstLetter && lastNameFirstLetter)
    return (
      <span
        className={`inline-flex items-center justify-center min-w-40 ${
          width ? width : "w-10"
        } ${height ? height : "h-10"} rounded-full bg-gray-500`}
      >
        <span className="text-body-medium text-white">{initials}</span>
      </span>
    );
  else
    return (
      <div className="h-10 w-10">
        <UserIcon
          className={`${width ? width : "w-10"} ${
            height ? height : "h-10"
          } p-3 rounded-full bg-gray-500 text-white `}
        />
      </div>
    );
}

export function formatEmailforStripe(recipientName: any, recipientEmail: any) {
  let email;
  if (recipientName.includes("@")) {
    email = recipientName;
  } else if (recipientEmail) {
    email = recipientEmail;
  } else {
    email = "";
  }
  return email;
}

export function formatPhoneforStripe(recipientName: any, recipientPhone: any) {
  let phone: any;
  if (recipientPhone) {
    const unformattedPhone = unformatPhoneNumber(recipientPhone);
    if (unformattedPhone.includes("+1")) {
      phone = unformattedPhone;
    } else {
      phone = "+1" + unformattedPhone;
    }
  } else if (validatePhoneNumber(recipientName)) {
    const unformattedPhone = unformatPhoneNumber(recipientName);
    if (unformattedPhone.includes("+1")) {
      phone = unformattedPhone;
    } else {
      phone = "+1" + unformattedPhone;
    }
  } else {
    phone = "";
  }
  return phone;
}

export const initalItemState = [
  {
    itemName: null,
    itemPrice: "",
  },
];

export const connectToStripeOptions = [
  {
    id: "company",
    name: "Company",
    description:
      "If you filed documentation to register your business with a government agency and have a tax-ID (EIN), select this option",
  },
  {
    id: "individual",
    name: "Individual",
    description:
      "If you have not filed paperwork to register as a business entity, select this option",
  },
];

export const whoPaysFeesOptions = [
  {
    id: "pro",
    name: "I will pay fees",
    description: "We recommend building them into your price",
  },
  {
    id: "customer",
    name: "Customers will pay fees",
    description: "A small additional fee will be added to your payment request",
  },
];

export const dueDateOptions = [
  {
    id: "now",
    name: "Due upon receipt",
    description: "Recommended",
  },
  { id: "7", name: "7 Days" },
  { id: "30", name: "30 Days" },
  { id: "none", name: "No due date" },
];

export const taxesOptions = [
  {
    id: "no",
    name: "Don’t charge taxes by default",
  },
  { id: "yes", name: "Charge taxes by default" },
];

export const paymentsOptions = [
  {
    id: "link",
    title: "Add payment link to my website",
    defaultChecked: true,
  },
  {
    id: "auto-follow-up",
    title: "Automatically follow up on payments due after 7 days",
    defaultChecked: true,
  },
];

export async function seedPaymentItems(slug: any) {
  try {
    const response = await api.post(`/api/seed-payment-items`, {
      slug,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function addLineItemDb(slug: any, itemName: string) {
  try {
    const response = await api.post(`/api/add-payment-items`, {
      slug,
      itemName,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function sendInvoiceReminder(
  invoiceId: string,
  invoiceUrl: string,
  slug: any
) {
  try {
    const response = await api.post(`/api/send-invoice-reminder`, {
      invoiceId,
      invoiceUrl,
      slug,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return e;
  }
}

export function numberWithCommas(number: any) {
  if (number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return "";
  }
}

export function stripCommas(number: any) {
  if (number) {
    number = number?.replace(/\,/g, "");
    number = parseFloat(number);
    return number;
  } else {
    return "";
  }
}

export function inBoth(array1: any, array2: any) {
  return findInBoth(array1, array2, true);
}

export function findInBoth(array1: any, array2: any, isUnion: any) {
  const result = [];

  for (let i = 0; i < array1.length; i++) {
    const item1 = array1[i];
    let found = false;
    for (let j = 0; j < array2.length && !found; j++) {
      found = item1.invoice_id === array2[j].id;
    }
    if (found === !!isUnion) {
      result.push(item1);
    }
  }
  return result;
}

export function getCustomerDisplayName(customerName: any) {
  const customerDisplayName = validatePhoneNumber(customerName)
    ? formatPhoneNumberForDisplay(customerName)
    : customerName;

  return customerDisplayName;
}

export function checkStatus(accountInfo: any, who_pays_stripe_fees: any) {
  if (
    accountInfo?.details_submitted &&
    accountInfo?.charges_enabled &&
    accountInfo?.requirements.eventually_due.length === 0 &&
    (who_pays_stripe_fees === "pro" || who_pays_stripe_fees === "customer")
  ) {
    return "complete";
  } else if (
    accountInfo?.details_submitted &&
    accountInfo?.charges_enabled &&
    accountInfo?.requirements.eventually_due.length > 0
  ) {
    if (
      accountInfo?.requirements.currently_due.length === 0 &&
      accountInfo?.requirements.eventually_due.length > 0 &&
      !!accountInfo?.requirements.current_deadline
    ) {
      return "restricted soon";
    } else if (
      accountInfo?.requirements.currently_due.length === 0 &&
      accountInfo?.requirements.eventually_due.length > 0 &&
      !accountInfo?.requirements.current_deadline
    ) {
      return "enabled";
    }
  } else if (
    accountInfo?.details_submitted &&
    accountInfo?.charges_enabled &&
    !who_pays_stripe_fees
  ) {
    return "fees";
  } else if (!accountInfo?.details_submitted) {
    return "incomplete";
  } else if (accountInfo?.details_submitted && !accountInfo?.charges_enabled) {
    if (accountInfo?.requirements.currently_due.length === 0) {
      return "pending";
    } else if (accountInfo?.requirements.currently_due.length > 0) {
      return "restricted";
    }
  }
  return "";
}

export function formatTwoDecimals(number: any) {
  const formattedNumber =
    number.indexOf(".") >= 0
      ? number.substr(0, number.indexOf(".")) +
        number.substr(number.indexOf("."), 3)
      : number;
  return formattedNumber;
}

export function formatCurrency(amount: any) {
  const number = parseFloat(amount);
  if (isNaN(number)) {
    return "";
  }
  const formattedNumber = number
    .toFixed(2)
    .toLocaleString("en-US", { style: "currency", currency: "USD" });

  return formattedNumber;
}
