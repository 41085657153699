const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT;

export const adsMonthlyId =
  ENVIRONMENT === "development"
    ? "price_1Mnli2KohomEkgjrHcUZyhxd"
    : "price_1MMhUVKohomEkgjrkeaNoWHw";

export const newEmailMonthlyId =
  ENVIRONMENT === "development"
    ? "price_1P5sYKKohomEkgjrAme2DaZQ"
    : "price_1P7GWOKohomEkgjrdHLZs2j9";

export const oldEmailMonthlyId =
  ENVIRONMENT === "development"
    ? "price_1KZ0GTKohomEkgjrBtF14fNw"
    : "price_1K5CzxKohomEkgjrlaA4Hdjk";

export const emailAnnualId =
  ENVIRONMENT === "development"
    ? "price_1P7GboKohomEkgjrwU4vvuzl"
    : "price_1P7GaiKohomEkgjre0UynmWi";

export const defaultPlans = [
  {
    type: "starter",
    billing_cycle: "monthly",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdmZKohomEkgjrz9garyN4"
        : "price_1OIe51KohomEkgjrwluunXmG",
    amount: 199,
    quantity: 1,
  },
  {
    type: "starter",
    billing_cycle: "annual",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdnNKohomEkgjreXiUriw9"
        : "price_1OIe5HKohomEkgjr11UOO0jc",
    amount: 1920,
    quantity: 1,
  },
  {
    type: "core",
    billing_cycle: "monthly",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdo7KohomEkgjr1v1G7zio"
        : "price_1OIe5nKohomEkgjryM7l8rPH",
    amount: 249,
    quantity: 1,
  },
  {
    type: "core",
    billing_cycle: "annual",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdoYKohomEkgjr4inkDV6k"
        : "price_1OIe5zKohomEkgjryrR6Ynf5",
    amount: 2400,
    quantity: 1,
  },
  {
    type: "pro",
    billing_cycle: "monthly",
    price:
      ENVIRONMENT === "development"
        ? "price_1OyG4pKohomEkgjrgo2oEUZd"
        : "price_1OyG5WKohomEkgjrAvjpmsBW",
    amount: 299,
    quantity: 1,
  },
  {
    type: "pro",
    billing_cycle: "annual",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdpgKohomEkgjrNu9tDNFj"
        : "price_1OIe6eKohomEkgjrUBdbvaYP",
    amount: 3840,
    quantity: 1,
  },
];

export const testPlans = [
  {
    type: "starter",
    billing_cycle: "monthly",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdmZKohomEkgjrz9garyN4"
        : "price_1OIe51KohomEkgjrwluunXmG",
    amount: 199,
    quantity: 1,
  },
  {
    type: "starter",
    billing_cycle: "annual",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdnNKohomEkgjreXiUriw9"
        : "price_1OIe5HKohomEkgjr11UOO0jc",
    amount: 1920,
    quantity: 1,
  },
  {
    type: "core",
    billing_cycle: "monthly",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdo7KohomEkgjr1v1G7zio"
        : "price_1OIe5nKohomEkgjryM7l8rPH",
    amount: 249,
    quantity: 1,
  },
  {
    type: "core",
    billing_cycle: "annual",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdoYKohomEkgjr4inkDV6k"
        : "price_1OIe5zKohomEkgjryrR6Ynf5",
    amount: 2400,
    quantity: 1,
  },
  {
    type: "pro",
    billing_cycle: "monthly",
    price:
      ENVIRONMENT === "development"
        ? "price_1OyG4pKohomEkgjrgo2oEUZd"
        : "price_1OyG5WKohomEkgjrAvjpmsBW",
    amount: 299,
    quantity: 1,
  },
  {
    type: "pro",
    billing_cycle: "annual",
    price:
      ENVIRONMENT === "development"
        ? "price_1OIdpgKohomEkgjrNu9tDNFj"
        : "price_1OIe6eKohomEkgjrUBdbvaYP",
    amount: 3840,
    quantity: 1,
  },
];
