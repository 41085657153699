import { IContact } from "../Contact/types";
import { sixMonthsPrior } from "../ContactsTable/utils";
import { getQbContacts } from "../helpers/getQbContacts";

export function checkIfAlreadySentRequestReviewWithin6Months(
  requestReviewDate: any
) {
  return requestReviewDate > sixMonthsPrior();
}
export const processQuickBookContacts = async (router: any, slug: string) => {
  const newQbContactsObj = await getQbContacts(
    "contacts",
    slug || "",
    router,
    ""
  );

  return newQbContactsObj;
};

// Sorting function for contacts based on first name
export const sortContactsByName = (contactsList: IContact[]) => {
  return contactsList.sort((a, b) =>
    a?.first_name?.localeCompare(b.first_name)
  );
};
