import { api } from "../../../../../helpers/topline-api";

export async function getYearInReviewVideo(slug: string) {
  try {
    const response = await api.get(`/api/get-year-in-review-video/${slug}`);
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return null;
  }
}

export async function checkEligibleForSummaryStats(slug: string) {
  try {
    const response = await api.get(
      `/api/check-eligible-for-summary-stats/${slug}`
    );
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return null;
  }
}

export async function savePhotosAndRenderVideo(slug, photos, metricsToggleOn) {
  try {
    const url = `/api/update-year-in-review-video/${slug}`;

    const response = await api.post(url, {
      photos,
      metricsToggleOn,
    });

    return response.data.message;
  } catch (e) {
    return;
  }
}

export async function getVideoStatus(videoUrl: string) {
  try {
    const response = await fetch(videoUrl, { method: "HEAD" });
    return response.status;
  } catch (e) {
    return 404;
  }
}
