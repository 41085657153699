const LANDING_PAGE_MEDIA_ADDRESS =
  "https://landing-page-app-hero-images.s3.amazonaws.com/media/";

const LANDING_PAGE_MEDIA_CLOUDFRONT_ADDRESS =
  "https://d3p2r6ofnvoe67.cloudfront.net/fit-in/media/";

const SHORT_MONTH_NAME = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];
export const ALL_APPLE_PATENT_IMAGE_FORMAT_ARRAY = ["heic", "heif"];

export const CONSTANTS = {
  LANDING_PAGE_MEDIA_ADDRESS,
  LANDING_PAGE_MEDIA_CLOUDFRONT_ADDRESS,
  SHORT_MONTH_NAME,
};

export const USE_IN_SOCIAL_MEDIA_GALLERY_NAME = "Use in Social Media";
