import { useState, useEffect } from "react";
import useWindowSize from "./useWindowSize";

export default function useIsMediumOrSmaller() {
  const [mobile, setMobile] = useState<boolean>();
  const size = useWindowSize();

  useEffect(() => {
    if (size[0] < 1024) {
      // width < 1024
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [size]);

  return mobile;
}
