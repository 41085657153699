import { api } from "../../helpers/topline-api";

export enum Features {
  AUTO_REVIEW_REPLY = "AUTO_REVIEW_REPLY",
  BLOG_CREATE = "BLOG_CREATE",
  DIGITAL_MEDIA_CREATE = "DIGITAL_MEDIA_CREATE",
  REVIEW_GENERATE_REPLY = "REVIEW_GENERATE_REPLY",
  SOCIAL_POST = "SOCIAL_POST",
  SOCIAL_GENERATE_POST = "SOCIAL_GENERATE_POST",
  SOCIAL_REWRITE_POST = "SOCIAL_REWRITE_POST",
  PHYSICAL_MARKETING_CREATE = "PHYSICAL_MARKETING_CREATE",
  LOCAL_OPPORTUNITIES_FEED = "LOCAL_OPPORTUNITIES_FEED",
}

export enum ActionTiming {
  Before = "before",
  After = "after",
}

export const FeatureGateModalInfo = {
  NO_PLAN: {
    header: "You're not currently on a plan.",
    subheading: "Please select a plan to use this feature.",
    icon: "flag",
  },
  DIGITAL_MEDIA_CREATE: {
    starter: {
      header:
        "You've reached the Starter Plan’s digital content generation limit.",
      subheading:
        "Upgrade to the Core Plan to generate unlimited custom digital content for social media.",
      icon: "flag",
    },
    core: {
      header: "Want us to automatically create images for you?",
      subheading:
        "Upgrade to the Pro Plan to have weekly social posting done for you.",
      icon: "sparkles",
    },
  },
  SOCIAL_POST: {
    starter: {
      header: "You’ve reached the Starter Plan’s weekly social posting limit.",
      subheading:
        "Upgrade to the Core Plan to unlock unlimited posting plus weekly Google posts done for you.",
      icon: "flag",
    },
    core: {
      header: "Want to automate your social media?",
      subheading:
        "Upgrade to the Pro Plan to have weekly social posting done for you.",
      icon: "sparkles",
    },
  },
  SOCIAL_GENERATE_POST: {
    starter: {
      header: "You've reached the Starter Plan's social post generation limit.",
      subheading:
        "Upgrade to the Core Plan to generate unlimited social posts.",
      icon: "flag",
    },
  },
  SOCIAL_REWRITE_POST: {
    starter: {
      header: "You've reached the Starter Plan's ‘Rewrite for me’ limit.",
      subheading:
        "Upgrade to the Core Plan to access unlimited ‘Rewrite for me’ usage.",
      icon: "flag",
    },
  },
  BLOG_CREATE: {
    starter: {
      header: "You’ve reached the Starter Plan’s blog generation limit.",
      subheading:
        "Upgrade to the Core plan to generate 5 blogs a month or gain unlimited access on our Pro plan.",
      icon: "flag",
    },
    core: {
      header: "You’ve reached the Core Plan monthly limit for blog generation.",
      subheading:
        "Upgrade to the Pro Plan to generate unlimited blog posts plus weekly blogs written for you.",
      icon: "flag",
    },
  },
  REVIEW_GENERATE_REPLY: {
    starter: {
      header: "You’ve reached the Starter Plan’s reply generation limit.",
      subheading:
        "Upgrade to the Core Plan to generate unlimited custom replies.",
      icon: "flag",
    },
    core: {
      header: "Want to automate your replies?",
      subheading:
        "Upgrade to the Pro Plan to automate replies for 5-star reviews.",
      icon: "sparkles",
    },
  },
  AUTO_REVIEW_REPLY: {
    starter: {
      header: "Want to automate your replies?",
      subheading:
        "Upgrade to the Core or Pro Plan to automate replies for 5-star reviews.",
      icon: "sparkles",
    },
  },
  PHYSICAL_MARKETING_CREATE: {
    starter: {
      header: "Your Starter Plan does not include 'Print Marketing'",
      subheading:
        "Upgrade to the Core Plan to unlock done for you print design and orders with our ‘Print Marketing’ feature.",
      icon: "flag",
    },
  },
  LOCAL_OPPORTUNITIES_FEED: {
    starter: {
      header: "Your Starter Plan does not include ‘Local Opportunities Feed’",
      subheading:
        "Upgrade to the Core Plan to access local business opportunities and connect with potential customers.",
      icon: "flag",
    },
  },
};

export function checkIfPlanGated(
  feature: keyof typeof FeatureGateModalInfo,
  plan: string
) {
  const featureGates = FeatureGateModalInfo[feature];
  if (!featureGates) {
    console.warn(`Feature ${feature} not found in FeatureGateModalInfo`);
    return false;
  }
  const gatedPlans = Object.keys(featureGates);
  // Plan could be a string like test_starter, so this checks if any part of the plan matches the gate.
  return gatedPlans.some((gatedPlan) => plan.includes(gatedPlan));
}

export async function postFeatureUsage(
  slug: string,
  feature_name: string,
  current_plan: string
) {
  try {
    const response = await api.post(`/api/feature-usage-post/${slug}`, {
      feature_name,
      current_plan,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return {};
  }
}

export async function getFeatureUsage(slug: string) {
  try {
    const response = await api.get(`/api/feature-usage-get/${slug}`);
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return {};
  }
}

export async function getFeatureLimits() {
  try {
    const response = await api.get(`/api/feature-limits-get`);
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return {};
  }
}
