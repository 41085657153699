import { URL_CONSTANTS } from "../constants/urlConstants";
import Image from "next/image";
import { api } from "../helpers/topline-api";

export function fetchTestimonialsBeta() {
  return api.get(URL_CONSTANTS.PROSITE_GET_TESTIMONIALS_BETS);
}

const placeholderReviews = [
  "Highly recommend! Showed up on time, did a great job, reasonably priced.",
  "Reach out today! They came in clutch when I was in need. Thank you!",
  "Professional, easy to work with, and quick to respond to any questions! Amazing team.",
  "So easy to work with! Will definitely use them again in the future.",
  "I tell everyone I know to give them a call - they're the best there is!",
  "Stop thinking about it, and just call them! They're worth every penny.",
  "Wouldn't go to anyone else. They're the best there is. Call them now!",
  "Easy to find, easy to work with, all around a 5-star experience.",
  "Recommend them 100% - so responsive and do great work. I keep getting compliments!",
  "Found them through Google, showed up the next day, stuck to their quote.",
  "Reliable, trustworthy, all around stellar. Check them out!",
  "It's simple really - they do great work at the right price. Calling them is a no brainer.",
  "Easy to follow and transparent pricing. Work quickly but do a good job. Had a great experience!",
  "Just all around awesome people to work with - great experience.",
];

export async function getTestimonials() {
  try {
    const reviewResponse = await fetchTestimonialsBeta();
    const reviews = reviewResponse?.data?.message;

    const review_info: any = Object.keys(reviews)
      .filter((key) => !placeholderReviews.includes(reviews[key]?.content))
      .map((key) => {
        const review = reviews[key];
        return {
          ...review,
          id: key,
          review_content: review?.content,
        };
      })
      .sort((a, b) => {
        return (
          new Date(b?.date_posted).getTime() -
          new Date(a?.date_posted).getTime()
        );
      });
    return review_info;
  } catch (error) {
    console.error("getTestimonials", error);
    return;
  }
}

export async function getTeammates(customer_pk: number) {
  try {
    const teammatesResponse = await api.get(
      `/api/get-teammates/${customer_pk}`
    );
    const teammates = teammatesResponse.data.message;
    const formattedTeammates = teammates.map((teammate) => {
      return {
        ...teammate,
        first_name: teammate.name.split(" ")[0] || teammate.name,
        last_name: teammate.name.split(" ")[1] || "",
      };
    });
    return formattedTeammates;
  } catch (error) {
    console.error("getTeammates", error);
    return;
  }
}

export async function getBookingRequests() {
  try {
    const response = await api.get(
      URL_CONSTANTS.PROSITE_GET_BOOKINGS_BY_LANDING_PAGE
    );
    return response?.data?.bookings || [];
  } catch (error) {
    console.error(error);
  }
}

export async function getBasicInfo(slug: string) {
  try {
    const landingPageResponse = await api.get(
      `${URL_CONSTANTS.PROSITE_GET_LANDING_PAGE}/${slug}`
    );

    const landingPage = landingPageResponse?.data;

    if (landingPage.status === "success") {
      const {
        logo,
        address,
        street_address,
        country,
        city,
        state,
        zip_code,
        email,
        phone,
        about,
        hero_gallery_photos,
        about_upload,
        sitePk,
        business_hours,
        company_tagline,
        toggle_auto_adds_on_connect,
        toggle_auto_adds_on_connect_reviews,
        auto_add_fb_reviews,
        auto_add_gmb_reviews,
        auto_add_fb_photos,
        auto_add_instagram_photos,
        auto_add_gmb_photos,
        default_upload_gallery,
        services,
        google_ads_account_id,
        service_areas,
        gmb_photos_toggle,
        gmb_business_info_toggle,
        gmb_posts_toggle,
        gmb_services_toggle,
        fb_photos_toggle,
        fb_business_info_toggle,
        fb_posts_toggle,
        ig_photos_toggle,
        ig_posts_toggle,
        auto_send_response_to_homeowner,
        bookings_reminders_period,
        custom_response_to_homeowner,
        facebook,
        custom_emails,
        default_review_link,
        review_link,
        review_link_more,
        leave_review_link_3,
        yelp_review_link,
        gmb_location_verification_status,
        gmb_refresh_token,
        gmb_access_token,
        gmb_token_expiration,
        google_contacts_refresh_token,
        google_contacts_access_token,
        google_contacts_token_expiration,
        gmb_location_id,
        gmb_account_id,
        fb_user_access_token,
        fb_page_access_token,
        fb_page_id,
        fb_tokens_expired,
        industry,
        custom_primary,
        custom_secondary,
        quickbooks_refresh_token,
        quickbooks_expiration_date,
        does_use_quickbooks,
        social_post_footer,
        is_social_footer_active,
        twilio_website_phone,
        website_call_forwarding_on,
        twilio_gbp_phone,
        gbp_call_forwarding_on,
        version,
        title_tag,
        meta_description,
        last_login_time,
        second_last_login_time,
        faqs,
        company_type,
        gmb_pending_at,
        review_links,
        default_request_review_text,
        teammates,
        custom_layout,
        ads_payment_failure,
        showing_tooltip_count,
        website_call_bookings_reminders_on,
        gbp_call_bookings_reminders_on,
        website_text_bookings_reminders_on,
        google_service_account,
        add_related_showcase_to_homeowner_response,
        appointment_scheduling,
        nylas_grant_id,
        nylas_auth_token,
      } = landingPage?.message || {};
      return {
        logo,
        faqs,
        address,
        street_address,
        country,
        city,
        state,
        zip_code,
        email,
        phone,
        about,
        hero_gallery_photos,
        about_upload,
        sitePk,
        business_hours,
        company_tagline,
        toggle_auto_adds_on_connect,
        toggle_auto_adds_on_connect_reviews,
        auto_add_fb_reviews,
        google_ads_account_id,
        auto_add_gmb_reviews,
        auto_add_fb_photos,
        auto_add_instagram_photos,
        auto_add_gmb_photos,
        default_upload_gallery,
        services,
        service_areas,
        gmb_photos_toggle,
        gmb_business_info_toggle,
        gmb_posts_toggle,
        gmb_services_toggle,
        fb_photos_toggle,
        fb_business_info_toggle,
        fb_posts_toggle,
        ig_photos_toggle,
        ig_posts_toggle,
        facebook,
        custom_emails,
        default_review_link,
        review_link,
        review_link_more,
        leave_review_link_3,
        yelp_review_link,
        gmb_location_verification_status,
        gmb_refresh_token,
        gmb_access_token,
        gmb_token_expiration,
        google_contacts_refresh_token,
        google_contacts_access_token,
        google_contacts_token_expiration,
        google_service_account,
        gmb_location_id,
        gmb_account_id,
        fb_user_access_token,
        fb_page_access_token,
        fb_page_id,
        fb_tokens_expired,
        auto_send_response_to_homeowner,
        bookings_reminders_period,
        custom_response_to_homeowner,
        industry,
        custom_primary,
        custom_secondary,
        quickbooks_refresh_token,
        quickbooks_expiration_date,
        does_use_quickbooks,
        social_post_footer,
        is_social_footer_active,
        twilio_website_phone,
        website_call_forwarding_on,
        twilio_gbp_phone,
        gbp_call_forwarding_on,
        version,
        title_tag,
        meta_description,
        last_login_time,
        second_last_login_time,
        company_type,
        gmb_pending_at,
        review_links,
        default_request_review_text,
        teammates,
        custom_layout,
        ads_payment_failure,
        showing_tooltip_count,
        website_call_bookings_reminders_on,
        gbp_call_bookings_reminders_on,
        website_text_bookings_reminders_on,
        add_related_showcase_to_homeowner_response,
        appointment_scheduling,
        nylas_grant_id,
        nylas_auth_token,
      };
    } else return null;
  } catch {
    console.log("no booking requests");
  }
}

export async function incrementLoginCount() {
  try {
    const response = await api.get(`/api/increment-total-login-count`);
    return response;
  } catch (error) {
    console.log("error:", error);
    return;
  }
}

// fetch image from url by creating Image object
export async function fetchImage(url: string) {
  <Image src={url} layout={"fill"} alt="" className="hidden" />;
}

export const getUserCampaignInfo = async () => {
  try {
    const response = await api.get(`/ads/get_campaigns`);
    return response.data;
  } catch (error) {
    console.error("getUserCampaignInfo", error);
    return;
  }
};

export const fetchAllSeoInfo = async (slug: string) => {
  try {
    const response = await api.get(`/api/get-page-speed-seo/${slug}`);
    return response.data.message;
  } catch (error) {
    console.error("Error fetchAllSeoInfo", error);
    return;
  }
};

export const fetchGBPSeoInfo = async (slug: string) => {
  try {
    const response = await api.get(`/api/get-gbp-seo-info/${slug}`);
    return response.data.message;
  } catch (error) {
    console.error("Error fetchGBPSeoInfo", error);
    return;
  }
};

export const fetchWordsAndPageSeoInfo = async (slug: string) => {
  try {
    const response = await api.get(`/api/get-words-and-page-seo-info/${slug}`);
    return response.data.message;
  } catch (error) {
    console.error("Error fetchWordsAndPageSeoInfo", error);
    return;
  }
};

export function getNumberOfWeeksInLast30Days(): number | null {
  try {
    const oneDayInMilliseconds: number = 24 * 60 * 60 * 1000; // Number of milliseconds in one day
    const currentDate: Date = new Date(); // Get the current date

    // Validate if currentDate is a valid date
    if (!(currentDate instanceof Date) || isNaN(currentDate.getTime())) {
      throw new Error("Invalid date provided.");
    }

    // Calculate the starting date (30 days ago)
    const thirtyDaysAgo: Date = new Date(
      currentDate.getTime() - 30 * oneDayInMilliseconds
    );

    // Validate if thirtyDaysAgo is a valid date
    if (!(thirtyDaysAgo instanceof Date) || isNaN(thirtyDaysAgo.getTime())) {
      throw new Error("Error calculating the starting date.");
    }

    // Calculate the difference in milliseconds between the current date and the starting date
    const timeDifferenceInMilliseconds: number =
      currentDate.getTime() - thirtyDaysAgo.getTime();

    // Calculate the number of weeks
    const numberOfWeeks: number = Math.floor(
      timeDifferenceInMilliseconds / (7 * oneDayInMilliseconds)
    );

    return numberOfWeeks;
  } catch (error) {
    console.error(`An error occurred: ${error.message}`);
    return null; // Return null to indicate that the result is not valid
  }
}

export function getAveragePostPerWeek(dataArray: any[], source: string) {
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  let timeKey: string = "";

  if (source === "facebook") {
    timeKey = "created_time";
  } else if (source === "google") {
    timeKey = "createTime";
  }

  if (!dataArray || dataArray.length === 0) {
    return 0;
  }

  // handle when there are only 0 or 1 posts in last 30 days
  if (!dataArray) {
    return 0;
  }

  if (dataArray?.length === 0 || dataArray?.length === 1) {
    return dataArray?.length;
  }

  const oneWeek = 1000 * 60 * 60 * 24 * 7;
  const fourWeeksAgo = new Date().getTime() - 4 * oneWeek;

  const postsInLastFourWeeks = dataArray?.filter((post) => {
    const postDate = new Date(post[timeKey]).getTime();
    return postDate >= fourWeeksAgo;
  });

  const averagePostsPerWeek = postsInLastFourWeeks.length / 4;

  return parseFloat(averagePostsPerWeek.toFixed(1));
}

export function getAverageBlogPerMonth(dataArray: any[]) {
  if (dataArray?.length === 1) {
    return 1;
  }

  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  const blogsInLastMonth = dataArray.filter(
    (blog: any) => new Date(blog.created_at) > oneMonthAgo
  );
  const averageBlogs = blogsInLastMonth.length;

  return averageBlogs;
}

export const getTopThreeCompetitors = async (slug: string) => {
  try {
    const response = await api.get(
      `${URL_CONSTANTS.PROSITE_GET_COMPETITORS}/${slug}`
    );
    return response.data.message;
  } catch (error) {
    console.error("error getting top three competitors", error);
    return;
  }
};

export async function getAllDigitalMedia(slug: string) {
  try {
    const response = await api.get(`/api/digital-asset-get-all/${slug}`);
    return response.data.message;
  } catch (error) {
    console.error("error getting all digital media", error);
    return;
  }
}

export async function getDigitalMediaDefaults(slug: string) {
  try {
    const response = await api.get(`/api/digital-asset-get-defaults/${slug}`);
    return response.data.message;
  } catch (error) {
    console.error("error getting digital media defaults", error);
    return;
  }
}

export async function getPhysicalMarketingDefaults(slug: string) {
  try {
    const response = await api.get(
      `/api/physical-marketing-get-defaults/${slug}`
    );
    return response.data.message;
  } catch (error) {
    console.error("error getting physical marketing defaults", error);
    return;
  }
}

export const clearAllCookies = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};
