import { videoTypesComplete, videoTypesExtensions } from "./constants";

export const isMediaTypeVideo = (media: any) => {
  if (media?.type) {
    const type = getMediaType(media);
    if (type.includes("video")) {
      return true;
    }
  }

  const extension = getMediaTypeExtension(media);

  if (videoTypesExtensions.includes(extension)) {
    return true;
  }

  return false;
};
export const getMediaType = (media: any) => {
  if (media?.type && videoTypesComplete.includes(media?.type)) {
    return media.type;
  }
  if (media?.type) {
    return media?.type;
  }
  if (media?.photo) {
    const mediaSplit = media.photo.split(".");
    return `video/${mediaSplit[mediaSplit.length - 1]}`;
  }

  if (media && media.data) {
    const mediaSplit = media.data.split(".");
    return `video/${mediaSplit[mediaSplit.length - 1]}`;
  }
  return "";
};
export const getMediaTypeExtension = (media: any) => {
  if (media?.photo && media?.photo?.length > 0) {
    const mediaSrc = media.photo.toLowerCase();
    const mediaSrcSplit = mediaSrc.split(".");
    return mediaSrcSplit[mediaSrcSplit.length - 1];
  }
  return "";
};
