import axios from "axios";
import { CONSTANTS, USE_IN_SOCIAL_MEDIA_GALLERY_NAME } from "./constants";
import { isMediaTypeVideo } from "../../common/GalleryAsset/utils";
import {
  videoTypesComplete,
  videoTypesExtensions,
} from "../../common/GalleryAsset/constants";
import { deleteImages } from "../../../helpers/uploadFileUtil";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { v4 as uuidv4 } from "uuid";
import {
  FormattedGalleryPhoto,
  Gallery,
  GalleryPhoto,
  ProfileInfo,
  Service,
  ServicePhoto,
} from "../../../contexts/types";
import moment from "moment";
import { createFile, getUuid } from "./BeforeAndAfterModal/utils";
import { api } from "../../../helpers/topline-api";

export function arrayMove(arr: Array<any>, fromIndex: number, toIndex: number) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

export async function reorderGalleryPhotos(
  photos: Array<any>,
  gallery: string
) {
  await api.post(`/api/reorderGalleryPhotos`, {
    photos,
    gallery,
  });
}

export async function movePhotosDifferentGallery(
  photos: Array<any>,
  gallery: string
) {
  await api.post(`/api/move-photos-different-gallery`, {
    photos,
    gallery,
  });
}

export async function postGallery(gallery: string) {
  const response = await api.post(`/api/createGallery`, {
    "gallery-name": gallery,
    "gallery-subtitle": "Our Best Work",
  });
  return response.data;
}

export async function publishRoute() {
  return api.get(`/api/publish_route`);
}

export async function createPhotosDb(
  images: any[],
  gallery: string,
  uploadTo: any
) {
  const response = await api.post(`/api/new-photo`, {
    images,
    gallery_name: gallery,
    upload_to: uploadTo,
  });
  return response.data.message;
}

export async function editBeforeAfterDb(photo: any, gallery: string) {
  const response = await api.post(`/api/edit-before-after-photo`, {
    photo,
    gallery,
  });
  return response.data.message;
}

export const convertToSmallCloudfrontImage = (url: string) => {
  if (url) {
    const uuid = getUuid(url);
    return `https://d3p2r6ofnvoe67.cloudfront.net/fit-in/250x250/filters:no_upscale()/filters:format(webp)/filters:strip_exif()/media/${uuid}`;
  }
  return "";
};

export const convertImageUrlToCloudfrontUrl = (url: string) => {
  return url?.replace(
    CONSTANTS.LANDING_PAGE_MEDIA_ADDRESS,
    CONSTANTS.LANDING_PAGE_MEDIA_CLOUDFRONT_ADDRESS
  );
};

export const getOptimizedCloudFrontImageUrl = (url: string) => {
  if (url) {
    const convertedImageSrc = convertImageUrlToCloudfrontUrl(url);
    const optimized = convertedImageSrc?.replace(
      "fit-in",
      "fit-in/500x500/filters:format(webp)/filters:quality(40)/filters:strip_exif()"
    );
    return optimized;
  }
  return url;
};

export const getCloudfrontUrl1200 = (url: string) => {
  if (url) {
    const convertedImageSrc = convertImageUrlToCloudfrontUrl(url);
    const optimized = convertedImageSrc?.replace(
      "fit-in",
      "fit-in/1200x1200/filters:format(webp)/filters:quality(100)/filters:strip_exif()"
    );
    return optimized;
  }
  return url;
};

export const formatImageInfo = (photo: GalleryPhoto): FormattedGalleryPhoto => {
  const isVideo = isMediaTypeVideo(photo);
  let cloudFrontURL;

  if (isVideo) {
    cloudFrontURL = photo.photo;
  } else {
    cloudFrontURL = convertToSmallCloudfrontImage(photo.photo);
  }

  return {
    id: photo.photo_pk,
    photo: cloudFrontURL,
    data: photo.photo,
    url: photo.photo,
    gallery_name: photo.gallery_name ? photo.gallery_name : "",
    all_photos_order: photo.all_photos_order,
    gallery_order: photo.gallery_order,
    homepage_order: photo.homepage_order,
    shared_on_social: photo.shared_on_social,
    pro_post_status: photo.pro_post_status,
    created_at: photo.created_at,
    source: photo.source,
    gpt_description: photo.gpt_description,
    is_good_for_post: photo.is_good_for_post,
    before_after: photo.before_after || false,
    before_after_id: photo.before_after_id || null,
    before_after_photo_1: photo.before_after_photo_1 || null,
    before_after_photo_2: photo.before_after_photo_2 || null,
    before_after_layout: photo.before_after_layout || null,
    before_after_text: photo.before_after_text || null,
    before_after_logo: photo.before_after_logo || null,
    before_after_qr: photo.before_after_qr || null,
  };
};

export const isVideoUrl = (url: string) => {
  if (url) {
    const mediaSrc = url.toLowerCase();
    const mediaSrcSplit = mediaSrc.split(".");
    const extension = mediaSrcSplit[mediaSrcSplit.length - 1];
    if (videoTypesExtensions.includes(extension)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isVideo = (media: any) => {
  const type = getMediaType(media);
  if (type.includes("video")) {
    return true;
  }
  const extension = getMediaTypeExtension(media);
  const video = videoTypesExtensions.includes(extension);
  if (video) {
    return true;
  }
  return false;
};

export const getMediaType = (media: any) => {
  if (media && media.type && videoTypesComplete.includes(media.type)) {
    return media.type;
  }
  if (media && media.type) {
    return media.type;
  }
  return "";
};

export const getMediaTypeExtension = (media: any) => {
  if (media?.photo && media.photo.length > 0) {
    const mediaSrc = media.photo.toLowerCase();
    const mediaSrcSplit = mediaSrc.split(".");
    return mediaSrcSplit[mediaSrcSplit.length - 1];
  }
  return "";
};

export const updateGalleryAttribute = async (updatedGalleryAttributes: any) => {
  const response = await api.post(
    `/api/updateGallery`,
    updatedGalleryAttributes
  );
  return response.data;
};

export const deleteGallery = async (id: number) => {
  const response = await api.post(`/api/deleteGallery`, { id });
  return response.data;
};
export function timeSince(date: string) {
  const seconds = Math.floor((new Date() - Date.parse(date)) / 1000);
  let interval = seconds / 31536000;

  if (interval > 1) {
    const years = Math.floor(interval) === 1 ? "year" : "years";
    return Math.floor(interval) + ` ${years}`;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const months = Math.floor(interval) === 1 ? "month" : "months";
    return Math.floor(interval) + ` ${months}`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    const days = Math.floor(interval) === 1 ? "day" : "days";
    return Math.floor(interval) + ` ${days}`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    const hours = Math.floor(interval) === 1 ? "hour" : "hours";
    return Math.floor(interval) + ` ${hours}`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    const minutes = Math.floor(interval) === 1 ? "minute" : "minutes";
    return Math.floor(interval) + ` ${minutes}`;
  }
  return Math.floor(seconds) + " seconds";
}

function checkIfNewlyPulled(photo: FormattedGalleryPhoto, secondLastLoginTime) {
  const targetDate = new Date(secondLastLoginTime);
  const createdAtDate = new Date(photo.created_at);
  const source = photo.source;

  if (createdAtDate > targetDate && source === "pulled - facebook") {
    photo = { ...photo, newly_pulled: "facebook" };
  } else if (createdAtDate > targetDate && source === "pulled - google") {
    photo = { ...photo, newly_pulled: "google" };
  } else if (createdAtDate > targetDate && source === "pulled - instagram") {
    photo = { ...photo, newly_pulled: "instagram" };
  } else {
    photo = { ...photo, newly_pulled: "" };
  }

  return photo;
}

export function groupPhotosByGallery(
  photos: GalleryPhoto[] | undefined,
  galleriesArray: Gallery[] | undefined,
  secondLastLoginTime: string | null
) {
  const allPhotosObject: { [key: string]: FormattedGalleryPhoto[] } = {};
  const allPhotosKey = "All Photos";
  allPhotosObject[allPhotosKey] = [];
  const featuredPhotoKey = USE_IN_SOCIAL_MEDIA_GALLERY_NAME;
  allPhotosObject[featuredPhotoKey] = [];

  galleriesArray?.forEach((gallery: Gallery) => {
    if (gallery["gallery-name"]) {
      allPhotosObject[gallery["gallery-name"]] = [];
    }
  });

  photos?.forEach((photo: GalleryPhoto) => {
    let formattedPhoto = formatImageInfo(photo);
    formattedPhoto = checkIfNewlyPulled(formattedPhoto, secondLastLoginTime);

    allPhotosObject[allPhotosKey].push(formattedPhoto);

    if (photo.gallery_name && allPhotosObject[photo.gallery_name]) {
      allPhotosObject[photo.gallery_name].push(formattedPhoto);
    }
    if (photo.is_good_for_post) {
      allPhotosObject[featuredPhotoKey].push(formattedPhoto);
    }
  });

  allPhotosObject[allPhotosKey]?.sort(
    (a, b) => b.all_photos_order - a.all_photos_order
  );
  allPhotosObject[featuredPhotoKey]?.sort((a, b) => {
    if (a.shared_on_social && !b.shared_on_social) {
      return 1;
    } else if (!a.shared_on_social && b.shared_on_social) {
      return -1;
    } else {
      return b.all_photos_order - a.all_photos_order;
    }
  });

  galleriesArray?.forEach((gallery: any) => {
    allPhotosObject[gallery["gallery-name"]].sort(
      (a, b) => b.gallery_order - a.gallery_order
    );
  });

  return allPhotosObject;
}

export async function getFacebookProfileCoverPhotos(
  facebookPageId,
  facebookPageToken
) {
  try {
    const url = `https://graph.facebook.com/v15.0/${facebookPageId}?fields=cover,picture.type(large).redirect(false)&access_token=${facebookPageToken}`;
    const response = await fetch(url, {
      method: "GET",
    });
    const responseData = await response.json();
    if (responseData.error) {
      console.log(responseData.error.message);
      throw Error(responseData.error.error_subcode);
    }
    return responseData;
  } catch (error) {
    console.log("error getting facebook profile picture");
    const errorResponse = [];
    return errorResponse;
  }
}

export async function getInstagramPosts() {
  const allInstagramPostResponse = await api.get(`/api/instagram-connect`);

  const instagramPostIDs = allInstagramPostResponse?.data?.message?.media || [];

  const instagramFetchedPosts = await Promise.all(
    instagramPostIDs.map(async (postId: string) => {
      const postResponse = await api.get(`/api/instagram/posts/${postId}`);
      return postResponse?.data?.message?.post;
    })
  );

  const instagramPosts = instagramFetchedPosts.map((post) => {
    return {
      ...post,
      message: post?.caption || "",
      created_time: post?.timestamp,
      full_picture: post?.media_url,
      origin: {
        instagram: true,
      },
    };
  });
  return instagramPosts;
}

export async function getFacebookPosts(facebookPageId, facebookPageToken) {
  try {
    const baseUrl = `https://graph.facebook.com/v18.0/${facebookPageId}/feed?limit=100&fields=created_time,full_picture,id,message,attachments{media.limit(100),target.limit(100),type.limit(100),subattachments.limit(100)}&access_token=${facebookPageToken}`;
    let url = baseUrl;
    let posts: any[] = [];

    while (url) {
      const response = await axios.get(url);

      const responseData = response?.data;
      const postsArray = responseData?.data;

      posts = [...posts, ...postsArray];
      url = responseData?.paging?.next;
    }

    return { data: posts };
  } catch (error) {
    console.error("Error:", error);
    return { data: [] };
  }
}

export async function postFacebookMessage(
  message,
  facebookPageId,
  facebookPageToken,
  photo?
) {
  try {
    const response = await axios.post(
      `https://graph.facebook.com/v14.0/${facebookPageId}/${
        photo ? "photos" : "feed"
      }?access_token=${facebookPageToken}`,
      photo ? { message: message, url: photo.url } : { message: message }
    );
    return response;
  } catch (error) {
    console.log("error:", error);
    return { status: "error" };
  }
}

export async function deleteFacebookPost(slug, postIds) {
  try {
    const response = await api.post(
      `/api/social-delete-post-facebook/${slug}`,
      {
        postIds,
      }
    );
    return response.data.message;
  } catch (error) {
    console.log("error", error);
    return "error";
  }
}

export async function deleteGooglePost(slug, postIds) {
  try {
    const response = await api.post(`/api/social-delete-post-google/${slug}`, {
      postIds,
    });
    return response.data.message;
  } catch (error) {
    console.log("error", error);
    return "error";
  }
}

export async function editFacebookPost(slug, postIds, message, attachedMedia) {
  try {
    const response = await api.post(`/api/social-edit-post-facebook/${slug}`, {
      postIds,
      message,
      attachedMedia,
    });
    return response.data.message;
  } catch (error) {
    console.log("error", error);
    return "error";
  }
}

export async function editGooglePost(slug, postIds, message, attachedMedia?) {
  try {
    const response = await api.post(`/api/social-edit-post-google/${slug}`, {
      postIds,
      message,
      attachedMedia,
    });
    return response.data.message;
  } catch (error) {
    console.log("error", error);
    return "error";
  }
}

export async function getNewGmbPhotos(sitePk) {
  try {
    const url = `/api/get-new-gmb-photos-by-page/${sitePk}`;
    const response = await api.get(url);
    return response.data.message;
  } catch (e) {
    console.log(e, "error on getGmbPhotos");
    return [];
  }
}

export async function getNewFbPhotos(sitePk) {
  try {
    const url = `/api/get-new-fb-photos-by-page/${sitePk}`;
    const response = await api.get(url);
    return response.data;
  } catch (e) {
    console.log(e, "error on get-new-fb-photos-by-page");
    return [];
  }
}

export async function getNewIgPhotos(sitePk) {
  try {
    const url = `/api/get-new-ig-photos-by-page/${sitePk}`;
    const response = await api.get(url);
    return response.data;
  } catch (e) {
    console.log(e, "error on get-new-ig-photos-by-page");
    return [];
  }
}

export async function getGmbPhotos(accessToken, accountId, locationId) {
  try {
    const apiUrl = `https://mybusiness.googleapis.com/v4/${accountId}/${locationId}/media`;
    const images = await axios.get(apiUrl, {
      headers: {
        authorization: "Bearer " + accessToken,
      },
    });
    return images.data;
  } catch (e) {
    console.log(e, "error on getGmbPhotos");
    return [];
  }
}

export async function addPhotoToGmbLocation(
  photo,
  category, // https://developers.google.com/my-business/reference/rest/v4/accounts.locations.media#MediaItem.Category
  accessToken,
  accountId,
  locationId
): any {
  // const createUrl = `https://mybusiness.googleapis.com/v4/accounts/107213582681738842803/locations/13327188690133619531/media`
  const createUrl = `https://mybusiness.googleapis.com/v4/${accountId}/${locationId}/media`;
  const value = {
    mediaFormat: "PHOTO",
    locationAssociation: {
      category,
    },
    sourceUrl: photo,
    description: photo.split(
      // TODO: get rid of this description altogether and eventually populate it with alt tag text
      "https://landing-page-app-hero-images.s3.amazonaws.com/media/" // TODO: make a utility function that gets the uuid only
    )[1],
  };
  let uploadedMedia;
  try {
    uploadedMedia = await axios.post(
      createUrl,
      { ...value },
      {
        headers: {
          authorization: "Bearer " + accessToken,
        },
      }
    );
  } catch (e) {
    console.log(e, "err");
  }
  console.log(uploadedMedia?.data, "return from gmb");
  // Error handling
  let returned;
  if (uploadedMedia?.data) returned = uploadedMedia?.data;
  else if (category === "COVER")
    returned =
      "Cover photo must be less than 2120px wide and 1928px tall. Also, check that the aspect ratio is around 1.77 (w:h).";
  else if (category === "LOGO") returned = "Error uploading logo";
  else returned = `Error uploading photo`;
  return returned;
}

export async function createGmbPost(
  slug: string,
  message: string,
  photos?: any[]
) {
  try {
    const response = await api.post(`/api/social-create-post-google/${slug}`, {
      message,
      photos,
    });
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return "error";
  }
}

export async function createFacebookPost(
  slug: string,
  message: string,
  photos?: any[]
) {
  try {
    const response = await api.post(
      `/api/social-create-post-facebook/${slug}`,
      {
        message,
        photos,
      }
    );
    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return "error";
  }
}

export async function getGmbPosts(
  accessToken: string,
  accountId: string,
  locationId: string
) {
  const apiUrl = `https://mybusiness.googleapis.com/v4/${accountId}/${locationId}/localPosts?pageSize=100`;

  const gmbPosts = await axios.get(apiUrl, {
    headers: {
      authorization: "Bearer " + accessToken,
    },
  });

  return gmbPosts?.data?.localPosts || [];
}

export async function updateServiceToDb(
  serviceInfo: Service,
  newPhotos: ServicePhoto[],
  slug: string
) {
  const response = await api.post(
    `/api/update-service/${slug}`,
    {
      service: serviceInfo,
      newPhotos: newPhotos,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const responseData = response.data;
  const newServices = responseData.message.data;
  return newServices;
}

export async function addServiceToDb(
  serviceInfo: Service,
  newPhotos: ServicePhoto[],
  slug: string
) {
  try {
    const response = await api.post(
      `/api/add-service/${slug}`,
      {
        service: serviceInfo,
        newPhotos: newPhotos,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = response.data;
    const newServices = responseData.message.data;
    return newServices;
  } catch (error) {
    console.log("error", error);
    return { error: error };
  }
}

export async function deleteServiceFromDb(
  serviceToBeDeleted: string,
  slug: string
) {
  const response = await api.post(
    `/api/delete-service/${slug}`,
    {
      service: serviceToBeDeleted,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const responseData = response.data;
  const newServices = responseData.message.data;
  return newServices;
}

export async function updateGmbServices(sitePk) {
  try {
    const response = await api.post(`/api/update-services-google/${sitePk}`);
    return response?.data?.message;
  } catch (e) {
    return "failure";
  }
}

export async function updateGmbAttribute(
  attribute,
  value,
  accessToken,
  accountId,
  locationId
) {
  try {
    const locationsUrl = `https://mybusinessbusinessinformation.googleapis.com/v1/${accountId}/locations?read_mask=name,title,${attribute}`;
    const locationsRes = await axios.get(locationsUrl, {
      headers: {
        authorization: "Bearer " + accessToken,
      },
    });

    const location = locationsRes.data.locations[0];
    const apiUrl = `https://mybusinessbusinessinformation.googleapis.com/v1/${locationId}?updateMask=${attribute}&validateOnly=${
      process.env.NODE_ENV === "development" ? "true" : "false"
    }`;

    let updatedAtrribute;
    const valueOnGmb = location[value];
    if (valueOnGmb !== value) {
      updatedAtrribute = await axios.patch(
        apiUrl,
        { ...location, [attribute]: value },
        {
          headers: {
            authorization: "Bearer " + accessToken,
          },
        }
      );
      console.log(updatedAtrribute?.data, "return from gmb");
    }

    return updatedAtrribute?.data;

    // Error handling
  } catch (e) {
    console.log(e, "err");
    return { error: e };
  }
}

export async function storeDatabaseToggleStatus(attribute, slug) {
  const response = await api.get(`/api/toggle-social/${slug}/${attribute}`);
  const responseData = await response.data;
  return responseData;
}

export const objectsEqual = (o1, o2) =>
  typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export const arraysEqual = (a1, a2) => {
  return (
    a1 &&
    a2 &&
    a1.length === a2.length &&
    a1.every((o, idx) => {
      return objectsEqual(o, a2[idx]);
    })
  );
};

export function twoArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  const arr1String = JSON.stringify(arr1);
  const arr2String = JSON.stringify(arr2);

  return arr1String === arr2String;
}

export async function getScheduledPosts(slug: string) {
  try {
    const response = await api.get(`/api/get-scheduled-posts/${slug}`);
    return response.data?.message;
  } catch (e) {
    console.log("error", e);
  }
}

export async function deleteScheduledPost(post_id) {
  try {
    const response = await api.get(`/api/delete-scheduled-post/${post_id}`);
    return response.data;
  } catch (e) {
    console.log("error", e);
  }
}

export async function editScheduledPost(time, message, post_id, photo_links) {
  try {
    const response = await api.post(`/api/edit-scheduled-post/${post_id}`, {
      created_time: time,
      message,
      photo_links,
    });

    return response.data.message;
  } catch (e) {
    console.log("error", e);
  }
}

export async function schedulePost(
  time,
  message,
  site_pk,
  attached_photos,
  source
) {
  try {
    const response = await api.post(
      `/api/schedule-post-from-front-end-endpoint`,
      {
        time,
        message,
        site_pk,
        attached_photos,
        source,
      }
    );
    return response.data;
  } catch (e) {
    console.log("error", e);
  }
}

export function getNextHourAndMinutes(date: Date) {
  let hours = date.getHours() + 1;
  const minutes = date.getMinutes();

  if (hours === 24) {
    hours = 0;
  }

  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  const strTime = formattedHours + ":" + formattedMinutes;
  return strTime;
}

export function getDayOfOneHourFromNow(today: Date) {
  const hours = today.getHours() + 1;
  if (hours === 24) {
    return moment().add(1, "days").format("MMM D, YYYY");
  } else {
    return moment().format("MMM D, YYYY");
  }
}

export const getHeightAndWidthFromDataUrl = (dataURL: string) => {
  const img = new Image();
  img.crossOrigin = "anonymous";
  let width, height;
  img.onload = function () {
    width = this?.width;
    height = this?.height;
  };
  img.src = dataURL;

  return {
    height: height,
    width: width,
  };
};

export async function generateSocialMediaPost(slug: string, media: any[]) {
  try {
    const url = `/api/generate-social-post`;
    const response = await api.post(url, {
      slug,
      media,
    });

    if (response.data.message === "error") {
      return "";
    }
    return response.data.message;
  } catch (e) {
    return "";
  }
}

export async function getPhotoDescriptions(slug: string, photos: any[]) {
  try {
    const url = `/api/get-photo-descriptions`;
    const response = await api.post(url, {
      slug,
      photos,
    });

    if (response.data.message === "error") {
      return "";
    }
    return response.data.message;
  } catch (e) {
    return "";
  }
}

export function findFbGooglePosts(scheduledPosts: any[]) {
  const scheduledPostsNoDupes: any = [];
  const fbPostsHash = {} as any;
  const googlePostsHash = {} as any;
  const igPostsHash = {} as any;
  if (scheduledPosts && scheduledPosts.length) {
    scheduledPosts?.forEach((post) => {
      if (post.origin.facebook) {
        fbPostsHash[post.message] = post.created_time;
      } else if (post.origin.google) {
        googlePostsHash[post.message] = post.created_time;
      } else if (post.origin.instagram) {
        igPostsHash[post.message] = post.created_time;
      }
    });

    scheduledPosts.forEach((post) => {
      if (
        !scheduledPostsNoDupes.some(
          (p) =>
            p.message === post.message && p.created_time == post.created_time
        )
      ) {
        const dupePosts = scheduledPosts.filter(
          (p) =>
            p.message === post.message && p.created_time === post.created_time
        );
        const fbPost = dupePosts.find((post) => post.origin.facebook === true);
        const googlePost = dupePosts.find(
          (post) => post.origin.google === true
        );
        const igPost = dupePosts.find((post) => post.origin.instagram === true);

        const idArray = [dupePosts[0]?.id, dupePosts[1]?.id, dupePosts[2]?.id];

        const finalPost = {
          ...post,
          origin: {
            facebook: fbPost?.id,
            google: googlePost?.id,
            instagram: igPost?.id,
          },
          id: idArray,
        };

        scheduledPostsNoDupes.push(finalPost);
      }
    });

    return scheduledPostsNoDupes;
  }
}

export const getRewritePost = async (slug: string, postMessage: string) => {
  const response = await api.post(`/api/rewritePost`, {
    slug: slug,
    message: postMessage,
  });
  return response.data;
};

export const getFormattedDateString = (dateString: string) => {
  return moment(dateString).startOf("minute").format("MMMM D, YYYY h:mm A");
};

export function formatErrorSlack(error: any) {
  const stringifiedError = JSON.stringify(error);
  const first750Chars = stringifiedError.slice(0, 750);
  const formattedError = first750Chars + "...";

  return formattedError;
}

export async function getS3File(file: File) {
  const signedRequest = await sendPhotoToS3(file);
  return signedRequest;
}

export async function sendPhotoToS3(file: File) {
  try {
    const fileType = file.type.split("/")[1];
    const fileName = `${uuidv4()}.${fileType}`;
    const S3_BUCKET = "landing-page-app-hero-images";

    const s3Client = new S3Client({
      region: "us-east-2",
      credentials: {
        accessKeyId: process.env.NEXT_PUBLIC_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.NEXT_PUBLIC_S3_SECRET_ACCESS_KEY,
      },
    });

    const s3Params = {
      Bucket: S3_BUCKET,
      Key: `media/${fileName}`,
      ContentType: file.type,
      ACL: "public-read",
      CacheControl: "max-age=31536000",
    };

    const signedUrl = await getSignedUrl(
      s3Client,
      new PutObjectCommand(s3Params),
      { expiresIn: 600 }
    );

    await uploadFile(file, signedUrl);

    return fileName;
  } catch (e) {
    console.log("Upload failed", e);
  }
}

async function uploadFile(file, signedRequest) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedRequest);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(file.name);
        } else {
          console.log("error uploading file - handled by inline alert message");
        }
      }
    };
    xhr.send(file);
  });
}

export async function deletePhotos(photos: any[]) {
  const imagesToDelete = photos.map((photo) => photo.id);
  await deleteImages(imagesToDelete);
}

export async function editPhotoDb(photo: any) {
  try {
    const photo_id = photo.id;
    const url = photo.signedRequest;

    const response = await api.post(`/api/edit-photo`, {
      photo_id,
      url,
    });
    const editedPhoto = response.data.message;

    return editedPhoto;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export function updatePhotosState(newPhotos: any[], allPhotos: any) {
  const galleryName = newPhotos[0]?.gallery_name;
  const newAllPhotos = { ...allPhotos };

  newAllPhotos["All Photos"] = newAllPhotos["All Photos"].concat(newPhotos);

  if (galleryName) {
    newAllPhotos[galleryName] = newAllPhotos[galleryName].concat(newPhotos);
    newAllPhotos[galleryName].sort((a, b) => b.gallery_order - a.gallery_order);
  }
  newAllPhotos["All Photos"].sort(
    (a, b) => b.all_photos_order - a.all_photos_order
  );

  return newAllPhotos;
}

export function updatePhotosStateOnEdit(editedPhoto: any, allPhotos: any) {
  const galleryName = editedPhoto?.gallery_name || "All Photos";
  const newAllPhotos = { ...allPhotos };

  const existingIndexAll = newAllPhotos["All Photos"].findIndex(
    (photo) => photo.id === editedPhoto.id
  );
  newAllPhotos["All Photos"][existingIndexAll] = editedPhoto;

  const existingIndexGallery = newAllPhotos[galleryName].findIndex(
    (photo) => photo.id === editedPhoto.id
  );
  newAllPhotos[galleryName][existingIndexGallery] = editedPhoto;

  newAllPhotos[galleryName].sort((a, b) => b.gallery_order - a.gallery_order);
  newAllPhotos["All Photos"].sort(
    (a, b) => b.all_photos_order - a.all_photos_order
  );

  return newAllPhotos;
}

export function generateSubtext(photosCount: number, videosCount: number) {
  let subtext;
  if (photosCount && videosCount) {
    subtext = `${photosCount} photo${
      photosCount > 1 ? "s" : ""
    }, ${videosCount} video${videosCount > 1 ? "s" : ""}`;
  } else if (!photosCount && videosCount) {
    subtext = `${videosCount} video${videosCount > 1 ? "s" : ""}`;
  } else if (photosCount && !videosCount) {
    subtext = `${photosCount} photo${photosCount > 1 ? "s" : ""}`;
  } else {
    subtext = "0 photos";
  }
  return subtext;
}

async function getSignedRequest(photo: any) {
  let signedRequest;

  if (!photo?.photo) {
    const file = await createFile(photo.data);
    signedRequest = await getS3File(file);
  } else {
    const photoUrl = photo.photo;
    signedRequest = getUuid(photoUrl);
  }

  return signedRequest;
}

export async function updateCoverPhotos(sitePk: any, coverPhotos: any[]) {
  try {
    const s3Array = [];

    for (let i = 0; i < coverPhotos.length; i++) {
      const photo = coverPhotos[i];
      const signedRequest = await getSignedRequest(photo);
      s3Array.push(signedRequest);
    }

    await api.post(`/api/update-cover-photos`, {
      pk: sitePk,
      photos: s3Array,
    });
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function updateHomepagePhotos(homepagePhotos: any[]) {
  try {
    await api.post(`/api/update-homepage-photos`, {
      homepage_photos: homepagePhotos,
    });
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function updateLogo(photo: any) {
  try {
    const signedRequest = await getSignedRequest(photo);

    await api.post(`/api/update-logo`, {
      logo: signedRequest,
    });
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function updateAboutUs(photo: any) {
  try {
    const signedRequest = await getSignedRequest(photo);

    await api.post(`/api/update-about-us`, {
      about_upload: signedRequest,
    });
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export function getMediaId(url: string) {
  if (url) {
    const urlSplit = url.split("/");
    const mediaId = urlSplit[urlSplit.length - 1];
    return mediaId;
  }
  return "";
}

export async function updateIsGoodForPost(
  photoId: number,
  isGoodForPost: boolean
) {
  try {
    const response = await api.patch(`/api/gallery-photos/${photoId}`, {
      is_good_for_post: isGoodForPost,
    });
    return response.data.status;
  } catch (e) {
    console.log("error:", e);
    return "error";
  }
}

export function isProPlan(profileInfo: ProfileInfo) {
  const plan = profileInfo?.current_plan?.type;
  const subscriptionStatus = profileInfo?.subscription_status;

  if (plan !== "pro" || subscriptionStatus !== "active") {
    return false;
  }
  return true;
}

export function isTestPrice(profileInfo: ProfileInfo) {
  return profileInfo?.is_test_price;
}

export async function createInstagramPost(caption: string, media: any[]) {
  const instagramPostData = {
    caption,
    media,
  };

  try {
    const response = await api.post(
      `/api/post_to_instagram`,
      instagramPostData
    );

    return response?.data?.message;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}
