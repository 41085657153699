import { signOut, useSession } from "next-auth/react";
import router from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getUserId } from "../../hooks/getAuthUserId";
import getUserStripeInfo, {
  getStripeInformation,
} from "../../hooks/getUserStripeInfo/getUserStripeInfo";
import {
  checkGoogleAuth,
  checkUpdateGmbVerificationStatus,
} from "../../components/primaryrender/connections/ConnectionsTab/utils";
import { ContactProps } from "../../components/primaryrender/contacts/Contact/Contact";
import { AppContext } from "../AppContextProvider/AppContextProvider";
import CONSTANTS from "../constants";
import {
  BasicInfoProps,
  DesktopContextType,
  GalleryPhoto,
  InitialApiLoadingState,
  ProfileInfo,
  Review,
} from "../types";
import {
  fetchAllSeoInfo,
  fetchGBPSeoInfo,
  fetchImage,
  fetchWordsAndPageSeoInfo,
  getAllDigitalMedia,
  getDigitalMediaDefaults,
  getBasicInfo,
  getBookingRequests,
  getTeammates,
  getTopThreeCompetitors,
  getUserCampaignInfo,
  incrementLoginCount,
  getPhysicalMarketingDefaults,
  clearAllCookies,
} from "../utils";
import { URL_CONSTANTS } from "../../constants/urlConstants";
import useSWR from "swr";
import {
  getAllInvoices,
  getAllPayouts,
  getAllQuotes,
  getInvoicesFromDb,
  getQuotesFromDb,
  getStripeAccountInfo,
} from "../../components/primaryrender/payments/utils";
import {
  fetchBlogs,
  fetchScheduledBlogs,
} from "../../components/primaryrender/editor/blogs/BlogsPage/utils";
import {
  getFacebookPosts,
  getFacebookProfileCoverPhotos,
  getGmbPhotos,
  getGmbPosts,
  getInstagramPosts,
  getNewFbPhotos,
  getNewGmbPhotos,
  getNewIgPhotos,
  getScheduledPosts,
  twoArraysEqual,
} from "../../components/primaryrender/editor/utils";
import getUserGalleries, {
  GetUserGalleriesResponse,
} from "../../hooks/getUserGalleries";
import { getNotiPreferencesFromCourier } from "../../components/primaryrender/notifications/NotificationsPage/utils";
import {
  getFacebookPageFollowerCount,
  getFacebookPageTotalLikes,
  getGoogleAnalytics,
  getTasksByLandingPage,
} from "../../components/primaryrender/dashboard/DashboardPage/utils";
import {
  getAllReviewRequests,
  getAllReviews,
  getNewFbReviews,
  getNewGmbReviews,
  getShowAutoReplyToReviewBannerFunc,
} from "../../components/primaryrender/reviews/ReviewContainer/utils";
import { detectIOS } from "../../helpers/promptUser";
import { getUserReferrals } from "../../components/primaryrender/refer/utils";
import { IReferral } from "../../components/primaryrender/refer/types";
import useContactInfo from "../../hooks/useContactInfo/useContactInfo";
import getCronTaskResult from "../../components/common/utils";
import { useTestimonials } from "../../hooks/useTestimonalsInfo/useTestimonalsInfo";
import { toast } from "react-toastify";
import useProsAllInvoices from "../../hooks/useProsAllInvoices/useProsAllInvoices";
import { Session } from "next-auth";
import {
  getFeatureLimits,
  getFeatureUsage,
} from "../../hooks/useFeatureGating/utils";
import useUserCompleteCampaignInfo from "../../hooks/useUserCompleteCampaignInfo/useUserCompleteCampaignInfo";
import {
  checkEligibleForSummaryStats,
  getYearInReviewVideo,
} from "../../components/primaryrender/marketing/DigitalMedia/YearInReview/utils";
import { IContact } from "../../components/primaryrender/contacts/Contact/types";
import { checkIfAlreadySentRequestReviewWithin6Months } from "../../components/primaryrender/contacts/ContactsPage/utils";
import {
  GOOD,
  NEUTRAL,
  POOR,
} from "../../components/primaryrender/editor/SEOPage/constants";
import getUserInfo from "../../hooks/utils";
import { api } from "../../helpers/topline-api";

export const DesktopContext = createContext({} as DesktopContextType);

export const useSwrStopRevalidation = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

import { SelectedSiteContext } from "../SelectedSiteContextProvider/SelectedSiteContextProvider";
import { getAllProjectShowcases } from "../../components/ProjectShowcases/utils";
import { appStoreContext } from "../StoreContextProvider/StoreContextProvider";
import { getCalendarEvents } from "../../components/primaryrender/calendar/utils";

function loginWithRedirect() {
  if (router.asPath === "/_functional/initiate-pro-plan-trial") {
    router.push("/login?from_pro_plan_trial=true");
  }
  if (!router.asPath.includes("redirect=")) {
    router.push("/login");
    return;
  }
  const redirectUrl = router.asPath;
  router.push(`/login?redirect=${redirectUrl}`);
}

export const DesktopContextProvider: React.FC = ({ children }) => {
  const [isLoginVerified, setIsLoginVerified] = useState(false);
  const [isSessionVerified, setIsSessionVerified] = useState(false);

  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated() {
      localStorage.clear();
      sessionStorage.clear();
      clearAllCookies();
      signOut({ redirect: false });
      loginWithRedirect();
    },
  });

  useEffect(() => {
    if (status === "authenticated") {
      setTimeout(() => {
        setIsLoginVerified(true);
      }, 750);
    }
  }, [status]);

  useEffect(() => {
    if (hasRefreshError(session)) {
      localStorage.clear();
      sessionStorage.clear();
      signOut({ redirect: false });
      loginWithRedirect();
    } else {
      setIsSessionVerified(true);
    }
  }, [session]);

  function hasRefreshError(session: Session | null) {
    return (
      !!session &&
      !!session.error &&
      session.error === "RefreshAccessTokenError"
    );
  }

  const [desktopMenuOpen, setDesktopMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [profileInfo, setProfileInfo] = useState<ProfileInfo>();
  const [
    downloadAppOnIphoneWithNoHomeButton,
    setDownloadAppOnIphoneWithNoHomeButton,
  ] = useState(false);
  const [reviewInfo, setReviewInfo] = useState<Array<Review>>();
  const [user, setUser] = useState(false);
  const [bookingRequests, setBookingRequests] = useState();
  const [showQRReviewDrawer, setShowQRReviewDrawer] = useState(false);
  const [incomingCalls, setIncomingCalls] = useState();
  const [isGmbAuthenticated, setIsGmbAuthenticated] = useState(false);
  const [gmbEmail, setGmbEmail] = useState("");
  const [facebookPageName, setFacebookPageName] = useState("");
  const [gmbVerificationStatus, setGmbVerificationStatus] =
    useState<string>("");
  const [doesUseQuickbooks, setDoesUseQuickbooks] = useState("");
  const [isFacebookAuthenticated, setIsFacebookAuthenticated] = useState(false);

  const [isInstagramAuthenticated, setIsInstagramAuthenticated] =
    useState(false);
  const [isGmbAuthenticationLoading, setIsGmbAuthenticationLoading] =
    useState(false);
  const [isQuickbooksAuthenticated, setIsQuickbooksAuthenticated] =
    useState(false);
  const [basicInfo, setBasicInfo] = useState<BasicInfoProps>();
  const [teammates, setTeammates] = useState<any[]>(); // TODO: type this
  const [campaigns, setCampaigns] = useState<any[]>(); // TODO: type this
  const [contacts, setContacts] = useState<ContactProps[]>();
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [paymentFailureError, setPaymentFailureError] = useState(false);
  const [photoUploadedSuccessfully, setPhotoUploadedSuccessfully] =
    useState(null);
  const [facebookUserToken, setFacebookUserToken] = useState("");
  const [facebookPageToken, setFacebookPageToken] = useState("");
  const [facebookPageId, setFacebookPageId] = useState("");
  const [gmbAccessToken, setGmbAccessToken] = useState("");
  const [gmbRefreshToken, setGmbRefreshToken] = useState("");
  const [gmbAccountId, setGmbAccountId] = useState("");
  const [gmbLocationId, setGmbLocationId] = useState("");
  const [landingPageCreationDate, setLandingPageCreationDate] = useState("");
  const [, setStripeInfoFetched] = useState(false);
  const [initialApiLoadingState, setInitialApiLoadingState] =
    useState<InitialApiLoadingState>(CONSTANTS.INITIAL_API_LOADING_STATE);
  const [paymentsFlagLocalStorage, setPaymentsFlagLocalStorage] =
    useState<string>("false");
  const [campaignsFlagLocalStorage, setCampaignsFlagLocalStorage] =
    useState<string>("true");
  const [userStripeAccount, setUserStripeAccount] = useState();
  const [allInvoicesForPro, setAllInvoicesForPro] = useState();
  const [allUserStripeInvoices, setAllUserStripeInvoices] = useState();
  const [userPersonalBlogs, setUserPersonalBlogs] = useState();
  const [userScheduleBlogs, setUserScheduleBlogs] = useState();
  const [userFacebookPosts, setUserFacebookPosts] = useState([]);
  const [userFacebookGroups, setUserFacebookGroups] = useState([]);
  const [userInstagramPosts, setUserInstagramPosts] = useState([]);
  const [userGMBPosts, setUserGMBPosts] = useState([]);
  const [userSchedulePosts, setUserSchedulePosts] = useState();
  const [userGalleries, setUserGalleries] =
    useState<GetUserGalleriesResponse>();
  const [userAllQuotes, setUserAllQuotes] = useState();
  const [allPayouts, setAllPayouts] = useState();
  const [userInvoicesInDB, setUserInvoicesInDB] = useState();
  const [userQuotesInDB, setUserQuotesInDB] = useState();
  const [allTasksResponse, setAllTasksResponse] = useState();
  const [facebookSocialPhotos, setFacebookSocialPhotos] = useState();
  const [googleSocialPhotos, setGoogleSocialPhotos] = useState();
  const [userFacebookFollowerCount, setUserFacebookFollowerCount] = useState();
  const [userFacebookLikes, setUserFacebookLikes] = useState();
  const [analytics, setAnalytics] = useState();
  const [googleSearchKeyWords, setGoogleSearchKeyWords] = useState<
    Map<string, number>
  >(new Map());
  const [gscImpressions, setGscImpressions] = useState<Map<Date, number>>(
    new Map()
  ); //Impressions data from Google Search Console
  const [googleImpressionsCount, setGoogleImpressionsCount] =
    useState<number>();
  const [numberOfWeeks, setNumberOfWeeks] = useState<number>();
  const [newFbReviews, setNewFbReviews] = useState();
  const [newGmbReviews, setNewGmbReviews] = useState();
  const [userPhoto, setUserPhoto] = useState<GalleryPhoto[]>();
  const [newFbPhotos, setNewFbPhotos] = useState(); // TODO: check the usage of this
  const [newGmbPhotos, setNewGmbPhotos] = useState(); // TODO: check the usage of this
  const [newIgPhotos, setNewIgPhotos] = useState(); // TODO: check the usage of this
  const [requestsCount, setRequestCount] = useState<any>();
  const [allRequests, setAllRequests] = useState();
  const [reviewRequestModalOpen, setReviewRequestModalOpen] = useState(false);
  const [replyModalOpen, setReplyModalOpen] = useState(false);
  const [showAutoReplyToReviewBanner, setShowAutoReplyToReviewBanner] =
    useState(false);
  const currentWindow = typeof window !== "undefined" ? window : null;
  const [competitors, setCompetitors] = useState();
  const [seoData, setSeoData] = useState();
  const [proHasChurned, setProHasChurned] = useState(false);
  const [userReferrals, setUserReferrals] = useState<IReferral[]>([]);
  const [showQRModal, setShowQRModal] = useState<boolean>(false);
  const [allDigitalMedia, setAllDigitalMedia] = useState<string>();
  const [digitalMediaDefaults, setDigitalMediaDefaults] = useState<string>();
  const [physicalMarketingDefaults, setPhysicalMarketingDefaults] =
    useState<string>();
  const [googleContactsAuthenticated, setGoogleContactsAuthenticated] =
    useState(false);
  const [googleContactsLoading, setGoogleContactsLoading] =
    useState<boolean>(false);
  const [showContactsTooltip, setShowContactsTooltip] = useState(false);
  const [showReviewsTooltip, setShowReviewsTooltip] = useState(false);
  const [savedPost, setSavedPost] = useState<any>();
  const [socialConnectionType, setSocialConnectionType] = useState<string>("");
  const [featureUsage, setFeatureUsage] = useState<any>();
  const [featureLimits, setFeatureLimits] = useState<any>();
  const [socialContentError, setSocialContentError] = useState<string | null>(
    null
  );
  const [attachedPhotos, setAttachedPhotos] = useState<any[]>([]);
  const [isDigitalAssetAttached, setIsDigitalAssetAttached] = useState(false);
  const [yearInReviewVideo, setYearInReviewVideo] = useState<any>();
  const [eligibleForMetrics, setEligibleForMetrics] = useState<any>();
  const [selectedContacts, setSelectedContacts] = useState<IContact[]>([]);
  const [onSiteSEOPillStatus, setOnSiteSEOPillStatus] = useState(GOOD);
  const [gmbPillStatus, setGmbPillStatus] = useState(GOOD);
  const [reviewsPillStatus, setReviewsPillStatus] = useState(NEUTRAL);
  const [postFreqPillStatus, setPostFreqPillStatus] = useState(POOR);
  const [chatbotOpen, setChatbotOpen] = useState(false);
  const [showChatbotTooltip, setShowChatbotTooltip] = useState(false);
  const [showChatbotTooltip2, setShowChatbotTooltip2] = useState(false);
  const [hasBookingNotifications, setHasBookingNotifications] = useState(false);
  const [isPostHistoryLoading, setIsPostHistoryLoading] = useState(false);
  const [allProjectShowcases, setAllProjectShowcases] = useState();
  const [calendarEvents, setCalendarEvents] = useState();
  const { setGoogleClientId } = useContext(appStoreContext);

  const [hasIncremented, setHasIncremented] = useState(false);

  useEffect(() => {
    // We only want to incement once per load - but not til the profileInfo has loaded
    if (hasIncremented || !profileInfo) return;
    incrementLoginCount();
    setHasIncremented(true);
  }, [profileInfo, hasIncremented]);

  useEffect(() => {
    async function fetchShowingTooltipInfo() {
      try {
        const response = await api.get(`/api/get-show-tooltip-info`);
        if (response?.data?.message) setShowContactsTooltip(true);
      } catch (e) {
        console.log(e);
      }
    }
    fetchShowingTooltipInfo();
  }, []);

  // All users in this context should be paid (have stripe_customer_id) if they have a site id
  // We allow churned users into the site (see proHasChurned variable) so this does not check the _status_ of the subscription
  useEffect(() => {
    // Profile could be loading
    if (!profileInfo) return;

    const { stripe_customer_id, landing_pages } = profileInfo;
    if (landing_pages.length && !stripe_customer_id) {
      router.push("/signout");
    }
  }, [profileInfo]);

  const paymentsFlag =
    currentWindow?.localStorage.getItem("payments_flag") || "false";
  const campaignsFlag =
    currentWindow?.localStorage.getItem("campaigns_flag") || "false";

  useEffect(() => {
    setPaymentsFlagLocalStorage(paymentsFlag);
    setCampaignsFlagLocalStorage(campaignsFlag);
  }, []);

  useEffect(() => {
    const iOS = detectIOS();
    const isMobile = window.innerWidth < 600;
    const noHomeButton = window.innerHeight > 667;
    const isPWADetected = localStorage.getItem("detectPWA");

    if (isMobile && iOS && noHomeButton && isPWADetected === "true") {
      setDownloadAppOnIphoneWithNoHomeButton(true);
    }
  }, []);

  useEffect(() => {
    if (
      profileInfo?.stripe_customer_id &&
      profileInfo?.subscription_status === "canceled"
    ) {
      setProHasChurned(true);
    } else {
      setProHasChurned(false);
    }
  }, [profileInfo]);

  const {
    selectedFrame,
    handleSelectedFrame,
    bottomLeftNavigation,
    setHelpModal,
    setAppProfileInfo,
  } = useContext(AppContext);

  const { selectedSiteId } = useContext(SelectedSiteContext);

  const {
    data: userInfo,
    isLoading: userInfoLoading,
    mutate: mutateProfileInfo,
  } = useSWR(
    [URL_CONSTANTS.PROSITE_GET_USER, selectedSiteId],
    async () => {
      try {
        return await getUserStripeInfo(selectedSiteId);
      } catch (e) {
        console.error(e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: seoInfo, isLoading: seoInfoLoading } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-page-speed-seo/${userInfo?.slug}`,
      userInfo?.slug,
    ],
    async () => {
      try {
        if (userInfo?.slug) {
          return (await fetchAllSeoInfo(userInfo?.slug)) || [];
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: gbpSeoInfo, isLoading: gbpSeoLoading } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-gbp-seo-info/${userInfo?.slug}`,
      userInfo?.slug,
    ],
    async () => {
      try {
        if (userInfo?.slug) {
          return (await fetchGBPSeoInfo(userInfo?.slug)) || [];
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: wordsAndPageSeoInfo, isLoading: wordsAndPageSeoLoading } =
    useSWR(
      [
        `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-words-and-page-seo-info/${userInfo?.slug}`,
        userInfo?.slug,
      ],
      async () => {
        try {
          if (userInfo?.slug) {
            return (await fetchWordsAndPageSeoInfo(userInfo?.slug)) || [];
          }
        } catch (e) {
          console.log("error", e);
        }
      },
      useSwrStopRevalidation
    );

  const { data: userCampaignInfo, isLoading: userCampaignInfoLoading } = useSWR(
    [URL_CONSTANTS.PROSITE_GET_USER_CAMPAIGN_INFO, profileInfo?.slug],
    async () => {
      try {
        if (profileInfo?.slug) {
          const res = await getUserCampaignInfo();
          return res?.message;
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: competitorsInfo, isLoading: competitorsLoading } = useSWR(
    `${URL_CONSTANTS.PROSITE_GET_COMPETITORS}/${userInfo?.slug}`,
    async () => {
      try {
        if (userInfo?.slug) {
          const competitorsInformation = await getTopThreeCompetitors(
            userInfo?.slug
          );
          return competitorsInformation;
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: userReferralsInfo, isLoading: userReferralsLoading } = useSWR(
    [URL_CONSTANTS.PROSITE_GET_CUSTOMER_REFERRALS, profileInfo?.slug],
    async () => {
      try {
        if (profileInfo?.slug) {
          const referrals = await getUserReferrals();
          return referrals;
        }
      } catch (e) {
        console.log(e);
      }
    },
    useSwrStopRevalidation
  );

  const { userCompleteCampaignInfo, userCompleteCampaignInfoLoading } =
    useUserCompleteCampaignInfo(profileInfo, userCampaignInfo);

  const {
    data: basic_landing_page_info,
    isLoading: basic_landing_page_infoLoading,
    mutate: mutateBasicInfo,
  } = useSWR(
    [
      `${URL_CONSTANTS.PROSITE_GET_LANDING_PAGE}/${profileInfo?.slug}`,
      profileInfo?.slug,
    ],
    async () => {
      try {
        if (profileInfo?.slug) {
          const basicInfo = await getBasicInfo(profileInfo?.slug);
          return basicInfo;
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: userInvoiceInDbInfo, isLoading: userInvoiceInDbInfoLoading } =
    useSWR(
      [
        `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-invoices-from-db`,
        profileInfo?.slug,
      ],
      async () => {
        try {
          if (profileInfo?.slug) {
            return await getInvoicesFromDb();
          }
        } catch (e) {
          console.log(e);
        }
      },
      useSwrStopRevalidation
    );

  const { data: userQuotesInDbInfo, isLoading: userQuotesInDbInfoLoading } =
    useSWR(
      [
        `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-quotes-from-db`,
        profileInfo?.slug,
      ],
      async () => {
        try {
          if (profileInfo?.slug) {
            return await getQuotesFromDb();
          }
        } catch (e) {
          console.log(e);
        }
      },
      useSwrStopRevalidation
    );

  const {
    data: stripeInfo,
    isLoading: stripeInfoLoading,
    mutate: mutateStripeInfo,
  } = useSWR(
    () => userInfo?.stripe_customer_id,
    async (customerId) => {
      try {
        if (customerId) {
          return await getStripeInformation(customerId);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: teammatesInfo } = useSWR(
    [
      `${URL_CONSTANTS.PROSITE_BASE}/api/get-teammates/${userInfo?.customer_pk}`,
      userInfo?.customer_pk,
      profileInfo?.slug,
    ],
    async () => {
      try {
        if (userInfo?.customer_pk && profileInfo?.slug) {
          return await getTeammates(userInfo?.customer_pk);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { testimonialInfo, mutateTestimonialInfo } =
    useTestimonials(profileInfo);

  const { data: newFbReviewInfo } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get_new_fb_reviews_by_landing_page`,
      basicInfo?.sitePk,
    ],
    async () => {
      try {
        if (basicInfo?.sitePk && facebookPageToken && isFacebookAuthenticated) {
          return await getNewFbReviews(basicInfo?.sitePk);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: newGmbReviewInfo } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get_new_gmb_reviews_by_landing_page`,
      basicInfo?.sitePk,
    ],
    async () => {
      try {
        if (
          basicInfo?.sitePk &&
          isGmbAuthenticated &&
          gmbVerificationStatus === "verified"
        ) {
          return await getNewGmbReviews(basicInfo?.sitePk);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  async function fetchPhotos() {
    const response = await api.get(`/api/get_photos`);
    return response.data;
  }

  async function generateGroupPost() {
    try {
      const response = await api.get(`/api/generate-facebook-group-post`);
      return await response;
    } catch (e) {
      console.error("error when generating facebook group post:", e);
    }
  }

  async function fetchWebsiteViews(slug: string) {
    try {
      const response = await api.get(`/api/get-landing-page-views/${slug}`);
      return await response.data;
    } catch (e) {
      console.error("error when getting website views:", e);
    }
  }

  const { data: userPhotoInfo, mutate: mutatePhotos } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get_photos`,
      profileInfo?.slug,
    ],
    async () => {
      try {
        if (profileInfo?.slug) {
          return await fetchPhotos();
        }
      } catch (e) {
        console.log("error:", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: newFbPhotosInfo } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-new-fb-photos-by-page/${basicInfo?.sitePk}`,
      isFacebookAuthenticated,
      facebookPageToken,
    ],
    async () => {
      try {
        if (basicInfo?.sitePk && isFacebookAuthenticated && facebookPageToken) {
          return await getNewFbPhotos(basicInfo?.sitePk);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: newIgPhotosInfo } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-new-ig-photos-by-page/${basicInfo?.sitePk}`,
      isInstagramAuthenticated,
    ],
    async () => {
      try {
        if (basicInfo?.sitePk && isInstagramAuthenticated) {
          return await getNewIgPhotos(basicInfo?.sitePk);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: newGmbPhotosInfo } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-new-gmb-photos-by-page/${basicInfo?.sitePk}`,
      gmbVerificationStatus,
    ],
    async () => {
      try {
        if (
          basicInfo?.sitePk &&
          isGmbAuthenticated &&
          gmbVerificationStatus === "verified"
        ) {
          return await getNewGmbPhotos(basicInfo?.sitePk);
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: bookingRequestsInfo, mutate: mutateBookingInfo } = useSWR(
    [
      `${URL_CONSTANTS.PROSITE_GET_BOOKINGS_BY_LANDING_PAGE}/${userInfo?.slug}`,
      userInfo?.slug,
    ],
    async () => {
      try {
        if (userInfo?.slug) {
          return await getBookingRequests();
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    {
      ...useSwrStopRevalidation,
      refreshInterval:
        Number(process.env.NEXT_PUBLIC_BOOKINGS_REFRESH_INTERVAL_MS) || 30000,
    }
  );

  const { contactInfo } = useContactInfo(profileInfo);

  const { data: auth0_id } = useSWR("getUserId", async () => {
    try {
      return await getUserId();
    } catch (e) {
      console.log("error", e);
    }
  });

  const { data: userSocialInfo, mutate: mutateSocialInfo } = useSWR(
    [auth0_id ? `getUserById/${auth0_id}` : null, selectedSiteId],
    () => getUserInfo(selectedSiteId),
    useSwrStopRevalidation
  );

  const {
    data: userStripeAccountInfo,
    isLoading: userStripeAccountInfoLoading,
  } = useSWR(
    `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-stripe-connect-account-info`,
    async () => {
      try {
        return await getStripeAccountInfo();
      } catch (e) {
        console.log("error", e);
      }
    },
    useSwrStopRevalidation
  );

  const {
    data: userStripeAccountInvoicesInfo,
    isLoading: userStripeAccountInvoicesInfoLoading,
    mutate: mutateStripeConnectInvoices,
  } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-all-invoices/${profileInfo?.stripe_connect_account_id}`,
      profileInfo?.stripe_connect_account_id,
    ],
    async () => {
      try {
        if (profileInfo?.stripe_connect_account_id) {
          const result = await getAllInvoices(
            profileInfo?.stripe_connect_account_id
          );
          return result;
        }
      } catch (e) {
        console.log(e);
      }
    },
    useSwrStopRevalidation
  );

  const { allProsInvoices, allProsInvoicesLoading } =
    useProsAllInvoices(profileInfo);

  const { data: usePersonalBlogInfo, isLoading: usePersonalBlogInfoLoading } =
    useSWR(
      [
        `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/fetch-blogs`,
        profileInfo?.slug,
      ],
      async () => {
        try {
          if (profileInfo?.slug) {
            return await fetchBlogs(profileInfo?.slug);
          }
          return [];
        } catch (e) {
          console.log(e);
        }
      },
      useSwrStopRevalidation
    );
  const { data: useScheduleBlogInfo, isLoading: useScheduleBlogInfoLoading } =
    useSWR(
      [
        `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-scheduled-blogs/${profileInfo?.slug}`,
        profileInfo?.slug,
      ],
      async () => {
        try {
          if (profileInfo?.slug) {
            return await fetchScheduledBlogs(profileInfo?.slug);
          }
        } catch (e) {
          console.log(e);
        }
      },
      useSwrStopRevalidation
    );

  const {
    data: userFacebookPostsInfo,
    isLoading: userFacebookPostsInfoLoading,
    mutate: mutateFacebookPosts,
  } = useSWR(
    [
      `https://graph.facebook.com/v14.0/${facebookPageId}/posts?limit=100&fields=full_picture%2Cmessage%2Ccreated_time%2Cattachments&access_token=${facebookPageToken}`,
      facebookPageId,
      facebookPageToken,
    ],
    async () => {
      try {
        if (facebookPageId && facebookPageToken) {
          return await getFacebookPosts(facebookPageId, facebookPageToken);
        }
      } catch (e) {
        console.log(e);
      }
    },
    useSwrStopRevalidation
  );

  const {
    data: instagramPostsInfo,
    isLoading: instagramPostsLoading,
    mutate: mutateInstagramPosts,
  } = useSWR(
    [
      `${URL_CONSTANTS.PROSITE_BASE}/api/instagram-connect`,
      isInstagramAuthenticated,
    ],
    async () => {
      try {
        if (isInstagramAuthenticated) {
          return await getInstagramPosts();
        }
      } catch (e) {
        console.log(e);
      }
    },
    useSwrStopRevalidation
  );

  const {
    data: userGMBPostsInfo,
    isLoading: userGMBPostsInfoLoading,
    mutate: mutateGooglePosts,
  } = useSWR(
    [
      `https://mybusiness.googleapis.com/v4/localPosts`,
      profileInfo?.slug,
      isGmbAuthenticated,
      gmbLocationId,
      gmbAccountId,
      gmbVerificationStatus,
    ],
    () => {
      if (
        gmbAccessToken &&
        gmbAccountId &&
        gmbLocationId &&
        isGmbAuthenticated
      ) {
        return getGmbPosts(gmbAccessToken, gmbAccountId, gmbLocationId);
      }
    },
    useSwrStopRevalidation
  );

  useEffect(() => {
    setIsPostHistoryLoading(
      instagramPostsLoading ||
        userFacebookPostsInfoLoading ||
        userGMBPostsInfoLoading
    );
  }, [
    instagramPostsLoading,
    userFacebookPostsInfoLoading,
    userGMBPostsInfoLoading,
  ]);

  const {
    data: userFacebookFollowerCountInfo,
    isLoading: userFacebookFollowerCountInfoLoading,
  } = useSWR(
    [
      `https://graph.facebook.com/v14.0/${facebookPageId}?fields=access_token%2Cfollowers_count%2Cposts%7Bmessage%2Cstory%2Cfull_picture%2Ccreated_time%7D&access_token=${facebookPageToken}`,
      facebookPageId,
      facebookPageToken,
      isFacebookAuthenticated,
    ],
    async () => {
      try {
        if (isFacebookAuthenticated && facebookPageId && facebookPageToken) {
          return await getFacebookPageFollowerCount(
            facebookPageId,
            facebookPageToken
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    useSwrStopRevalidation
  );

  // userFacebookPosts set using getFacebookPosts and useSWR pattern
  const {
    data: userFacebookLikesInfo,
    isLoading: userFacebookLikesInfoLoading,
  } = useSWR(
    [
      `https://graph.facebook.com/v14.0/${facebookPageId}/feed?fields=likes.limit(1).summary(true)&access_token=${facebookPageToken}`,
      facebookPageId,
      facebookPageToken,
      isFacebookAuthenticated,
    ],
    async () => {
      try {
        if (isFacebookAuthenticated && facebookPageId && facebookPageToken) {
          return await getFacebookPageTotalLikes(
            facebookPageId,
            facebookPageToken
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    useSwrStopRevalidation
  );

  const {
    data: userScheduledPostsInfo,
    isLoading: userScheduledPostsInfoLoading,
    mutate: mutateScheduledPosts,
  } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-scheduled-posts/${profileInfo?.slug}`,
      profileInfo?.slug,
    ],
    async () => {
      try {
        if (profileInfo?.slug) {
          return await getScheduledPosts(profileInfo?.slug);
        }
      } catch (e) {
        console.log(e);
      }
    },
    useSwrStopRevalidation
  );

  const {
    data: userGalleriesInfo,
    isLoading: userGalleriesInfoLoading,
    mutate: mutateGalleries,
  } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/getGalleries`,
      profileInfo?.slug,
    ],
    async () => {
      try {
        if (profileInfo?.slug) {
          return await getUserGalleries();
        }
      } catch (e) {
        console.log(e);
      }
    },
    useSwrStopRevalidation
  );

  const {
    data: notiPreferencesFromCourierData,
    isLoading: notiPreferencesFromCourierLoading,
  } = useSWR(
    `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get_noti_preferences_from_courier`,
    async () => {
      try {
        return await getNotiPreferencesFromCourier();
      } catch (e) {
        console.log(e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: userAllQuotesInfo, isLoading: userAllQuotesInfoLoading } =
    useSWR(
      [
        `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-all-quotes/${profileInfo?.stripe_connect_account_id}`,
        profileInfo?.stripe_connect_account_id,
      ],
      async () => {
        try {
          if (profileInfo?.stripe_connect_account_id) {
            return await getAllQuotes(profileInfo?.stripe_connect_account_id);
          }
        } catch (e) {
          console.log(e);
        }
      },
      useSwrStopRevalidation
    );

  const { data: allPayoutsInfo, isLoading: allPayoutsInfoLoading } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-all-payouts/${profileInfo?.stripe_connect_account_id}`,
      profileInfo?.stripe_connect_account_id,
    ],
    async () => {
      try {
        if (profileInfo?.stripe_connect_account_id) {
          return await getAllPayouts(profileInfo?.stripe_connect_account_id);
        }
      } catch (e) {
        console.log(e);
      }
    },
    useSwrStopRevalidation
  );

  const { data: allTasksResponseInfo, isLoading: allTasksResponseInfoLoading } =
    useSWR(
      [`getTasksByLandingPage/${profileInfo?.slug}`, profileInfo?.slug],
      async () => {
        try {
          if (profileInfo?.slug) {
            const res = await getTasksByLandingPage(profileInfo?.slug);
            return res?.data;
          }
          return;
        } catch (e) {
          console.log(e);
        }
      },
      useSwrStopRevalidation
    );

  const { data: facebookSocialPhotosInfo } = useSWR(
    ["facebookProfileCoverPhotos", facebookPageId, facebookPageToken],
    async () => {
      try {
        if (facebookPageId && facebookPageToken) {
          return await getFacebookProfileCoverPhotos(
            facebookPageId,
            facebookPageToken
          );
        }
      } catch (e) {
        console.log(e);
        return;
      }
    },
    useSwrStopRevalidation
  );

  const { data: googleSocialPhotosInfo } = useSWR(
    ["googleProfileCoverPhotos", gmbAccessToken, gmbAccountId],
    async () => {
      try {
        if (gmbAccessToken && gmbAccountId && gmbLocationId) {
          return await getGmbPhotos(
            gmbAccessToken,
            gmbAccountId,
            gmbLocationId
          );
        }
      } catch (e) {
        console.log(e);
        return;
      }
    },
    useSwrStopRevalidation
  );

  const { data: analyticsInfo, isLoading: analyticsInfoLoading } = useSWR(
    [gmbLocationId, gmbAccessToken, gmbVerificationStatus],
    () => {
      if (
        gmbLocationId &&
        gmbAccessToken &&
        gmbVerificationStatus === "verified"
      )
        return getGoogleAnalytics(gmbAccessToken, gmbLocationId);
    },
    useSwrStopRevalidation
  );

  const { data: allReviews, isLoading: allReviewsLoading } = useSWR(
    [`/api/reviews?slug=`, profileInfo?.slug],
    async () => {
      return await getAllReviews(profileInfo?.slug);
    },
    useSwrStopRevalidation
  );

  const { data: allRequestsInfo } = useSWR(
    [`/api/review-requests?slug=`, profileInfo?.slug],
    async () => {
      return await getAllReviewRequests(profileInfo?.slug);
    },
    useSwrStopRevalidation
  );

  const { data: digitalMediaInfo, mutate: mutateDigitalMedia } = useSWR(
    [`getAllDigitalMedia-${profileInfo?.slug}`],
    async () => {
      try {
        if (profileInfo?.slug) {
          return await getAllDigitalMedia(profileInfo?.slug);
        }
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    useSwrStopRevalidation
  );

  const { data: digitalMediaDefaultsInfo, mutate: mutateDigitalMediaDefaults } =
    useSWR(
      [`getDigitalMediaDefaults-${profileInfo?.slug}`],
      async () => {
        try {
          if (profileInfo?.slug) {
            return await getDigitalMediaDefaults(profileInfo?.slug);
          }
        } catch (e) {
          console.log(e);
          return [];
        }
      },
      useSwrStopRevalidation
    );

  const {
    data: physicalMarketingDefaultsInfo,
    mutate: mutatePhysicalMarketingDefaults,
  } = useSWR(
    [`getPhysicalMarketingDefaults-${profileInfo?.slug}`],
    async () => {
      try {
        if (profileInfo?.slug) {
          return await getPhysicalMarketingDefaults(profileInfo?.slug);
        }
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    useSwrStopRevalidation
  );

  const { data: featureUsageInfo, mutate: mutateFeatureUsage } = useSWR(
    [`getFeatureUsage-${profileInfo?.slug}`],
    async () => {
      try {
        if (profileInfo?.slug) {
          const featureUsageData = await getFeatureUsage(profileInfo?.slug);
          return featureUsageData;
        }
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    useSwrStopRevalidation
  );

  const { data: featureLimitsInfo, mutate: mutateFeatureLimits } = useSWR(
    ["getFeatureLimits"],
    async () => {
      try {
        const featureLimitsData = await getFeatureLimits();
        return featureLimitsData;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    useSwrStopRevalidation
  );

  const { data: yearInReviewVideoInfo, mutate: mutateYearInReviewVideo } =
    useSWR(
      ["getYearInReviewVideo"],
      async () => {
        try {
          const slug = profileInfo?.slug;
          if (slug) {
            const video = await getYearInReviewVideo(slug);
            return video;
          }
          return null;
        } catch (e) {
          console.log(e);
          return null;
        }
      },
      useSwrStopRevalidation
    );

  const { data: eligibleForMetricsInfo, mutate: mutateEligibleForMetrics } =
    useSWR(
      ["checkEligibleForSummaryStats"],
      async () => {
        try {
          const slug = profileInfo?.slug;
          if (slug) {
            const isEligibleForMetrics = await checkEligibleForSummaryStats(
              slug
            );
            return isEligibleForMetrics;
          }
          return false;
        } catch (e) {
          console.log(e);
          return false;
        }
      },
      useSwrStopRevalidation
    );

  const { data: projectShowcasesInfo, mutate: mutateProjectShowcases } = useSWR(
    ["getAllProjectShowcases"],
    async () => {
      try {
        const slug = profileInfo?.slug;
        if (slug) {
          const showcases = await getAllProjectShowcases(slug);
          return showcases;
        }
        return null;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    useSwrStopRevalidation
  );

  const { data: calendarEventsInfo, mutate: mutateCalendarEvents } = useSWR(
    ["getCalendarEvents"],
    async () => {
      try {
        const slug = profileInfo?.slug;
        if (slug) {
          const showcases = await getCalendarEvents(slug);
          return showcases;
        }
        return null;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    useSwrStopRevalidation
  );

  useEffect(() => {
    if (!calendarEventsInfo) {
      mutateCalendarEvents();
    } else {
      setCalendarEvents(calendarEventsInfo);
    }
  }, [calendarEventsInfo, profileInfo?.slug]);

  useEffect(() => {
    if (!projectShowcasesInfo) {
      mutateProjectShowcases();
    } else {
      setAllProjectShowcases(projectShowcasesInfo);
    }
  }, [projectShowcasesInfo, profileInfo?.slug]);

  useEffect(() => {
    if (!eligibleForMetricsInfo) {
      mutateEligibleForMetrics();
    } else {
      setEligibleForMetrics(eligibleForMetricsInfo);
    }
  }, [eligibleForMetricsInfo, profileInfo?.slug]);

  useEffect(() => {
    if (!yearInReviewVideoInfo) {
      mutateYearInReviewVideo();
    } else {
      setYearInReviewVideo(yearInReviewVideoInfo);
    }
  }, [yearInReviewVideoInfo, profileInfo?.slug]);

  useEffect(() => {
    if (!featureUsageInfo) {
      mutateFeatureUsage();
    } else {
      setFeatureUsage(featureUsageInfo);
    }
  }, [featureUsageInfo]);

  useEffect(() => {
    if (!featureLimitsInfo) {
      mutateFeatureLimits();
    } else {
      setFeatureLimits(featureLimitsInfo);
    }
  }, [featureLimitsInfo]);

  useEffect(() => {
    if (!digitalMediaInfo) {
      mutateDigitalMedia();
    } else {
      setAllDigitalMedia(digitalMediaInfo);
    }
  }, [digitalMediaInfo]);

  useEffect(() => {
    if (!digitalMediaDefaultsInfo) {
      mutateDigitalMediaDefaults();
    } else {
      setDigitalMediaDefaults(digitalMediaDefaultsInfo);
    }
  }, [digitalMediaDefaultsInfo]);

  useEffect(() => {
    if (!physicalMarketingDefaultsInfo) {
      mutatePhysicalMarketingDefaults();
    } else {
      setPhysicalMarketingDefaults(physicalMarketingDefaultsInfo);
    }
  }, [physicalMarketingDefaultsInfo]);

  function handleDesktopMenuOpen(isOpen: boolean) {
    setDesktopMenuOpen(isOpen);
  }

  useEffect(() => {
    if (allRequestsInfo) {
      setAllRequests(allRequestsInfo);
    }
  }, [allRequestsInfo]);

  useEffect(() => {
    async function getShowAutoReplyToReviewBanner() {
      const message = await getShowAutoReplyToReviewBannerFunc(
        profileInfo?.slug
      );

      setShowAutoReplyToReviewBanner(message);
    }
    if (profileInfo?.slug) {
      getShowAutoReplyToReviewBanner();
    }
  }, [profileInfo?.slug]);

  useEffect(() => {
    if (userInvoiceInDbInfo && !userInvoiceInDbInfoLoading) {
      setUserInvoicesInDB(userInvoiceInDbInfo);
    }
  }, [userInvoiceInDbInfo, userInvoiceInDbInfoLoading]);
  useEffect(() => {
    if (userQuotesInDbInfo && !userQuotesInDbInfoLoading) {
      setUserQuotesInDB(userQuotesInDbInfo);
    }
  }, [userQuotesInDbInfo, userQuotesInDbInfoLoading]);

  useEffect(() => {
    if (usePersonalBlogInfo) {
      setUserPersonalBlogs(usePersonalBlogInfo);
    }
  }, [usePersonalBlogInfo]);

  useEffect(() => {
    if (useScheduleBlogInfo) {
      setUserScheduleBlogs(useScheduleBlogInfo);
    }
  }, [useScheduleBlogInfo]);

  useEffect(() => {
    handleInitialLoadingState("isDjangoProfileLoading", userInfoLoading);
  }, [userInfoLoading]);

  useEffect(() => {
    if (userInfo?.stripe_customer_id) {
      handleInitialLoadingState("isStripeInfoLoading", stripeInfoLoading);
    } else {
      initialApiLoadingState.isStripeInfoLoading = false;
    }
  }, [stripeInfoLoading]);

  async function checkAuth() {
    try {
      if (userInfo || stripeInfo) {
        setProfileInfo({ ...userInfo, ...stripeInfo });
        setAppProfileInfo({ ...userInfo, ...stripeInfo });
        setStripeInfoFetched(true);
      }
    } catch (error) {
      console.log("no user", error);
    }
    setIsFetching(false);
    if (userInfo) setLoading(false);
  }

  useEffect(() => {
    async function onStart() {
      setIsFetching(true);
      await checkAuth();
    }
    onStart();
  }, [userInfo, stripeInfo]);

  useEffect(() => {
    if (profileInfo) {
      const { domain = "", slug } = profileInfo;
      if (domain) {
        const QR_URL = `${URL_CONSTANTS.PROSITE_IMAGE_SRC_QR_URL}?text=${domain}`;
        fetchImage(QR_URL);
      }
      if (!domain && slug) {
        const QR_URL = `${URL_CONSTANTS.PROSITE_IMAGE_SRC_QR_URL}?text=${URL_CONSTANTS.PROSITE_BASE}/m/v2/${slug}/index`;
        fetchImage(QR_URL);
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    if (basic_landing_page_info) {
      setBasicInfo(basic_landing_page_info as BasicInfoProps);
    }
  }, [basic_landing_page_info, userInfo?.slug]);

  useEffect(() => {
    handleInitialLoadingState(
      "isLandingPageInfoLoading",
      basic_landing_page_infoLoading
    );
  }, [basic_landing_page_infoLoading]);

  useEffect(() => {
    async function fetchTeammates() {
      if (teammatesInfo) {
        handleInitialLoadingState("isTeammateInfoLoading", true);
        setTeammates(teammatesInfo);
        handleInitialLoadingState("isTeammateInfoLoading", false);
      }
    }
    fetchTeammates();
  }, [teammatesInfo]);

  useEffect(() => {
    async function fetchCampaigns() {
      if (profileInfo?.slug && !campaigns && profileInfo?.campaigns_flag) {
        handleInitialLoadingState("isCampaignInfoLoading", true);
        // const campaigns =
        //   (await getCampaigns(profileInfo?.google_ads_account_id)) || [];
        // setCampaigns(campaigns);
        handleInitialLoadingState("isCampaignInfoLoading", false);
      }
    }
    fetchCampaigns();
  }, [profileInfo]);

  useEffect(() => {
    async function fetchBookingRequests() {
      if (bookingRequestsInfo) {
        handleInitialLoadingState("isBookingRequestInfoLoading", true);
        if (
          !bookingRequests ||
          (bookingRequests &&
            !twoArraysEqual(bookingRequests, bookingRequestsInfo))
        ) {
          setBookingRequests(bookingRequestsInfo);
          if (bookingRequestsInfo.find((request) => request.is_new)) {
            setHasBookingNotifications(true);
          }
        }
        handleInitialLoadingState("isBookingRequestInfoLoading", false);
      }
      handleInitialLoadingState("isBookingRequestInfoLoading", false);
    }
    fetchBookingRequests();
  }, [bookingRequestsInfo]);

  useEffect(() => {
    async function fetchTestimonials() {
      const testimonials = testimonialInfo;
      if (testimonialInfo) {
        handleInitialLoadingState("isTestimonialInfoLoading", true);
        setReviewInfo(testimonials);
        handleInitialLoadingState("isTestimonialInfoLoading", false);
      }
      handleInitialLoadingState("isTestimonialInfoLoading", false);
    }

    fetchTestimonials();
  }, [testimonialInfo]);

  useEffect(() => {
    mutateTestimonialInfo();
  }, [newFbReviews, newGmbReviews]);

  useEffect(() => {
    async function fetchNewFbReviews() {
      if (newFbReviewInfo) {
        setNewFbReviews(newFbReviewInfo);
      }
    }

    fetchNewFbReviews();
  }, [newFbReviewInfo]);

  useEffect(() => {
    async function fetchNewGmbReviews() {
      if (newGmbReviewInfo) {
        setNewGmbReviews(newGmbReviewInfo);
      }
    }

    fetchNewGmbReviews();
  }, [newGmbReviewInfo]);

  useEffect(() => {
    async function fetchNewFbPhotos() {
      if (newFbPhotosInfo) {
        setNewFbPhotos(newFbPhotosInfo);
      }
    }

    fetchNewFbPhotos();
  }, [newFbPhotosInfo]);

  useEffect(() => {
    async function fetchNewGmbPhotos() {
      if (newGmbPhotosInfo) {
        setNewGmbPhotos(newGmbPhotosInfo);
      }
    }

    fetchNewGmbPhotos();
  }, [newGmbPhotosInfo]);

  useEffect(() => {
    async function fetchNewIgPhotos() {
      if (newIgPhotosInfo) {
        setNewIgPhotos(newIgPhotosInfo);
      }
    }

    fetchNewIgPhotos();
  }, [newIgPhotosInfo]);

  useEffect(() => {
    if (userPhotoInfo) {
      setUserPhoto(userPhotoInfo?.message);
    }
  }, [userPhotoInfo, newFbPhotosInfo, newGmbPhotosInfo, newIgPhotosInfo]);

  useEffect(() => {
    async function fetchContacts() {
      if (contactInfo) {
        setContacts(contactInfo);
      }
    }
    fetchContacts();
  }, [contactInfo]);

  useEffect(() => {
    // Google
    async function setGoogleInfo() {
      if (!userSocialInfo) return;
      let verificationStatus;
      const {
        slug,
        gmb_token_expiration,
        gmb_refresh_token,
        gmb_access_token,
        gmb_account_id,
        gmb_location_id,
        gmb_email,
        gmb_location_verification_status,
        landing_page_creation_date,
        first_name,
        last_name,
      } = userSocialInfo;

      setIsGmbAuthenticationLoading(true);
      let is_gmb_authenticated = false;
      handleInitialLoadingState("isGmbInfoLoading", true);

      const validToken = await checkGoogleAuth(slug, {
        expiryDate: gmb_token_expiration,
        refreshToken: gmb_refresh_token,
        accessToken: gmb_access_token,
      });

      if (validToken?.data) {
        setGmbAccessToken(validToken?.data?.accessToken);
      }

      setGmbRefreshToken(gmb_refresh_token);
      setGmbAccountId(gmb_account_id);
      setGmbLocationId(gmb_location_id);
      setGmbEmail(gmb_email);

      is_gmb_authenticated = validToken?.status && gmb_location_id;

      setIsGmbAuthenticated(is_gmb_authenticated);
      handleInitialLoadingState("isGmbInfoLoading", false);

      setGmbVerificationStatus(gmb_location_verification_status);

      setLandingPageCreationDate(landing_page_creation_date);
      setIsGmbAuthenticationLoading(false);

      if (first_name !== "Testing" && last_name !== "Testing") {
        verificationStatus = await checkUpdateGmbVerificationStatus(slug);
        setGmbVerificationStatus(verificationStatus);
      }
    }

    setGoogleInfo();
  }, [userSocialInfo]);

  useEffect(() => {
    // Facebook
    if (!userSocialInfo) return;
    const {
      fb_user_access_token,
      fb_page_access_token,
      fb_page_id,
      landing_page_creation_date,
      fb_page_name,
    } = userSocialInfo;
    setIsFacebookAuthenticated(Boolean(fb_page_access_token));
    setFacebookUserToken(fb_user_access_token);
    setFacebookPageToken(fb_page_access_token);
    setFacebookPageId(fb_page_id);
    setFacebookPageName(fb_page_name);
    setLandingPageCreationDate(landing_page_creation_date);
  }, [userSocialInfo]);

  useEffect(() => {
    // Instagram
    if (!userSocialInfo) return;
    setIsInstagramAuthenticated(Boolean(userSocialInfo.is_instagram_connected));
  }, [userSocialInfo]);

  useEffect(() => {
    // Quickbooks
    if (!userSocialInfo) return;
    const {
      does_use_quickbooks,
      quickbooks_refresh_token,
      quickbooks_expiration_date,
    } = userSocialInfo;
    if (does_use_quickbooks === "yes") {
      setDoesUseQuickbooks("yes");
    } else if (does_use_quickbooks === "no") {
      setDoesUseQuickbooks("no");
    } else {
      setDoesUseQuickbooks("not sure");
    }
    let isQbRefreshTokenExpired = true;
    if (quickbooks_expiration_date) {
      const today = new Date();
      const qbExpirationDate = new Date(quickbooks_expiration_date) || today;
      isQbRefreshTokenExpired = today.getTime() > qbExpirationDate.getTime();
    }

    setIsQuickbooksAuthenticated(
      quickbooks_refresh_token && !isQbRefreshTokenExpired
    );
  }, [userSocialInfo]);

  useEffect(() => {
    if (userStripeAccountInfo && !userStripeAccountInfoLoading) {
      setUserStripeAccount(userStripeAccountInfo);
    }
  }, [userStripeAccountInfo, userStripeAccountInfoLoading]);

  useEffect(() => {
    if (allProsInvoices && !allProsInvoicesLoading) {
      setAllInvoicesForPro(allProsInvoices?.data);
    }
  }, [allProsInvoices, allProsInvoicesLoading]);

  useEffect(() => {
    if (
      userStripeAccountInvoicesInfo &&
      !userStripeAccountInvoicesInfoLoading
    ) {
      setAllUserStripeInvoices(userStripeAccountInvoicesInfo);
    }
  }, [userStripeAccountInvoicesInfo, userStripeAccountInvoicesInfoLoading]);

  useEffect(() => {
    if (userFacebookPostsInfo && !userFacebookPostsInfoLoading) {
      setUserFacebookPosts(userFacebookPostsInfo);
    }
  }, [userFacebookPostsInfo, userFacebookPostsInfoLoading]);

  useEffect(() => {
    if (instagramPostsInfo && !instagramPostsLoading) {
      setUserInstagramPosts(instagramPostsInfo);
    }
  }, [instagramPostsInfo, instagramPostsLoading]);

  useEffect(() => {
    if (
      userFacebookFollowerCountInfo &&
      !userFacebookFollowerCountInfoLoading
    ) {
      setUserFacebookFollowerCount(userFacebookFollowerCountInfo);
    }
  }, [userFacebookFollowerCountInfo, userFacebookFollowerCountInfoLoading]);

  useEffect(() => {
    if (userFacebookLikesInfo && !userFacebookLikesInfoLoading) {
      setUserFacebookLikes(userFacebookLikesInfo);
    }
  }, [userFacebookLikesInfo, userFacebookLikesInfoLoading]);

  useEffect(() => {
    if (userGMBPostsInfo && !userGMBPostsInfoLoading) {
      setUserGMBPosts(userGMBPostsInfo);
    }
  }, [userGMBPostsInfo, userGMBPostsInfoLoading]);

  // useEffect for userScheduledPostsInfo

  useEffect(() => {
    if (userScheduledPostsInfo && !userScheduledPostsInfoLoading) {
      setUserSchedulePosts(userScheduledPostsInfo);
    }
  }, [userScheduledPostsInfo, userScheduledPostsInfoLoading]);

  // useEffect for userGalleriesInfo

  useEffect(() => {
    if (userGalleriesInfo && !userGalleriesInfoLoading) {
      setUserGalleries(userGalleriesInfo);
    }
  }, [userGalleriesInfo, userGalleriesInfoLoading]);

  // useEffect for userAllQuotes

  useEffect(() => {
    if (userAllQuotesInfo && !userAllQuotesInfoLoading) {
      setUserAllQuotes(userAllQuotesInfo);
    }
  }, [userAllQuotesInfo, userAllQuotesInfoLoading]);

  useEffect(() => {
    if (allPayoutsInfo && !allPayoutsInfoLoading) {
      setAllPayouts(allPayoutsInfo);
    }
  }, [allPayoutsInfo, allPayoutsInfoLoading]);

  useEffect(() => {
    if (allTasksResponseInfo && !allTasksResponseInfoLoading) {
      setAllTasksResponse(allTasksResponseInfo);
    }
  }, [allTasksResponseInfo, allTasksResponseInfoLoading]);

  useEffect(() => {
    if (userReferralsInfo && !userReferralsLoading) {
      setUserReferrals(userReferralsInfo);
    }
  }, [userReferralsInfo, userReferralsLoading]);

  useEffect(() => {
    if (facebookSocialPhotosInfo) {
      setFacebookSocialPhotos(facebookSocialPhotosInfo);
    }
  }, [facebookSocialPhotosInfo]);

  useEffect(() => {
    if (googleSocialPhotosInfo) {
      setGoogleSocialPhotos(googleSocialPhotosInfo);
    }
  }, [googleSocialPhotosInfo]);

  useEffect(() => {
    if (analyticsInfo && !analyticsInfoLoading) {
      if (
        !analytics ||
        (analytics && !twoArraysEqual(analytics, analyticsInfo))
      ) {
        setAnalytics(analyticsInfo);
      }
    }
  }, [analyticsInfo, analyticsInfoLoading]);

  useEffect(() => {
    setTimeout(() => {
      setPhotoUploadedSuccessfully(null);
    }, 5000);
  }, [photoUploadedSuccessfully]);

  useEffect(() => {
    function getDelinquentStatus() {
      const isDelinquent = profileInfo?.is_delinquent;
      if (isDelinquent) {
        setPaymentFailureError(true);
      }
    }

    getDelinquentStatus();
  }, [profileInfo?.is_delinquent]);

  useEffect(() => {
    if (!!isFetching && profileInfo && userInfo) {
      setLoading(false);
    }
  }, [isFetching, profileInfo]);

  useEffect(() => {
    if (competitorsInfo) {
      setCompetitors(competitorsInfo);
    }
  }, [competitorsInfo]);

  useEffect(() => {
    if (seoInfo && gbpSeoInfo && wordsAndPageSeoInfo) {
      setSeoData({ ...seoInfo, ...gbpSeoInfo, ...wordsAndPageSeoInfo });
    }
  }, [seoInfo, gbpSeoInfo, wordsAndPageSeoInfo]);

  function handleInitialLoadingState(key: string, value: boolean) {
    setInitialApiLoadingState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  useEffect(() => {
    if (basicInfo?.google_contacts_access_token)
      setGoogleContactsAuthenticated(true);
  }, [basicInfo?.google_contacts_access_token]);

  useEffect(() => {
    if (basicInfo?.google_service_account?.gbp_client_id) {
      setGoogleClientId(basicInfo?.google_service_account?.gbp_client_id);
    }
  }, [basicInfo?.google_service_account]);

  const fetchQuickBookContact = async (taskId: string) => {
    try {
      const contactsData = await getCronTaskResult(taskId); // Assuming you get contacts info here
      const { contacts } = contactsData || {};
      setContacts(contacts);
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    }
  };

  const fetchTestimonialsReplied = async (taskId: string) => {
    try {
      const reviewData = await getCronTaskResult(taskId);
      setReviewInfo(reviewData);
      toast.success("Successfully replied to existing reviews.");
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
      toast.error("Unable to reply to existing reviews. Please try again.");
    }
  };

  const contactsToRequestReview = () => {
    return contacts.filter((contact) => {
      return !checkIfAlreadySentRequestReviewWithin6Months(
        new Date(contact?.date_request_review_sent)
      );
    });
  };

  const DesktopContextValue: DesktopContextType = {
    handleDesktopMenuOpen,
    desktopMenuOpen,
    handleSelectedFrame,
    selectedFrame,
    setProfileInfo,
    profileInfo,
    reviewInfo,
    isFacebookAuthenticated,
    setIsFacebookAuthenticated,
    isInstagramAuthenticated,
    setIsInstagramAuthenticated,
    isQuickbooksAuthenticated,
    setIsQuickbooksAuthenticated,
    doesUseQuickbooks,
    setDoesUseQuickbooks,
    isGmbAuthenticated,
    setIsGmbAuthenticated,
    isGmbAuthenticationLoading,
    gmbVerificationStatus,
    setGmbVerificationStatus,
    basicInfo,
    setBasicInfo,
    setReviewInfo,
    checkAuth,
    setHelpModal,
    bottomLeftNavigation,
    loading,
    setLoading,
    isLoginVerified,
    setIsLoginVerified,
    isSessionVerified,
    setIsSessionVerified,
    user,
    contacts,
    setContacts,
    setUser,
    rightDrawerOpen,
    setRightDrawerOpen,
    paymentFailureError,
    photoUploadedSuccessfully,
    setPhotoUploadedSuccessfully,
    facebookUserToken,
    setFacebookUserToken,
    facebookPageToken,
    setFacebookPageToken,
    facebookPageId,
    setFacebookPageId,
    gmbAccessToken,
    setGmbAccessToken,
    gmbRefreshToken,
    setGmbRefreshToken,
    gmbAccountId,
    setGmbAccountId,
    gmbLocationId,
    setGmbLocationId,
    gmbEmail,
    setGmbEmail,
    facebookPageName,
    setFacebookPageName,
    landingPageCreationDate,
    initialApiLoadingState,
    paymentsFlagLocalStorage,
    campaignsFlagLocalStorage,
    campaigns,
    setCampaigns,
    teammates,
    setTeammates,
    userStripeAccount,
    setUserStripeAccount,
    allUserStripeInvoices,
    setAllUserStripeInvoices,
    userPersonalBlogs,
    setUserPersonalBlogs,
    userScheduleBlogs,
    setUserScheduleBlogs,
    userFacebookPosts,
    setUserFacebookPosts,
    userFacebookGroups,
    setUserFacebookGroups,
    userInstagramPosts,
    setUserInstagramPosts,
    userGMBPosts,
    setUserGMBPosts,
    userSchedulePosts,
    setUserSchedulePosts,
    userGalleries,
    setUserGalleries,
    usePersonalBlogInfoLoading,
    useScheduleBlogInfoLoading,
    userPhoto,
    notiPreferencesFromCourierData,
    notiPreferencesFromCourierLoading,
    userAllQuotes,
    setUserAllQuotes,
    allTasksResponse,
    setAllTasksResponse,
    facebookSocialPhotos,
    setFacebookSocialPhotos,
    googleSocialPhotos,
    setGoogleSocialPhotos,
    allReviews,
    allReviewsLoading,
    allRequests,
    setAllRequests,
    userInvoicesInDB,
    setUserInvoicesInDB,
    userQuotesInDB,
    setUserQuotesInDB,
    userFacebookFollowerCount,
    setUserFacebookFollowerCount,
    userFacebookLikes,
    setUserFacebookLikes,
    analytics,
    setAnalytics,
    googleSearchKeyWords,
    setGoogleSearchKeyWords,
    gscImpressions,
    setGscImpressions,
    googleImpressionsCount,
    setGoogleImpressionsCount,
    numberOfWeeks,
    setNumberOfWeeks,
    downloadAppOnIphoneWithNoHomeButton,
    setDownloadAppOnIphoneWithNoHomeButton,
    requestsCount,
    setRequestCount,
    userStripeAccountInfoLoading,
    allInvoicesForPro,
    setAllInvoicesForPro,
    reviewRequestModalOpen,
    setReviewRequestModalOpen,
    replyModalOpen,
    setReplyModalOpen,
    userCampaignInfo,
    userCampaignInfoLoading,
    allPayouts,
    setAllPayouts,
    bookingRequests,
    mutateBookingInfo,
    incomingCalls,
    userCompleteCampaignInfo,
    userCompleteCampaignInfoLoading,
    showAutoReplyToReviewBanner,
    setShowAutoReplyToReviewBanner,
    competitors,
    competitorsLoading,
    seoData,
    seoInfoLoading,
    gbpSeoLoading,
    wordsAndPageSeoLoading,
    proHasChurned,
    mutateStripeInfo,
    mutateProfileInfo,
    mutateBasicInfo,
    mutateSocialInfo,
    mutateDigitalMedia,
    mutateDigitalMediaDefaults,
    mutatePhotos,
    mutateGalleries,
    mutateFacebookPosts,
    mutateGooglePosts,
    mutateInstagramPosts,
    mutateScheduledPosts,
    mutateFeatureUsage,
    mutateStripeConnectInvoices,
    mutateProjectShowcases,
    userReferrals,
    showQRModal,
    setShowQRModal,
    fetchQuickBookContact,
    fetchTestimonialsReplied,
    fetchWebsiteViews,
    allDigitalMedia,
    setAllDigitalMedia,
    digitalMediaDefaults,
    setDigitalMediaDefaults,
    physicalMarketingDefaults,
    setPhysicalMarketingDefaults,
    googleContactsAuthenticated,
    setGoogleContactsAuthenticated,
    googleContactsLoading,
    setGoogleContactsLoading,
    showContactsTooltip,
    setShowContactsTooltip,
    showReviewsTooltip,
    setShowReviewsTooltip,
    savedPost,
    setSavedPost,
    allProsInvoices,
    socialConnectionType,
    setSocialConnectionType,
    showQRReviewDrawer,
    setShowQRReviewDrawer,
    featureUsage,
    setFeatureUsage,
    featureLimits,
    socialContentError,
    setSocialContentError,
    isDigitalAssetAttached,
    setIsDigitalAssetAttached,
    attachedPhotos,
    setAttachedPhotos,
    yearInReviewVideo,
    mutateYearInReviewVideo,
    eligibleForMetrics,
    selectedContacts,
    setSelectedContacts,
    contactsToRequestReview,
    onSiteSEOPillStatus,
    setOnSiteSEOPillStatus,
    gmbPillStatus,
    setGmbPillStatus,
    reviewsPillStatus,
    setReviewsPillStatus,
    postFreqPillStatus,
    setPostFreqPillStatus,
    chatbotOpen,
    setChatbotOpen,
    showChatbotTooltip,
    setShowChatbotTooltip,
    showChatbotTooltip2,
    setShowChatbotTooltip2,
    generateGroupPost,
    hasBookingNotifications,
    isPostHistoryLoading,
    allProjectShowcases,
    setAllProjectShowcases,
    calendarEvents,
  };

  return (
    <DesktopContext.Provider value={DesktopContextValue}>
      {children}
    </DesktopContext.Provider>
  );
};
