import { api } from "../../../../helpers/topline-api";

export async function updateNotificationPreferences(payload, topicId) {
  api.post(`/api/update_noti_preferences_from_settings`, {
    payload: payload,
    courier_workspace: "non-bookings",
    topic_id: topicId,
  });
}

export async function getNotiPreferencesFromCourier() {
  const notiPreferencesObj = await api.get(
    `/api/get_noti_preferences_from_courier`
  );
  const notiPreferences = `${notiPreferencesObj?.data.message.data}`;

  return notiPreferences;
}

export async function getBookingsNotiPreferencesFromCourier() {
  const notiPreferencesObj = await api.get(
    `/api/get_bookings-noti_preferences_from_courier`
  );
  const notiPreferences = `${notiPreferencesObj?.data.message.data}`;

  return notiPreferences;
}

export async function updateBookingsReminderPeriodFunc(
  slug: any,
  period: string
) {
  try {
    const responseObj = await api.post(
      `/api/update-bookings-reminders-period`,
      {
        slug,
        period,
      }
    );
    return responseObj;
  } catch (e) {
    console.log("error updating booking reminder period", e);
    return { error: "error updating bookng reminder period" };
  }
}

export async function toggleBookingsReminders(remindersOn: boolean) {
  try {
    const responseObj = await api.post(`/api/toggle-bookings-reminders`, {
      remindersOn,
    });
    return responseObj;
  } catch (e) {
    console.log("error turning off bookings reminders", e);
    return { error: "error turning off bookings reminders" };
  }
}

export async function toggleBookingsRemindersSetting(setting: string) {
  try {
    const responseObj = await api.post(
      `/api/toggle-bookings-reminders-setting`,
      { setting }
    );
    return responseObj;
  } catch (e) {
    console.log("error turning off bookings reminders", e);
    return { error: "error turning off bookings reminders" };
  }
}

export async function toggleAiAssistantSetting() {
  try {
    const responseObj = await api.get(`/api/toggle-ai-assistant-notifications`);
    return responseObj;
  } catch (e) {
    console.log("error turning off bookings reminders", e);
    return { error: "error turning off bookings reminders" };
  }
}

export const courierNotifications = [
  {
    type: "Tips and Tricks",
    desc: "How to make the best of your Topline Pro subscription",
    emailTopicId:
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? "FWZBJK5XRGM37MN2AX3RAWB9CHFR"
        : "SJ0G7PCTSQMCJ6P7HDX7VCH8MJCN",
    smsTopicId:
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? "ZCFY9DANNNMFT9G57XHHRHNH1BCB"
        : "QDZA215F134GSJQV01FRDH8WSPVQ",
  },
  {
    type: "Payments",
    desc: "Everytime you get paid",
    emailTopicId:
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? "S5M727FBCCM0Q7KZMW1JFJA2J8XM"
        : "63QJ2REJS04Y3MK77FWX3CF677E4",
    smsTopicId:
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? "XV5EMWYP6M4S2AJDEAAT7QQG8WGJ"
        : "2QBY9E2WRM4300Q3DYR4VHTPCQ5H",
  },
  {
    type: "Scheduled Posts",
    desc: "Before we post anything",
    emailTopicId:
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? "6Q1P9GDWB4416DPZHP06EB7SFJ16"
        : "8CSEJ0PV7N44GPND884QD7H7AF56",
    smsTopicId:
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? "FKR7M55CWX4MAQPFT2CKJQ6WSK8A"
        : "66538NRNNCMERGQ8QKX0QRF6F7HT",
  },
  {
    type: "Local Opportunities on Nextdoor",
    desc: "For new Nextdoor leads in your Local Opportunity Feed",
    smsTopicId:
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? "NWQRW2HQ9GMT7NNEXJ5N7R00PAZ3"
        : "VZXQ5SQAS8MXWWKD8JKPPSXKA3KB",
  },
  {
    type: "Appointment Reminders",
    desc: "For confirmed upcoming appointments, 1 day before and 1 hour before",
    emailTopicId:
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? "C91E25DN2V4ANFGV2K95PGVK2JDX"
        : "NERH8JPTMB4T60PF9T688VDVC2CX",
    smsTopicId:
      process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
        ? "HPAAX29ZK5MRPBPESZ5JQC20D57T"
        : "KP7ETD04TDMDRXGWFGV8061W2VQN",
  },
];

export const bookingNotificationObj = {
  type: "Bookings Reminders",
  desc: "To reach out or update the status of a request from your website",
};

export const tlpAssistantNotificationObj = {
  type: "Topline Pro Assistant",
  desc: "Your virtual assistant for generating social posts, showcases, and requesting reviews",
};

export interface NonCourierNotifications {
  id: string;
  type: string;
  desc: string;
}

export interface BookingsRemindersSettings {
  gbp_calls: boolean;
  website_calls: boolean;
  website_texts: boolean;
}
