import { URL_CONSTANTS } from "../../constants/urlConstants";
import { getTestimonials } from "../../contexts/utils";
import useSWR from "swr";

export const useTestimonials = (userInfo) => {
  const {
    data: testimonialInfo,
    error: testimonialInfoError,
    isLoading: testimonialInfoLoading,
    mutate: mutateTestimonialInfo,
  } = useSWR(
    [URL_CONSTANTS.PROSITE_GET_TESTIMONIALS_BETS, userInfo?.slug],
    async () => {
      try {
        if (userInfo?.slug) {
          const testimonials = await getTestimonials();
          return testimonials;
        }
      } catch (e) {
        console.log("error", e);
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  return {
    testimonialInfo,
    testimonialInfoError,
    testimonialInfoLoading,
    mutateTestimonialInfo,
  };
};
