import useSWR from "swr";
import { URL_CONSTANTS } from "../../constants/urlConstants";
import { getCompleteCompaignInfo } from "../../components/primaryrender/marketing/CampaignPage/utils";
import get from "lodash/get";
const useUserCompleteCampaignInfo = (profileInfo, userCampaignInfo) => {
  const {
    data: userCompleteCampaignInfo,
    isLoading: userCompleteCampaignInfoLoading,
    mutate: mutateUserCompleteCampaignInfo,
  } = useSWR(
    [
      URL_CONSTANTS.PROSITE_GET_USER_COMPLETE_CAMPAIGN_INFO,
      profileInfo?.slug,
      JSON.stringify(userCampaignInfo),
    ],
    async () => {
      try {
        const firstCampaignId = get(userCampaignInfo, "[0].id");
        if (!firstCampaignId) return null;
        return getCompleteCompaignInfo(firstCampaignId);
      } catch (e) {
        console.error("error with getCompleteCompaignInfo:", e);
      }
    },
    { revalidateOnFocus: false }
  );
  return {
    userCompleteCampaignInfo,
    userCompleteCampaignInfoLoading,
    mutateUserCompleteCampaignInfo,
  };
};

export default useUserCompleteCampaignInfo;
