import { api } from "../../../../helpers/topline-api";

export default async function fetchAllContacts() {
  try {
    const contactsRes = await api.get(`/api/contacts`);
    if (contactsRes.data.message) return contactsRes.data.message;
  } catch (err) {
    console.error(err);
  }
}
