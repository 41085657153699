export enum FilterOptionsEnum {
  NO_REQUEST_SENT = "NO_REQUEST_SENT",
  REQUEST_SENT = "REQUEST_SENT",
  LEFT_REVIEW = "LEFT_REVIEW",
}

export interface FilterOptions {
  REQUEST_SENT: RequestSentProps;
  LEFT_REVIEW: LeftReviewProps;
  NO_REQUEST_SENT: NoRequestSent;
}

interface RequestSentProps {
  name: string;
  checked: boolean;
}

interface LeftReviewProps {
  name: string;
  checked: boolean;
}

interface NoRequestSent {
  name: string;
  checked: boolean;
}
