import { api } from "../../../../helpers/topline-api";

export async function getQbContacts(
  type: string,
  slug: string,
  router: any,
  url = "",
  run_on_cron: boolean = false
) {
  try {
    const response = await api.post(`/api/get-quickbook-contacts/${slug}`, {
      code: router.query.code || "",
      realmId: router.query.realmId || "",
      type: type,
      url: url,
      run_on_cron,
    });

    if (run_on_cron && response.data.status === "error")
      return { error: "error running cron" };
    else if (run_on_cron && response.data.status === "success")
      return response.data.message;

    return {
      newQbContacts: response.data.message.data,
      deleteCounter: response.data.message.delete_counter,
      importedContactCounter: response.data.message.imported_contact_counter,
    };
  } catch (error) {
    console.log("error:", error);
    return { error: error };
  }
}
