const FullPageLoading = () => {
  return (
    <div
      style={{ height: "100dvh" }}
      className="flex items-center justify-center"
    >
      <img
        src="/TextLoading.gif"
        className="object-cover self-center w-60 h-60"
        alt="loading"
      />
    </div>
  );
};

export default FullPageLoading;
