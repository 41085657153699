import { FilterOptions, FilterOptionsEnum } from "./types";

export function sixMonthsPrior() {
  // Copy date so don't affect original
  const d = new Date();
  // Get the current month number
  const m = d.getMonth();
  // Subtract 6 months
  d.setMonth(d.getMonth() - 6);
  // If the new month number isn't m - 6, set to last day of previous month
  // Allow for cases where m < 6
  const diff = (m + 12 - d.getMonth()) % 12;
  if (diff < 6) d.setDate(0);

  return d;
}

const hasReviewRequestSentRecent = (contact) =>
  !!contact.date_request_review_sent &&
  new Date(contact.date_request_review_sent) > sixMonthsPrior();

const hasNoRecentReviewRequest = (contact) =>
  !contact?.date_request_review_sent ||
  contact?.date_request_review_sent < sixMonthsPrior();

const getFilteredContacts = (contacts, checkedFilters) => {
  let filteredContacts = [...contacts]; // clone to avoid direct mutation

  checkedFilters.forEach(([key]) => {
    switch (key) {
      case FilterOptionsEnum.REQUEST_SENT:
        filteredContacts = filteredContacts.filter((contact) =>
          hasReviewRequestSentRecent(contact)
        );
        break;
      case FilterOptionsEnum.LEFT_REVIEW:
        filteredContacts = filteredContacts.filter(
          (contact) => contact.left_a_review
        );
        break;
      case FilterOptionsEnum.NO_REQUEST_SENT:
        filteredContacts = filteredContacts.filter(
          (contact) =>
            hasNoRecentReviewRequest(contact) && !contact.left_a_review
        );
        break;
    }
  });

  return filteredContacts;
};

export const getCheckedFilters = (state: FilterOptions) => {
  return Object.entries(state).filter(([, value]) => value.checked);
};

export const determineDisplayedContacts = ({
  checkedFilters,
  contacts,
}: any) => {
  if (checkedFilters.length === 0) return contacts;
  return getFilteredContacts(contacts, checkedFilters);
};
