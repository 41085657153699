import { URL_CONSTANTS } from "../../../constants/urlConstants";
import { IReferral } from "./types";
import { api } from "../../../helpers/topline-api";

export async function getUserReferrals(): Promise<IReferral[]> {
  try {
    const response = await api.get(
      URL_CONSTANTS.PROSITE_GET_CUSTOMER_REFERRALS
    );
    return response?.data?.message as IReferral[];
  } catch (e) {
    console.log("error getting referrals", e);
    return [] as IReferral[];
  }
}
