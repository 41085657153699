import { URL_CONSTANTS } from "../constants/urlConstants";
import { toast } from "react-toastify";
import {
  stripeGetCanceledPlan,
  stripeGetCustomer,
} from "../components/UpdateCardPayment/utils";
import { api } from "../helpers/topline-api";
import { ProfileApiResponse, ProfileLandingPage } from "../contexts/types";
import isArray from "lodash/isArray";
import { getAuthToken } from "./getAuthUserId";

export async function getSites(): Promise<ProfileApiResponse[]> {
  try {
    // This is preloaded so may be called before we know if the user is authenticated.
    // Long term fix: Ideally the selected site context would be internal to an "Authenticated" context
    if (!(await getAuthToken())) return [];
    const response = await api.get(URL_CONSTANTS.PROSITE_GET_USER);
    // This is code to help the deploy process, it can be removed once GET_USER always returns an array

    return isArray(response.data.message)
      ? response.data.message
      : [response.data.message];
  } catch (e) {
    console.warn(
      "Attempted to fetch sites but failed - could be indiciative of a server problem or a session timeout"
    );
    return [];
  }
}

export default async function getUserInfo(
  site_id: number
): Promise<ProfileApiResponse & { landing_pages: ProfileLandingPage[] }> {
  const sites = await getSites();
  const site = sites.find(({ landing_page_pk }) => landing_page_pk == site_id);
  // Build landing page list that goes on the selected site object.
  // This is the list of the sites the user has access to, that belongs on the user profile
  const landingPages = sites
    .filter(({ slug }) => slug)
    .map(({ domain, slug, landing_page_pk, company_name }) => ({
      domain,
      slug,
      landing_page_pk,
      company_name,
    }));
  if (!site) {
    console.warn(
      `Selected site ${site_id} not found in sites array, returning first site in list`
    );
    return { ...sites[0], landing_pages: landingPages };
  }
  return { ...site, landing_pages: landingPages };
}

export async function fetchStripeUserInfo(stripeCustomerId: string) {
  try {
    const response = await api.get(
      `/api/stripe-fetch-user-information/${stripeCustomerId}`
    );

    if (response.data.status === "error") {
      return "error";
    }

    return response.data.message;
  } catch (e) {
    console.log("error:", e);
    return "error";
  }
}

export async function getStripeCustomerInfo(stripeCustomerId: string) {
  try {
    const stripeUser = await stripeGetCustomer(stripeCustomerId);
    return stripeUser;
  } catch (error) {
    console.log(error, "no stripe user");
    return null;
  }
}

export async function getStripeLatestCanceledPlan(customerId: string) {
  try {
    const canceledPlan = await stripeGetCanceledPlan(customerId);
    return canceledPlan;
  } catch (error) {
    return null;
  }
}

export function facebookToast(status: string) {
  if (status === "success") {
    toast.success("Connected to Facebook");
  } else if (status === "failure") {
    toast.error("Unable to connect. Please try again.");
  } else if (status === "disconnect-failure") {
    toast.error("Unable to disconnect from Facebook. Please try again.");
  }
}

export function instagramToast(status: string) {
  if (status === "success") {
    toast.success("Connected to Instagram");
  } else if (status === "disconnect-failure") {
    toast.error("Unable to disconnect from Instagram. Please try again.");
  }
}

export function quickbooksToast(status: string) {
  if (status === "success") {
    toast.success("Connected to Quickbooks");
  } else if (status === "failure") {
    toast.error("Unable to connect. Please try again.");
  }
}

export function photosToast(images: any[]) {
  if (images.length > 1) {
    toast.success("Photos added");
  } else {
    toast.success("Photo added");
  }
}
export function postToast(
  googleStatus: string,
  facebookStatus: string,
  instagramStatus: string
) {
  const SUCCESS = "success";
  const ERROR = "error";
  const statusArr = [
    { name: "Google", status: googleStatus },
    { name: "Facebook", status: facebookStatus },
    { name: "Instagram", status: instagramStatus },
  ];
  const errorStatusArr = statusArr.filter((item) => item.status === ERROR);
  const successStatusArr = statusArr.filter((item) => item.status === SUCCESS);

  const successNames = successStatusArr.map((item) => item.name).join(" and ");
  const errorNames = errorStatusArr.map((item) => item.name).join(" and ");

  // case 1: all success
  if (successStatusArr.length > 0 && errorStatusArr.length === 0)
    toast.success("Post shared");
  // case 2: all failure
  else if (successStatusArr.length === 0 && errorStatusArr.length > 0)
    toast.error("Unable to post. Please try again");
  // case 3: some success and some failure
  else {
    toast.success(`Post shared to ${successNames}`);
    toast.error(`Unable to post to ${errorNames}. Please try again.`);
  }
}

export function editPostToast(status: string) {
  if (status === "success") {
    toast.success("Post updated");
  } else {
    toast.error("Unable to update post. Please try again.");
  }
}

export function deletePostToast(status: string) {
  if (status === "success") {
    toast.success("Post deleted");
  } else if (status === "failure - fb") {
    toast.error("Unable to delete post on Facebook. Please try again.");
  } else if (status === "failure - google") {
    toast.error("Unable to delete post on Google. Please try again.");
  } else if (status === "failure") {
    toast.error("Unable to delete post. Please try again.");
  }
}

export function importContactsToast(status: any) {
  if (status === "success") {
    toast.success("Contacts imported");
  } else if (typeof status === "object" && status.from === "quickbooks") {
    toast.success(
      `${status.importedContactCounter} contacts imported/updated.${
        status.showDeleteCounter
          ? ` ${status.deleteCounter} previously deleted not imported.`
          : ""
      }`
    );
  } else if (typeof status === "object") {
    toast.success(
      `${status.imported} contacts imported. ${status.duplicated} existing contacts/duplicates not imported.`
    );
  } else if (status === "failure") {
    toast.error("Unable to import contacts. Please try again.");
  }
}

type ImportGoogleContactsToastProps = {
  status: "success" | "failure";
  google_contact_counter?: number;
  deleted_contact_count?: number;
};

export function importGoogleContactsToast({
  status,
  google_contact_counter,
  deleted_contact_count,
}: ImportGoogleContactsToastProps) {
  return status === "failure"
    ? toast.error("Unable to import contacts. Please try again.")
    : toast.success(
        `${google_contact_counter} contacts imported/updated.\n${deleted_contact_count} previously deleted not imported.`
      );
}

export function updateInfoToast(status: string) {
  if (status.includes("success")) {
    const itemUpdated = status.split("-")[1];
    toast.success(`Updated ${itemUpdated}`);
  } else if (status === "failure") {
    const itemUpdated = status.split("-")[1];
    toast.error(`Unable to update ${itemUpdated}`);
  }
}
export function autoGenerateToast(status: string) {
  if (status === "failure - blog titles") {
    toast.error("Error generating blog titles. Please try again.");
  } else if (status === "failure - blog post") {
    toast.error("Error generating blog. Please try again");
  } else if (status === "failure - post") {
    toast.error("Error generating post. Please try again.");
  } else if (status === "failure - rewrite for me") {
    toast.error("Error generating rewrite. Please try again.");
  }
}

export function isGmbPendingMoreThan20DaysAgo(gmbPendingAt: any) {
  if (gmbPendingAt) {
    gmbPendingAt = new Date(gmbPendingAt);
    const today: any = new Date();
    const timeDiff = (today - gmbPendingAt) / (1000 * 60 * 60 * 24);
    return timeDiff >= 20;
  } else return false;
}
