export const videoTypesComplete = [
  "video/mp4",
  "video/mov",
  "video/wmv",
  "video/flv",
  "video/avi",
  "video/webm",
];
export const videoTypesExtensions = [
  "mp4",
  "mov",
  "wmv",
  "flv",
  "avi",
  "webm",
  "quicktime",
];

export const CONSTANTS = {
  videoTypesExtensions,
  videoTypesComplete,
};
