const MOBILE_BOTTOM_NAV_OPTION = [
  "Home",
  "Social Media",
  "My Site",
  "Reviews",
  "Contacts",
];

const MOBILE_HEADER_NAV_OPTION = [
  "Marketing",
  "Bookings",
  "Payments",
  "Settings",
];

const MOBILE_SELECTED_NAV_ICON_COLOR = "rgb(255, 255, 255)"; // text-gray-600
const MOBILE_UNSELECTED_NAV_ICON_COLOR = "rgb(250, 250, 250)"; // text-gray-500
const MOBILE_UNSELECTED_NAV_ICON_COLOR_GRAY = "rgba(107, 114, 128, 1)"; // text-gray-500
const DESKTOP_SELECTED_NAV_ICON_COLOR = "white"; // white
const DESKTOP_UNSELECTED_NAV_ICON_COLOR = "rgba(209, 213, 219)"; // text-gray-300
const NAV_ICON_COLOR_WITH_SHOWN_TOOLTIP = "rgb(37 99 235)"; //text-blue-600

export const CONSTANTS = {
  MOBILE_BOTTOM_NAV_OPTION,
  MOBILE_HEADER_NAV_OPTION,
  MOBILE_SELECTED_NAV_ICON_COLOR,
  MOBILE_UNSELECTED_NAV_ICON_COLOR,
  DESKTOP_SELECTED_NAV_ICON_COLOR,
  DESKTOP_UNSELECTED_NAV_ICON_COLOR,
  MOBILE_UNSELECTED_NAV_ICON_COLOR_GRAY,
  NAV_ICON_COLOR_WITH_SHOWN_TOOLTIP,
};
