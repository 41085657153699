// useContactInfo.js
import useSWR from "swr";
import fetchAllContacts from "../../components/primaryrender/contacts/helpers/fetchAllContacts";
import { ProfileInfo } from "../../contexts/types";

// You might want to import fetchAllContacts if it's defined elsewhere
// import { fetchAllContacts } from './path-to-fetchAllContacts';

const fetcher = async (slug: string | undefined) => {
  try {
    if (slug) {
      return (await fetchAllContacts()) || [];
    }
    return [];
  } catch (e) {
    console.error("Error fetching contacts:", e);
  }
};
const useSwrStopRevalidation = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const useContactInfo = (profileInfo: ProfileInfo | undefined) => {
  const { data, error, isLoading, mutate } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/contacts`, // Updated path
      profileInfo?.slug,
    ],
    () => fetcher(profileInfo?.slug),
    useSwrStopRevalidation
  );

  return {
    contactInfo: data,
    contactInfoError: error,
    contactInfoLoading: isLoading,
    mutateContactInfo: mutate,
  };
};

export default useContactInfo;
