import useSWR from "swr";
import { getAllInvoicesForPro } from "../../components/primaryrender/payments/utils";

const useProsAllInvoices = (profileInfo) => {
  const {
    data: allProsInvoices,
    isLoading: allProsInvoicesLoading,
    mutate: mutateAllProsInVoices,
  } = useSWR(
    [
      `${process.env.NEXT_PUBLIC_PROSITE_BASE}/api/get-all-invoices-for-pro/${profileInfo?.stripe_customer_id}`,
      profileInfo?.stripe_customer_id,
    ],
    async () => {
      try {
        if (profileInfo?.stripe_customer_id) {
          const result = await getAllInvoicesForPro(
            profileInfo?.stripe_customer_id
          );
          return result;
        } else {
          return [];
        }
      } catch (e) {
        console.log(e);
      }
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  return { allProsInvoices, allProsInvoicesLoading, mutateAllProsInVoices };
};

export default useProsAllInvoices;
