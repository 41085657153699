import { Gallery } from "../contexts/types";
import { api } from "../helpers/topline-api";

export interface GetUserGalleriesResponse {
  message?: {
    galleries?: Gallery[];
  };
  status: string;
}

export default async function getUserGalleries(): Promise<GetUserGalleriesResponse> {
  const user_res = await api.get(`/api/getGalleries`);
  return user_res.data;
}
