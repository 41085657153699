import { BlogPostType, ScheduledBlogPostType } from "./types";
import { api } from "../../../../../helpers/topline-api";

export const gpt3Titles = [
  {
    id: 0,
    name: "Title 1",
  },
  {
    id: 1,
    name: "Title 2",
  },
  { id: 2, name: "Title 3" },
];

export async function fetchBlogs(slug: any) {
  try {
    const response = await api.post(`/api/fetch-blogs`, {
      slug,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error fetching blogs:", e);
    return;
  }
}

export async function fetchDefaultBlogs(slug: any) {
  try {
    const response = await api.post(`/api/fetch-default-blogs`, {
      slug,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error fetching default blogs:", e);
    return;
  }
}

export async function fetchBlog(blogId: any) {
  try {
    const response = await api.post(`/api/fetch-blog`, {
      blogId,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error fetch blog:", e);
    return;
  }
}

export async function fetchScheduledBlog(blogId: any) {
  try {
    const response = await api.post(`/api/fetch-scheduled-blog`, {
      blogId,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error fetch blog:", e);
    return;
  }
}

export async function updateBlogPost(blog: BlogPostType) {
  try {
    const { id, title, body } = blog;
    const response = await api.post(`/api/update-blog-post/${id}`, {
      title,
      body,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error update blog post:", e);
    return;
  }
}

export async function deleteBlogPost(blogId: number) {
  try {
    const response = await api.get(`/api/delete-blog-post/${blogId}`);
    const responseData = response?.data;
    return responseData;
  } catch (e) {
    console.log("error delete blog post:", e);
    return;
  }
}

export async function deleteScheduledBlogPost(blogId: number) {
  try {
    const response = await api.get(`/api/delete-scheduled-blog/${blogId}`);
    const responseData = response?.data;
    return responseData;
  } catch (e) {
    console.log("error delete blog post:", e);
    return;
  }
}

export async function generateBlogTitles(slug: any) {
  try {
    const response = await api.post(`/api/generate-blog-titles`, {
      slug,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error generateBlogTitles:", e);
    return;
  }
}

export async function generateServiceBlogTitles(
  slug: string,
  service_name: string
) {
  try {
    const response = await api.post(`/api/generate-service-blog-titles`, {
      slug,
      service_name,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error generateServiceBlogTitles:", e);
    return;
  }
}

export async function rewriteBlogTitle(title: string) {
  try {
    const response = await api.post(`/api/rewrite-blog-title`, {
      title,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error rewriteBlogTitle:", e);
    return;
  }
}

export async function generateBlogPost(slug: any, title: string) {
  let retryCount = 0;
  while (retryCount < 2) {
    try {
      const response = await api.post(`/api/generate-blog-post`, {
        slug,
        title,
      });
      const responseData = response?.data?.message;
      return responseData;
    } catch (e) {
      console.log(`Error generateBlogPost on attempt ${retryCount}:`, e);
      retryCount++;
    }
  }
  console.log("Maximum retry count reached for generateBlogPost");
  return;
}

export async function publishBlog(title: string, body: string, slug: any) {
  try {
    const response = await api.post(`/api/publish-blog`, {
      title,
      body,
      slug,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error publishBlog:", e);
    return;
  }
}

export async function scheduleBlog(
  time: { date: string },
  title: string,
  body: string,
  slug: string
) {
  try {
    const response = await api.post(
      `/api/schedule-blog-from-front-end-endpoint`,
      {
        time,
        body,
        title,
        slug,
      }
    );
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error", e);
  }
}

export async function fetchScheduledBlogs(slug: any) {
  try {
    const response = await api.post(`/api/get-scheduled-blogs/${slug}`);
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error fetch blogs:", e);
    return;
  }
}

export async function updateScheduledBlogPost(
  blog: ScheduledBlogPostType,
  time: string
) {
  try {
    const { id, title, body } = blog;
    const response = await api.post(`/api/edit-scheduled-blog/${id}`, {
      time_to_post: time,
      title,
      body,
    });
    const responseData = response?.data?.message;
    return responseData;
  } catch (e) {
    console.log("error update blog post:", e);
    return;
  }
}

export function cleanBody(body: any) {
  let preview = body?.replace(/<p[^>]*>/g, "");
  preview = preview?.replace(/<\/?p[^>]*>/g, " ");
  preview = preview?.replace(/<[^>]*>?/gm, "");
  preview = preview?.replace(/\&amp;/g, "&");
  return preview;
}
