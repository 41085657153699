import { api } from "../../../helpers/topline-api";

export async function getCalendarEvents(slug: string) {
  try {
    const response = await api.get(`api/landing-page/${slug}/calendar_events`);
    return response?.data?.data;
  } catch (e) {
    return null;
  }
}
