import useSWR, { preload } from "swr";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";

import { SITE_ID_HEADER } from "../../constants/httpConstants";
import { URL_CONSTANTS } from "../../constants/urlConstants";
import FullPageLoading from "../../components/FullPageLoading/FullPageLoading";
import { SiteIdStore as SiteIdStoreSingleton } from "../../components/navbar/SiteSelector/SiteIdStore";
import { api } from "../../helpers/topline-api";
import { ProfileLandingPage } from "../types";
import { getSites } from "../../hooks/utils";
import { useSwrStopRevalidation } from "../DesktopContextProvider/DesktopContextProvider";
const SiteIdStore = SiteIdStoreSingleton.getInstance();

/**
 * This context provides the currently selected site the the children below it.
 * It depends on session storage, but on first load, will wait til the sites list is loaded and select the the first as a default.
 */
preload(URL_CONSTANTS.PROSITE_GET_USER, () => getSites());

export const SelectedSiteContext = createContext<SelectedSiteContextType>(
  {} as any
);

export interface SelectedSiteContextType {
  setSelectedSiteId: Dispatch<SetStateAction<number>>;
  selectedSiteId: number;
}

export const SelectedSiteContextProvider = ({
  children,
}: PropsWithChildren<any>) => {
  const [selectedSiteId, setSelectedSiteId] = useState<number>();

  const { data: sites, isLoading } = useSWR(
    URL_CONSTANTS.PROSITE_GET_USER,
    () => getSites(),
    useSwrStopRevalidation
  );

  const contextValues = {
    selectedSiteId,
    setSelectedSiteId,
  };

  // Only want to run this in the browser context. This reloads the site id from storage into state.
  useEffect(() => {
    if (
      typeof window != "undefined" &&
      SiteIdStore.id != null &&
      SiteIdStore.id !== selectedSiteId
    )
      setSelectedSiteId(SiteIdStore.id);
  }, []);

  useEffect(() => {
    // Only want to run interceptor in browser context
    if (typeof window == "undefined") return;
    if (selectedSiteId == null) return;

    const interceptor = api.interceptors.request.use((config) => {
      if (selectedSiteId == null) return config;
      // If this runs in a Next context, we won't have the information needed for the selected site id, so we can disregard
      config.headers[SITE_ID_HEADER] = selectedSiteId;
      return config;
    });

    // Eject the existing interceptor if the site id changes
    return () => api.interceptors.request.eject(interceptor);
  }, [selectedSiteId]);

  // Update the store when the selected site changes
  useEffect(() => {
    if (selectedSiteId == SiteIdStore.id) return;
    // Otherwise, we want to update the store with the selected site id
    SiteIdStore.id = selectedSiteId;
  }, [selectedSiteId]);

  useEffect(() => {
    // If there is no profile, we need to wait for it to load.
    if (!sites?.length) return;
    const landingPage = getSelectedLandingPage(selectedSiteId, sites);
    setSelectedSiteId(landingPage.landing_page_pk);
  }, [sites, selectedSiteId]);

  const getSelectedLandingPage = (
    selectedSiteId: number | undefined,
    sites: ProfileLandingPage[]
  ) => {
    // If there is no selected site id, this might be the first time on the page in this session, select first element
    if (selectedSiteId == null) return sites[0];
    const site = sites.find((site) => site.landing_page_pk === selectedSiteId);
    // Something has gone wrong in this case, we'll fallback to the first site. It's possible a site association was deleted so the cached site id is no longer valid
    if (!site) return sites[0];
    return site;
  };

  if (isLoading) return <FullPageLoading />;
  return (
    <SelectedSiteContext.Provider value={contextValues}>
      {children}
    </SelectedSiteContext.Provider>
  );
};
