const PROSITE_BASE = `${process.env.NEXT_PUBLIC_PROSITE_BASE}`;
const CLIENT_BASE = `${process.env.NEXT_PUBLIC_CLIENT_BASE}`;
const PROSITE_GET_USER = `/api/get-user`;
const PROSITE_GET_TESTIMONIALS_BETS = `/api/testimonials-beta`;
const PROSITE_GET_BOOKINGS_BY_LANDING_PAGE = `/api/bookings/get-bookings`;
const PROSITE_GET_LANDING_PAGE = `/api/getLandingPage`;
const PROSITE_UPDATE_LANDING_PAGE = `/api/update_landing_page`;
const PROSITE_UPDATE_CUSTOMER_AUTO_RESPONSE = `/api/update_auto_response_to_homeowner`;
const PROSITE_UPDATE_BUSINESS_HOURS = `/api/update-business-hours`;
const PROSITE_POST_REPLY_TO_GOOGLE_REVIEW = `/api/post-google-review-reply`;
const PROSITE_DELETE_REPLY_TO_GOOGLE_REVIEW = `/api/delete-google-review-reply`;

const PROSITE_GET_AUTO_REPLY_TO_REVIEW_BANNER = `/api/get_auto_reply_to_review_banner`;
const PROSITE_UPDATE_SHOW_AUTO_REPLY_TO_REVIEW_BANNER = `/api/update_show_auto_reply_to_reviews_banner`;
const PROSITE_GET_USER_CAMPAIGN_INFO = `/ads/get_campaigns`;
const PROSITE_GET_USER_COMPLETE_CAMPAIGN_INFO = `/ads/get_complete_campaign_info_request`;
const PROSITE_GET_KEYWORDS_HISTORIC_METRICS = `/ads/get-keywords-historical-metrics`;
const PROSITE_GET_GOOGLE_ADS_INTEREST_FORM_INFO = `/ms/google_ads_interest_form`;
// Use this URL when referring to something that is routed through axios
const PROSITE_QR_URL = `/api/qr`;
// Use this URL when referring to something and not using axios (like a src tag)
const PROSITE_IMAGE_SRC_QR_URL = `${PROSITE_BASE}${PROSITE_QR_URL}`;
const PROSITE_FAQs_URL = `/api/faqs`;
const PROSITE_REORDER_FAQs_URL = `/api/reorder_faqs`;
const PRIMARY_INDEX_ROUTE = "/home";
const DEFAULT_GOOGLE_REDIRECT_URL = `${CLIENT_BASE}/settings/connections`;
const HOW_TO_CREATE_GMB_HELP_LINK =
  "https://help.toplinepro.com/en/articles/6566446-how-to-create-your-google-business-profile";
const HOW_TO_VERIFY_GMB_HELP_LINK =
  "https://help.toplinepro.com/en/articles/6566444-how-to-verify-your-google-my-business-profile";
const PROSITE_SEARCH_WITH_SUPER_ASSISTANT = `/assistant/assistant-actions/semantic-search`;
const PROSITE_GET_COMPETITORS = `/api/get-competitors`;
const PROSITE_GET_CUSTOMER_REFERRALS = `/api/get-referrals`;

export const URL_CONSTANTS = {
  PRIMARY_INDEX_ROUTE,
  PROSITE_BASE,
  PROSITE_QR_URL,
  PROSITE_IMAGE_SRC_QR_URL,
  PROSITE_GET_USER,
  PROSITE_GET_TESTIMONIALS_BETS,
  PROSITE_GET_BOOKINGS_BY_LANDING_PAGE,
  PROSITE_GET_LANDING_PAGE,
  PROSITE_UPDATE_LANDING_PAGE,
  PROSITE_UPDATE_BUSINESS_HOURS,
  PROSITE_UPDATE_CUSTOMER_AUTO_RESPONSE,
  HOW_TO_CREATE_GMB_HELP_LINK,
  HOW_TO_VERIFY_GMB_HELP_LINK,
  DEFAULT_GOOGLE_REDIRECT_URL,
  CLIENT_BASE,
  PROSITE_POST_REPLY_TO_GOOGLE_REVIEW,
  PROSITE_DELETE_REPLY_TO_GOOGLE_REVIEW,
  PROSITE_GET_USER_CAMPAIGN_INFO,
  PROSITE_GET_USER_COMPLETE_CAMPAIGN_INFO,
  PROSITE_GET_AUTO_REPLY_TO_REVIEW_BANNER,
  PROSITE_UPDATE_SHOW_AUTO_REPLY_TO_REVIEW_BANNER,
  PROSITE_GET_KEYWORDS_HISTORIC_METRICS,
  PROSITE_SEARCH_WITH_SUPER_ASSISTANT,
  PROSITE_GET_COMPETITORS,
  PROSITE_GET_GOOGLE_ADS_INTEREST_FORM_INFO,
  PROSITE_GET_CUSTOMER_REFERRALS,
  PROSITE_FAQs_URL,
  PROSITE_REORDER_FAQs_URL,
};
