import { api } from "../../helpers/topline-api";

enum TaskStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

interface TaskResponse {
  state: TaskStatus;
  result: any;
}

const getCronTaskResult = async (
  taskId: string,
  timeout = 60000
): Promise<any> => {
  const startTime = Date.now();

  do {
    try {
      const response = await api.get<TaskResponse>(
        `/api/task-status/${taskId}`
      );
      if (!response.data.state || response.data.state === TaskStatus.SUCCESS) {
        return response.data.result;
      }

      if (response.data.state === TaskStatus.FAILURE) {
        throw new Error("Task failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return {};
    }

    if (Date.now() - startTime > timeout) {
      throw new Error("Timeout");
    }

    await new Promise((resolve) => setTimeout(resolve, 5000));
    // eslint-disable-next-line no-constant-condition
  } while (true);
};

export default getCronTaskResult;
