import { api } from "./topline-api";
// Direct upload to s3 documentation here
//https://devcenter.heroku.com/articles/s3-upload-node

// function that will return image name from the url string where name is last path parameter
export function getFileNameFromUrl(url: string) {
  if (!url || !url.includes("/")) return url;
  const urlArray = url.split("/");
  const fileName = urlArray[urlArray.length - 1];
  return fileName;
}

export function uploadFile(file, signedRequest, url) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedRequest);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(file.name);
        } else {
          console.log("error uploading file - handled by inline alert message");
        }
      }
    };
    xhr.send(file);
  });
}

export function getSignedRequest(file) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    const newUrl = `https://api-prophone.herokuapp.com/sign_s3?file_name=${file.name}&file_type=${file.type}`;

    xhr.open("GET", newUrl);
    xhr.onreadystatechange = async function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const response = JSON.parse(xhr.responseText);
          const uploaded = await uploadFile(
            file,
            response.signedRequest,
            response.url
          );
          resolve(file.name);
        } else {
          console.log(
            "error getting signed url - handled with inline alert message"
          );
        }
      }
    };
    console.log("getSignedRequest -pre xhr.send");
    xhr.send();
  });
}

export async function deleteImages(photo_ids: any[]) {
  const res = await api.post(`/api/delete_photos`, {
    photo_ids,
  });
  const imagesq = await res.data;
}
