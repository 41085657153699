import isNumber from "lodash/isNumber";
import isNil from "lodash/isNil";

export class SiteIdStore {
  private static readonly LOCAL_STORAGE_SITE_ID: string = "tlp-site-id";
  private static instance: SiteIdStore;

  public static getInstance(): SiteIdStore {
    if (!SiteIdStore.instance) {
      SiteIdStore.instance = new SiteIdStore();
    }
    return SiteIdStore.instance;
  }

  // Choosing sessionStorage because it allows for the user to have different values in different windows
  // Not a static member since sessionStorage may not exist on the server context
  private get storage(): Storage {
    return sessionStorage;
  }

  public get id(): number | null {
    try {
      const item = this.storage.getItem(SiteIdStore.LOCAL_STORAGE_SITE_ID);
      if (!item) return null;
      return Number(item);
    } catch (e) {
      console.error(e);
      this.storage.removeItem(SiteIdStore.LOCAL_STORAGE_SITE_ID);
      // Fail gracefully
      return null;
    }
  }

  public set id(value: number | null | undefined) {
    try {
      if (isNil(value)) {
        this.storage.removeItem(SiteIdStore.LOCAL_STORAGE_SITE_ID);
        return;
      }
      if (!isNumber(value)) {
        console.error(`Cannot set a ${typeof value} to Site Id`);
        return;
      }
      const item = this.storage.setItem(
        SiteIdStore.LOCAL_STORAGE_SITE_ID,
        value.toString()
      );
    } catch (e) {
      console.error(e);
    }
  }
}
